import React, { useEffect, useState } from 'react';
import { getAllCustomersList } from 'api/customer';
import { getAllLegalPersonsList } from 'api/legalPerson';
import { SelectOptionsType } from 'components/UI/UiSelect';
import { getOrderList } from 'api/order';
import { SystemOrderListComponent } from 'components/System/Order/list';
import { doRequest } from 'utils/doRequest';
import { useAllManagers } from 'utils/hooks/useAllManagers';

export const SystemOrderList: React.FC = () => {
  const [customersListSelectOptions, setCustomersListSelectOptions] = useState<SelectOptionsType<number>>([]);
  const [agencyList, setAgencyList] = useState<SelectOptionsType<number>>([]);

  useEffect(() => {
    doRequest(
      () => Promise.all([getAllCustomersList(), getAllLegalPersonsList()]),
      () => true
    )(([customers, agencies]) => {
      setCustomersListSelectOptions(customers.data.map((m) => ({ value: m.id, label: m.name })));
      setAgencyList(agencies.data.map((m) => ({ value: m.id, label: m.name })));
    });
  }, []);

  const { managersListSelectOptions } = useAllManagers();

  return (
    <SystemOrderListComponent
      getData={getOrderList}
      agencyList={agencyList}
      customerOptionsList={customersListSelectOptions}
      managerList={managersListSelectOptions}
    />
  );
};
