import React, { useCallback, useState } from 'react';

import { ProductModel } from 'models/Product';
import { UiModal } from 'components/UI/UiModal';
import { SelectOptionType, UiSelect } from 'components/UI/UiSelect';
import { SystemServiceForm } from 'containers/System/Service/form';
import { serviceTranslations } from 'translations/service';

// почти идентично UiSelectComponent Props
type Props = {
  register: any;
  name: string;
  options: SelectOptionType[] | { label: string; options: SelectOptionType[] }[];
  setValue: any;
  value: any;
  label: string;

  errors?: { [s: string]: unknown }; // объект с ошибками
  isLoading?: boolean;
  setOnSave?: boolean;
  disabled?: boolean;
};

export const UiSelectServiceModal = ({
  register,
  name,
  options,
  setValue,
  errors,
  value,
  label,
  isLoading,
  setOnSave = false,
  disabled = false,
}: Props): React.ReactElement => {
  const [showModal, setShowModal] = useState(false);
  const showModalFn = useCallback(() => {
    setShowModal(true);
  }, []);
  const hideModalFn = useCallback(() => {
    setShowModal(false);
  }, []);

  const onSave = useCallback((savedItem: ProductModel) => {
    if (setOnSave) {
      setValue(name, { label: savedItem.name, value: savedItem.id }, { shouldValidate: true });
    }
  }, []);

  return (
    <>
      <UiSelect
        register={register}
        name={name}
        label={label}
        errors={errors}
        options={options}
        setValue={setValue}
        value={value}
        allowEmptyValue
        appendFn={showModalFn}
        isLoading={isLoading}
        disabled={disabled}
      />
      <UiModal header={serviceTranslations.new} show={showModal} onClose={hideModalFn} size="xl">
        <SystemServiceForm modal closeModal={hideModalFn} onSaved={onSave} />
      </UiModal>
    </>
  );
};
