import React from 'react';

import { getCustomersList } from 'api/customer';
import { SystemCustomersListComponent } from 'components/System/Customer/list';
import { useAllManagers } from 'utils/hooks/useAllManagers';

export const SystemCustomersList = (): React.ReactElement => {
  const { managersListSelectOptions, loading } = useAllManagers();

  return (
    <SystemCustomersListComponent
      getData={getCustomersList}
      loadingFilterData={loading}
      managersListSelectOptions={managersListSelectOptions}
    />
  );
};
