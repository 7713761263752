import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import toNumber from 'lodash/toNumber';
import isNaN from 'lodash/isNaN';
import isFunction from 'lodash/isFunction';

import { createCustomer, getCustomer, updateCustomer } from 'api/customer';
import { SystemCustomerFormComponent } from 'components/System/Customer/form';
import { CustomerFormModel } from 'models/Customer';
import { addCustomersToStoreAction } from 'store/actions';
import { customerTranslations } from 'translations/customer';
import { UiLoader } from 'components/UI/UiLoader';
import { doRequest } from 'utils/doRequest';
import { useAllManagers } from 'utils/hooks/useAllManagers';
import { useRequest } from 'utils/hooks/useRequest';
import { ModalEntityProps } from 'utils/types';

let mounted = false;

export const SystemCustomerForm = ({ modal = false, closeModal, onSaved }: ModalEntityProps): React.ReactElement => {
  useEffect(() => {
    mounted = true;
    return () => {
      mounted = false;
    };
  }, []);

  const { id } = useParams<{ id?: string }>();
  const numberId = toNumber(id);

  const dispatch = useDispatch();

  const { managersListSelectOptions, loading: managersLoading } = useAllManagers();

  const getData = useCallback(() => (isNaN(numberId) || modal ? null : getCustomer(numberId)), [numberId]);
  const { model: initModel, loading } = useRequest(getData);

  const [model, setModel] = useState(initModel);
  useEffect(() => {
    setModel(initModel);
  }, [initModel]);

  const [saveLoading, setSaveLoading] = useState(false);

  const [key, setKey] = useState(1);

  const onSave = useCallback(
    (data: CustomerFormModel) => {
      setSaveLoading(true);
      const updateMode = !!data.id;

      const apiFn = updateMode ? () => updateCustomer(data.id as number, data) : () => createCustomer(data);

      doRequest(apiFn, () => mounted)(
        (customer) => {
          if (modal) {
            if (isFunction(closeModal)) {
              closeModal();
            }

            if (isFunction(onSaved)) {
              onSaved(customer);
            }
          }
          dispatch(addCustomersToStoreAction(customer));

          setModel(customer);
          setKey((k) => k + 1);

          toast.success(updateMode ? customerTranslations.updated : customerTranslations.created);
        },
        () => {
          setSaveLoading(false);
        }
      );
    },
    [mounted, model]
  );

  return loading || managersLoading ? (
    <UiLoader />
  ) : (
    <SystemCustomerFormComponent
      key={key}
      saveLoading={saveLoading}
      onSave={onSave}
      model={model}
      managerList={managersListSelectOptions}
      modal={modal}
    />
  );
};
