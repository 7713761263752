import queryString from 'query-string';
import isEmpty from 'lodash/isEmpty';

import { defaultInitialFilter } from 'config';
import { customHistory } from 'utils/customHistory';

/** очистка get параметров */
export const clearSearchParam = (): void => {
  customHistory.replace({ ...customHistory.location, search: null });
};

/**
 * установить url get параметры
 * @param params {Object}
 */
export const setSearchParam = (params: { [name: string]: string | number }): void => {
  clearSearchParam();

  const search = queryString.parse(customHistory.location.search);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const query = new URLSearchParams(search);

  Object.entries(params)
    .filter(([, v]) => v)
    .filter(([, v]) => typeof v !== 'object') // отсеять сложные типы, т.к. они не входят просто
    .forEach((param: [string, string | number]) => query.set(param[0], param[1].toString()));

  customHistory.replace({ ...customHistory.location, search: query.toString() });
};

/**
 * получить url get параметры
 */
export const getSearchFilterParams = (): Record<string, any> => {
  const search = queryString.parse(customHistory.location.search);

  if (isEmpty(search)) {
    return defaultInitialFilter();
  }

  if (search.pageLimit) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    search.pageLimit = +search.pageLimit;
  }
  if (search.pageNumber) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    search.pageNumber = +search.pageNumber;
  }

  return search;
};
