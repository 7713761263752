import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { CCol, CForm, CRow } from '@coreui/react';

import { UiRowCheckbox } from 'components/UI/UiCheckbox';
import { ServiceListFilterType } from 'models/Service';
import { ClientContractFilterType } from 'models/ClientContract';
import { SelectOptionsType, SelectOptionType, UiSelect } from 'components/UI/UiSelect';
import { UiInput } from 'components/UI/UiInput';
import { UiToolbar } from 'components/UI/UiToolbar';
import { legalPersonTranslations } from 'translations/legalPerson';
import { serviceTranslations } from 'translations/service';
import { prepareFormData, prepareIds } from 'utils/formFilter';
import { useAgencyContractsOptions } from 'utils/hooks/SelectOptionsHooks/useAgencyContractsOptions';
import { useAgentsOptions } from 'utils/hooks/SelectOptionsHooks/useAgentsOptions';
import { useClientsContractsOptions } from 'utils/hooks/SelectOptionsHooks/useClientsContractsOptions';
import { useLegalPersonsOptions } from 'utils/hooks/SelectOptionsHooks/useLegalPersonsOptions';
import { baseFinder } from 'utils/listHelpers';
import { Optional } from 'utils/types';

type Props = {
  onSearch: (f: ClientContractFilterType) => void;
  onReset: () => void;
  customerList: SelectOptionsType<number>;
};

type FormValues = Optional<{
  id: number;
  name: string;
  customer: SelectOptionType<number> | null;
  legalPerson: SelectOptionType<number> | null;
  clientContract: SelectOptionType<number> | null;
  isAgent: boolean;
  legalPersonAgent: SelectOptionType<number> | null;
  agencyContract: SelectOptionType<number> | null;
}>;

export const SystemServiceFilter = ({ onSearch, onReset, customerList }: Props): React.ReactElement => {
  const { register, handleSubmit, reset, setValue, watch } = useForm<FormValues>({
    defaultValues: {
      name: '',
      customer: null,
      legalPerson: null,
      clientContract: null,
      isAgent: false,
      legalPersonAgent: null,
      agencyContract: null,
    },
  });

  const onLocalReset = () => {
    reset();
    onReset();
  };

  const onSearchLocal = (data: FormValues) => {
    onSearch(
      prepareFormData<ServiceListFilterType>({
        ids: prepareIds(data.id),
        name: data.name || undefined,
        clientContractIds: prepareIds(data.clientContract?.value),
        agencyContractIds: prepareIds(data.agencyContract?.value),
        agencyLegalPersonIds: prepareIds(data.legalPersonAgent?.value),
        rhsClientLegalPersonIds: prepareIds(data.legalPerson?.value),
        rhsClientCustomerIds: prepareIds(data.customer?.value),
        haveAgencyContract: data.isAgent ? true : undefined,
      })
    );
  };

  /** найти получателей, зависящих от заказчика */
  const [legalPersons, getLegalPersons, legalPersonsLoading] = useLegalPersonsOptions();

  /** найти клиентские договоры зависящие от юл. */
  const [clientContracts, getClientContracts, clientContractsLoading] = useClientsContractsOptions();

  /** Загрузить юл - агентов */
  const [agents, , agentsLoading] = useAgentsOptions();

  /** Найти агентские договоры по юл агента */
  const [agencyContracts, findAgencyContracts, agencyContractsLoading] = useAgencyContractsOptions();

  /** Запросить список юр лиц */
  const customer = watch('customer');
  useEffect(() => {
    getLegalPersons(customer?.value ?? undefined);
  }, [customer]);

  /** Запросить список клиентских договоров */
  const legalPerson = watch('legalPerson');
  useEffect(() => {
    getClientContracts(legalPerson?.value ?? undefined);
  }, [legalPerson]);

  /** Запросить список агентских договоров */
  const legalPersonAgent = watch('legalPersonAgent');
  useEffect(() => {
    findAgencyContracts(legalPersonAgent?.value ?? undefined);
  }, [legalPersonAgent]);

  /** Сбросить значение юр лица, если нет соответствий в списке*/
  useEffect(() => {
    if (legalPerson?.value && !baseFinder(legalPersons, legalPerson?.value)) {
      setValue('legalPerson', null);
    }
  }, [legalPersons]);

  /** Сбросить значение контракта КЛИЕНТА, если нет соответствий в списке*/
  const clientContract = watch('clientContract');
  useEffect(() => {
    if (clientContract?.value && !baseFinder(clientContracts, clientContract?.value)) {
      setValue('clientContract', null);
    }
  }, [clientContracts]);

  /** Сбросить значение контракта АГЕНТА, если нет соответствий в списке*/
  const agencyContract = watch('agencyContract');
  useEffect(() => {
    if (agencyContract?.value && !baseFinder(agencyContracts, agencyContract?.value)) {
      setValue('agencyContract', null);
    }
  }, [agencyContracts]);

  return (
    <CForm onSubmit={handleSubmit(onSearchLocal)} onReset={onLocalReset}>
      <UiToolbar open={Object.values(watch()).some((f) => f)}>
        <CRow>
          <CCol xs="12" sm="6" md="4">
            <UiInput register={register} name="id" label="ID" />
          </CCol>
          <CCol xs="12" sm="6" md="4">
            <UiInput register={register} label={serviceTranslations.name} name="name" />
          </CCol>

          <CCol xs="12" sm="6" md="4">
            <UiSelect
              label={legalPersonTranslations.customer}
              register={register}
              name="customer"
              options={customerList}
              setValue={setValue}
              value={watch('customer')}
              allowEmptyValue
              isClearable
            />
          </CCol>

          <CCol xs="12" sm="6" md="4">
            <UiSelect
              label={serviceTranslations.legalPerson}
              register={register}
              name="legalPerson"
              options={legalPersons}
              setValue={setValue}
              value={watch('legalPerson')}
              isLoading={legalPersonsLoading}
              allowEmptyValue
            />
          </CCol>

          <CCol xs="12" sm="6" md="4">
            <UiSelect
              label={serviceTranslations.clientContract}
              register={register}
              name="clientContract"
              options={clientContracts}
              setValue={setValue}
              value={watch('clientContract')}
              isLoading={clientContractsLoading}
              allowEmptyValue
            />
          </CCol>

          <CCol xs="12" sm="6" md="4" className="d-flex align-items-end">
            <UiRowCheckbox register={register} name="isAgent" label={serviceTranslations.isAgencyContract} />
          </CCol>

          <CCol xs="12" sm="6" md="4">
            <UiSelect
              label={serviceTranslations.agent}
              register={register}
              name="legalPersonAgent"
              options={agents}
              setValue={setValue}
              value={watch('legalPersonAgent')}
              isLoading={agentsLoading}
              allowEmptyValue
            />
          </CCol>

          <CCol xs="12" sm="6" md="4">
            <UiSelect
              label={serviceTranslations.agencyContract}
              register={register}
              name="agencyContract"
              options={agencyContracts}
              setValue={setValue}
              value={watch('agencyContract')}
              isLoading={agencyContractsLoading}
              allowEmptyValue
            />
          </CCol>
        </CRow>
      </UiToolbar>
    </CForm>
  );
};
