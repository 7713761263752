import { getCurrentUserRole, getIsOwner } from 'models/Auth';

/** Возвращает объект с полями которые нужно задизеблить в зависимости от прав пользователя */
export const getCustomerRights = (updateMode = false, managerId = ''): { [key: string]: boolean } => {
  const { isAdmin, isChiefManager } = getCurrentUserRole();
  const isOwner = getIsOwner(managerId);

  return {
    name: updateMode && !(isAdmin || (isOwner && isChiefManager)),
    isPartner: updateMode,
    manager: !(updateMode && isAdmin),
  };
};
