import React from 'react';
import { CCard, CCardBody } from '@coreui/react';
import { Link } from 'react-router-dom';

import { SelectOptionsType } from 'components/UI/UiSelect';
import { getAgencyContractList } from 'api/agencyContract';
import { SystemAgencyContractFilter } from 'components/System/AgencyContract/filter';
import { UiTable } from 'components/UI/UiTable';
import { UiListBtnNew } from 'components/UI/UiListBtnNew';
import { agencyContractTranslations } from 'translations/agencyContract';
import { formatDate } from 'utils/date';
import { useFilter } from 'utils/hooks/useFilter';
import { systemAgencyContractRoute } from 'containers/System/AgencyContract/routes';
import { AgencyContractListModel, AgencyContractModel } from 'models/AgencyContract';

const fields = [
  { key: 'id', label: 'ID' },
  { key: 'numberOfContract', label: agencyContractTranslations.numberOfContract },
  { key: 'agencyLegalPerson', label: agencyContractTranslations.agencyLegalPersonId },
  { key: 'invitedLegalPerson', label: agencyContractTranslations.invitedLegalPersonId },
  { key: 'signingDate', label: agencyContractTranslations.signingDate },
];

type Props = {
  getData: typeof getAgencyContractList;
  legalPersonsAgentSelectOptions: SelectOptionsType<number>;
  legalPersonsRecipientSelectOptions: SelectOptionsType<number>;
  loadingFilterData: boolean;
};

export const SystemAgencyContractListComponent = ({
  getData,
  legalPersonsAgentSelectOptions,
  legalPersonsRecipientSelectOptions,
  loadingFilterData,
}: Props): React.ReactElement => {
  const {
    onPaginationChange,
    loading,
    model,
    filter,
    onReset,
    onSearch,
    onChangePage,
  } = useFilter<AgencyContractListModel>(getData);

  return (
    <CCard>
      <CCardBody>
        <h3 className="text-center">{agencyContractTranslations.route.list}</h3>

        <UiListBtnNew title={agencyContractTranslations.new} to={systemAgencyContractRoute()} />

        {!loadingFilterData && (
          <SystemAgencyContractFilter
            filter={filter}
            onSearch={onSearch}
            onReset={onReset}
            legalPersonsAgentSelectOptions={legalPersonsAgentSelectOptions}
            legalPersonsRecipientSelectOptions={legalPersonsRecipientSelectOptions}
          />
        )}

        <UiTable
          items={model}
          fields={fields}
          loading={loading}
          pageLimit={filter.pageLimit}
          onChangePage={onChangePage}
          scopedSlots={{
            id: ({ id }: AgencyContractModel) => (
              <td>
                <Link to={systemAgencyContractRoute(id)}>{id}</Link>
              </td>
            ),
            agencyLegalPerson: ({ agencyLegalPerson: { name } }: AgencyContractModel) => <td>{name}</td>,
            invitedLegalPerson: ({ invitedLegalPerson: { name } }: AgencyContractModel) => <td>{name}</td>,
            signingDate: ({ signingDate }: AgencyContractModel) => <td>{formatDate(signingDate)}</td>,
          }}
          onPaginationChange={onPaginationChange}
        />
      </CCardBody>
    </CCard>
  );
};
