import isFunction from 'lodash/isFunction';
import isNaN from 'lodash/isNaN';
import toNumber from 'lodash/toNumber';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { TariffCreateType, TariffUpdateType } from 'models/Tariff';
import { createTariff, getAPINds, getTariff, updateTariff } from 'api/tariff';
import { UiLoader } from 'components/UI/UiLoader';
import { SelectOptionsType } from 'components/UI/UiSelect';
import { SystemTariffFormComponent } from 'components/System/Tariff/form';
import { addTariffAction } from 'store/actions';
import { tariffTranslations } from 'translations/tariff';
import { doRequest } from 'utils/doRequest';
import { getAllLegalPersonsList } from 'api/legalPerson';
import { getAllProductList } from 'api/product';
import { ProductModel, ProductTypesEnum } from 'models/Product';
import { useRequest } from 'utils/hooks/useRequest';

type Props = {
  modal?: boolean; // признак вывода в модалке
  closeModal?: () => void; // опциональное закрытие модалки (при сохранении)
  onSaved?: (item: any) => void;
};

let mounted = false;

export const SystemTariffForm = ({ modal = false, closeModal, onSaved }: Props): React.ReactElement => {
  useEffect(() => {
    mounted = true;
    return () => {
      mounted = false;
    };
  }, []);

  const dispatch = useDispatch();

  const query = useParams<{ id?: string }>();
  const { pathname } = useLocation();
  const numberId = toNumber(query.id);

  const createBasedOn = useMemo(() => pathname.includes('createBasedOn'), [pathname]);

  const getData = useCallback(() => (isNaN(numberId) || modal ? null : getTariff(numberId)), [numberId]);
  const { model: initModel, loading: modelLoading } = useRequest(getData, null, () => mounted);

  const [key, setKey] = useState(1);
  const [model, setModel] = useState(initModel);
  useEffect(() => {
    setModel(initModel);
  }, [initModel]);

  const [models, setModels] = useState<{
    executors: SelectOptionsType<number>;
    subscrProducts: ProductModel[];
    razProducts: ProductModel[];
    nds: number;
  }>({
    executors: [],
    subscrProducts: [],
    razProducts: [],
    nds: 0,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    doRequest(
      () =>
        Promise.all([
          getAllLegalPersonsList({ isExecutor: true }),
          getAllProductList({ types: [ProductTypesEnum.SUBSCRIPTION] }),
          getAllProductList({ types: [ProductTypesEnum.LUMP_SUM] }),
          getAPINds(),
        ]),
      () => mounted
    )(
      ([executors, subscrProducts, razProducts, nds]) => {
        setModels({
          executors: executors.data.map(({ name, id }) => ({ value: id, label: name })),
          subscrProducts: subscrProducts.data,
          razProducts: razProducts.data,
          nds,
        });
      },
      () => {
        setLoading(false);
      }
    );
  }, []);

  const [saveLoading, setSaveLoading] = useState(false);
  const onSave = useCallback(
    (data: TariffCreateType | TariffUpdateType) => {
      setSaveLoading(true);
      const updateMode = !!data?.id;

      const apiFn = updateMode
        ? () => updateTariff(data.id as number, data as TariffUpdateType)
        : () => createTariff(data as TariffCreateType);

      doRequest(apiFn, () => mounted)(
        (savedItem) => {
          if (modal) {
            if (isFunction(closeModal)) {
              closeModal();
            }

            if (isFunction(onSaved)) {
              onSaved(savedItem);
            }
          }

          getTariff(savedItem.id).then((response) => {
            setModel(response);
            setKey((k) => k + 1);
          });

          dispatch(addTariffAction(savedItem));

          toast.success(updateMode ? tariffTranslations.updated : tariffTranslations.created);
        },
        () => {
          setSaveLoading(false);
        }
      );
    },
    [dispatch]
  );

  return loading || modelLoading ? (
    <UiLoader />
  ) : (
    <SystemTariffFormComponent
      key={key}
      model={model}
      executors={models.executors}
      subscrProducts={models.subscrProducts}
      razProducts={models.razProducts}
      nds={models.nds}
      saveLoading={saveLoading}
      onSave={onSave}
      modal={modal}
      createBasedOn={createBasedOn}
    />
  );
};
