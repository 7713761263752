import React from 'react';
import { Link } from 'react-router-dom';
import { CCard, CCardBody } from '@coreui/react';

import { UiListBtnNew } from 'components/UI/UiListBtnNew';
import { getLegalPersonRolesByRights } from 'containers/System/LegalPerson/common';
import { LegalPersonListModel, LegalPersonModel } from 'models/LegalPerson';
import { getLegalPersonsList } from 'api/legalPerson';
import { legalPersonTranslations } from 'translations/legalPerson';
import { systemLegalPersonRoute } from 'containers/System/LegalPerson/routes';
import { useFilter } from 'utils/hooks/useFilter';
import { UiTable } from 'components/UI/UiTable';
import { SystemLegalPersonFilter } from 'components/System/LegalPerson/filter';
import { SelectOptionsType } from 'components/UI/UiSelect';
import { getCustomerById, getManagerById } from 'utils/listHelpers';

const fields = [
  { key: 'id', label: 'ID' },
  { key: 'name', label: legalPersonTranslations.name },
  { key: 'inn', label: legalPersonTranslations.inn },
  { key: 'kpp', label: legalPersonTranslations.kpp },
  { key: 'role', label: legalPersonTranslations.role.title },
  { key: 'customer', label: legalPersonTranslations.customer },
  { key: 'manager', label: legalPersonTranslations.manager },
];

type Props = {
  getData: typeof getLegalPersonsList;
  customerOptionsList: SelectOptionsType<number>;
  managerOptionsList: SelectOptionsType<string>;
  loadingFilterData: boolean; // загрузка доп.данных
  simple: boolean;

  addInfo?: React.ReactNode | React.ReactNode[];
};

export const SystemLegalPersonListComponent = ({
  getData,
  customerOptionsList,
  managerOptionsList,
  loadingFilterData,
  simple,
  addInfo,
}: Props): React.ReactElement => {
  const {
    onPaginationChange,
    loading,
    model,
    filter,
    onReset,
    onSearch,
    onChangePage,
  } = useFilter<LegalPersonListModel>(getData);

  const component = (
    <>
      {addInfo}

      {!simple && (
        <>
          <h3 className="text-center">{legalPersonTranslations.route.list}</h3>

          <UiListBtnNew title={legalPersonTranslations.new} to={systemLegalPersonRoute()} />

          {!loadingFilterData && (
            <SystemLegalPersonFilter
              filter={filter}
              onSearch={onSearch}
              onReset={onReset}
              customerOptionsList={customerOptionsList}
              managerOptionsList={managerOptionsList}
            />
          )}
        </>
      )}

      <UiTable
        items={model}
        fields={fields}
        loading={loading}
        pageLimit={filter.pageLimit}
        onChangePage={onChangePage}
        scopedSlots={{
          id: ({ id }: LegalPersonModel) => (
            <td>
              <Link to={systemLegalPersonRoute(id)}>{id}</Link>
            </td>
          ),
          role: ({ isRecipient, isAgent, isExecutor, isNDSPayer }: LegalPersonModel) => (
            <td>{getLegalPersonRolesByRights({ isRecipient, isAgent, isExecutor, isNDSPayer })}</td>
          ),
          customer: ({ customerId }: LegalPersonModel) => <td>{getCustomerById(customerOptionsList, customerId)}</td>,
          kpp: ({ kpp }: LegalPersonModel) => <td>{kpp || null}</td>,
          manager: ({ manager }: LegalPersonModel) => <td>{getManagerById(managerOptionsList, manager)}</td>,
        }}
        onPaginationChange={onPaginationChange}
      />
    </>
  );

  return simple ? (
    component
  ) : (
    <CCard>
      <CCardBody>{component}</CCardBody>
    </CCard>
  );
};
