import { getAllCustomersList } from 'api/customer';
import { SelectOptionsType } from 'components/UI/UiSelect';
import React, { useCallback, useEffect, useState } from 'react';

import { getLegalPersonsList } from 'api/legalPerson';
import { SystemLegalPersonListComponent } from 'components/System/LegalPerson/list';
import { doRequest } from 'utils/doRequest';
import { useAllManagers } from 'utils/hooks/useAllManagers';

type Props = {
  customerId?: number; // поиск по конкретному заказчику
  children?: React.ReactNode | React.ReactNode[]; // доп. инфа для вывода
};

export const SystemLegalPersonList = ({ customerId, children }: Props): React.ReactElement => {
  const [customersListSelectOptions, setCustomersListSelectOptions] = useState<SelectOptionsType<number>>([]);

  useEffect(() => {
    doRequest(
      getAllCustomersList,
      () => true
    )(({ data }) => {
      setCustomersListSelectOptions(data.map((m) => ({ value: m.id, label: m.name })));
    });
  }, []);

  const { managersListSelectOptions, loading: loadManagers } = useAllManagers();

  const getData = useCallback(
    (filter) => getLegalPersonsList({ ...filter, customerId: customerId || filter.customerId }),
    [customerId]
  );

  return (
    <SystemLegalPersonListComponent
      getData={getData}
      customerOptionsList={customersListSelectOptions}
      managerOptionsList={managersListSelectOptions}
      loadingFilterData={loadManagers}
      simple={Boolean(customerId)}
      addInfo={children}
    />
  );
};
