import { CCard, CCardBody, CCol, CForm, CRow } from '@coreui/react';
import { UiDatePicker } from 'components/UI/UiDatePicker';
import isEqual from 'lodash/isEqual';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { UiSelectLegalPersonModal } from 'components/UI/UiSelectLegalPersonModal';
import { SelectOptionsType, SelectOptionType } from 'components/UI/UiSelect';
import { UiInput } from 'components/UI/UiInput';
import { UiSubmitButton } from 'components/UI/UiSubmitButton';
import { agencyContractTranslations } from 'translations/agencyContract';
import { formatDate, getMinDate, getUnixTimeGMT } from 'utils/date';
import { formatModelNameId } from 'utils/formatters';
import { AgencyContractFormType, AgencyContractModel } from 'models/AgencyContract';
import { prepareFormData } from 'utils/formFilter';
import { baseFinder } from 'utils/listHelpers';
import { getAgencyContractRights } from 'components/System/AgencyContract/formHelpers';

type Props = {
  model: AgencyContractModel | null;
  saveLoading: boolean;
  legalPersonsAgentSelectOptions: SelectOptionsType<number>;
  legalPersonsRecipientSelectOptions: SelectOptionsType<number>;
  onSave: (data: AgencyContractFormType) => void;
  modal: boolean;
};

type FormValues = {
  numberOfContract: string;
  agencyLegalPersonId: SelectOptionType<number> | null;
  invitedLegalPersonId: SelectOptionType<number> | null;
  creationDate: string;
  signingDate: Date | null;
};

export const SystemAgencyContractFormComponent = ({
  model,
  saveLoading,
  legalPersonsAgentSelectOptions,
  legalPersonsRecipientSelectOptions,
  onSave,
  modal,
}: Props): React.ReactElement => {
  const updateMode = !!model?.id;

  const initValues = useMemo(
    () => ({
      numberOfContract: model?.numberOfContract || undefined,
      agencyLegalPersonId: baseFinder(legalPersonsAgentSelectOptions, model?.agencyLegalPersonId) || null,
      invitedLegalPersonId: baseFinder(legalPersonsRecipientSelectOptions, model?.invitedLegalPersonId) || null,
      creationDate: model?.creationDate ? formatDate(model.creationDate) : '',
      signingDate: model?.signingDate ? getUnixTimeGMT(model.signingDate) : null,
    }),
    []
  );

  const { register, handleSubmit, errors, watch, setValue, control } = useForm<FormValues>({
    defaultValues: initValues,
  });

  const disabledToSave = updateMode
    ? isEqual(initValues, {
        numberOfContract: watch('numberOfContract'),
        agencyLegalPersonId: watch('agencyLegalPersonId'),
        invitedLegalPersonId: watch('invitedLegalPersonId'),
        creationDate: watch('creationDate'),
        signingDate: watch('signingDate'),
      })
    : false;

  const rights = getAgencyContractRights(updateMode);

  const watchAgencyPerson = watch('agencyLegalPersonId');

  /** чтобы не попадалось одно юр.лицо в двух местах */
  const filteredInvitedLegalPersonOptions = useMemo(
    () => legalPersonsRecipientSelectOptions.filter((m) => m.value !== watchAgencyPerson?.value),
    [watchAgencyPerson, legalPersonsRecipientSelectOptions]
  );

  const onSubmit = (data: FormValues) =>
    !disabledToSave &&
    onSave(
      prepareFormData({
        id: model?.id,
        numberOfContract: data.numberOfContract,
        agencyLegalPersonId: data.agencyLegalPersonId?.value || 0,
        invitedLegalPersonId: data.invitedLegalPersonId?.value || 0,
        signingDate: getMinDate(data.signingDate) as number,
      })
    );

  return (
    <CForm onSubmit={handleSubmit(onSubmit)}>
      <CCard>
        <CCardBody>
          <CRow>
            <CCol xs={9}>
              {!modal && (
                <h3>{model?.id ? formatModelNameId(null, model.id) : agencyContractTranslations.route.create}</h3>
              )}

              <CRow>
                <CCol xs="12" sm="6" md="4">
                  <UiInput
                    register={register}
                    label={agencyContractTranslations.numberOfContract}
                    name="numberOfContract"
                    errors={errors}
                    required
                    disabled={rights.numberOfContract}
                  />
                </CCol>
                <CCol xs="12" sm="6" md="4">
                  <UiSelectLegalPersonModal
                    label={agencyContractTranslations.agencyLegalPersonId}
                    register={register}
                    name="agencyLegalPersonId"
                    options={legalPersonsAgentSelectOptions}
                    setValue={setValue}
                    value={watch('agencyLegalPersonId')}
                    errors={errors}
                    disabled={rights.agencyLegalPersonId}
                    setOnSave
                  />
                </CCol>
                <CCol xs="12" sm="6" md="4">
                  <UiSelectLegalPersonModal
                    label={agencyContractTranslations.invitedLegalPersonId}
                    register={register}
                    name="invitedLegalPersonId"
                    options={filteredInvitedLegalPersonOptions}
                    setValue={setValue}
                    value={watch('invitedLegalPersonId')}
                    errors={errors}
                    disabled={rights.invitedLegalPersonId}
                    setOnSave
                    legalTwo
                  />
                </CCol>

                {model?.creationDate && (
                  <CCol xs="12" sm="6" md="4">
                    <UiInput
                      label={agencyContractTranslations.creationDate}
                      register={register}
                      name="creationDate"
                      disabled
                    />
                  </CCol>
                )}
                <CCol xs="12" sm="6" md="4">
                  <UiDatePicker
                    control={control}
                    name="signingDate"
                    setValue={setValue}
                    value={watch('signingDate')}
                    label={agencyContractTranslations.signingDate}
                    errors={errors}
                    required
                    disabled={rights.signingDate}
                  />
                </CCol>
              </CRow>

              <UiSubmitButton saving={saveLoading} disabled={disabledToSave} />
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CForm>
  );
};
