import { SelectOptionsType } from 'components/UI/UiSelect';
import { MyProfileType } from 'models/Auth';
import { ClientContractModel } from 'models/ClientContract';
import { CustomerManagersListModel, CustomerModel } from 'models/Customer';
import { LegalPersonModel } from 'models/LegalPerson';
import { ServiceModel } from 'models/Service';
import { TariffModel } from 'models/Tariff';
import { storeName } from 'store/globalReducer';
import { RootReducer } from 'store/reducers';

type BaseSelectorEntityType<T> = {
  data: T;
  loading: boolean;
};

type OptionsSelectorEntityType<T, N> = BaseSelectorEntityType<T> & {
  options: SelectOptionsType<N>;
};

export const getProfileSelector = (state: RootReducer): BaseSelectorEntityType<MyProfileType> => ({
  data: state[storeName].profile as MyProfileType,
  loading: state[storeName].profileLoading,
});

export const getManagersSelector = (state: RootReducer): BaseSelectorEntityType<CustomerManagersListModel> => ({
  data: state[storeName].managers,
  loading: state[storeName].managersLoading,
});

export const getCustomersSelector = (state: RootReducer): OptionsSelectorEntityType<CustomerModel[], number> => ({
  data: state[storeName].customers,
  options: state[storeName].customersOptions,
  loading: state[storeName].customersLoading,
});

export const getLegalPersonsSelector = (state: RootReducer): OptionsSelectorEntityType<LegalPersonModel[], number> => ({
  data: state[storeName].legalPersons,
  options: state[storeName].legalPersonsOptions,
  loading: state[storeName].legalPersonsLoading,
});

export const getLegalPersons2Selector = (
  state: RootReducer
): OptionsSelectorEntityType<LegalPersonModel[], number> => ({
  data: state[storeName].legalPersons2,
  options: state[storeName].legalPersons2Options,
  loading: state[storeName].legalPersonsLoading,
});

export const getTariffsSelector = (state: RootReducer): OptionsSelectorEntityType<TariffModel[], number> => ({
  data: state[storeName].tariffs,
  options: state[storeName].tariffsOptions,
  loading: state[storeName].tariffsLoading,
});

export const getServicesSelector = (state: RootReducer): OptionsSelectorEntityType<ServiceModel[], number> => ({
  data: state[storeName].services,
  options: state[storeName].servicesOptions,
  loading: state[storeName].servicesLoading,
});

export const getClientContractsSelector = (
  state: RootReducer
): {
  data: ClientContractModel[];
  options: { label: string; value: number; rawLabel: string }[];
  loading: boolean;
} => ({
  data: state[storeName].clientContracts,
  options: state[storeName].clientContractsOptions,
  loading: state[storeName].clientContractsLoading,
});

export const getManagerName = (state: RootReducer): string => state[storeName].profile?.account.name || '';
export const getManagerId = (state: RootReducer): string => state[storeName].profile?.account.localAccountId || '';
export const isSidebarShow = (state: RootReducer): boolean => state[storeName].settings.sidebarShow;
