import { getCustomerRights } from 'components/System/Customer/formHelpers';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { CCard, CCardBody, CCol, CForm, CRow } from '@coreui/react';
import isEqual from 'lodash/isEqual';

import { UiListBtnNew } from 'components/UI/UiListBtnNew';
import { systemLegalPersonRoute } from 'containers/System/LegalPerson/routes';
import { UiRowCheckbox } from 'components/UI/UiCheckbox';
import { UiInput } from 'components/UI/UiInput';
import { SelectOptionsType, SelectOptionType, UiSelect } from 'components/UI/UiSelect';
import { CustomerFormModel, CustomerModel } from 'models/Customer';
import { useSelector } from 'react-redux';
import { getManagerId } from 'store/selectors';
import { customerTranslations } from 'translations/customer';
import { UiSubmitButton } from 'components/UI/UiSubmitButton';
import { SystemLegalPersonList } from 'containers/System/LegalPerson/list';
import { legalPersonTranslations } from 'translations/legalPerson';
import { UiTabs } from 'components/UI/UiTabs';
import { formatModelNameId } from 'utils/formatters';
import { prepareFormData } from 'utils/formFilter';
import { baseFinder } from 'utils/listHelpers';

type Props = {
  saveLoading: boolean;
  managerList: SelectOptionsType<string>;
  onSave: (data: CustomerFormModel) => void;
  model: CustomerModel | null;
  modal: boolean;
};

type FormValues = {
  name: string;
  isPartner: boolean;
  manager: SelectOptionType<string> | null;
};

export const SystemCustomerFormComponent = ({
  saveLoading,
  onSave,
  managerList,
  model,
  modal,
}: Props): React.ReactElement => {
  const updateMode = !!model?.id;
  const managerId = useSelector(getManagerId);

  const initValues = useMemo(
    () => ({
      name: model?.name || '',
      isPartner: model?.isPartner || false,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      manager: model?.manager ? baseFinder(managerList, model.manager) : baseFinder(managerList, managerId),
    }),
    []
  );

  const { register, handleSubmit, errors, setValue, watch } = useForm<FormValues>({
    defaultValues: initValues,
  });

  const disabledToSave = updateMode
    ? isEqual(initValues, {
        name: watch('name'),
        isPartner: watch('isPartner'),
        manager: watch('manager'),
      })
    : false;

  const rights = getCustomerRights(updateMode, model?.manager);

  const onSubmit = (data: FormValues) =>
    !disabledToSave &&
    onSave(
      prepareFormData({
        id: model?.id,
        isPartner: data.isPartner,
        name: data.name,
        manager: data.manager?.value || undefined,
      })
    );

  const component = (
    <>
      <UiInput
        register={register}
        name="name"
        label={customerTranslations.name}
        errors={errors}
        required
        disabled={rights.name}
      />
      <UiRowCheckbox
        register={register}
        name="isPartner"
        label={customerTranslations.partner}
        disabled={rights.isPartner}
      />

      <UiSelect
        label={customerTranslations.manager}
        register={register}
        name="manager"
        options={managerList}
        setValue={setValue}
        value={watch('manager')}
        errors={errors}
        required
        disabled={rights.manager}
      />
      <UiSubmitButton saving={saveLoading} disabled={disabledToSave} />
    </>
  );

  return (
    <CForm onSubmit={handleSubmit(onSubmit)}>
      {modal ? (
        component
      ) : (
        <CCard>
          <CCardBody>
            <h3>{model?.id ? formatModelNameId(model.name, model.id) : customerTranslations.route.create}</h3>

            {model?.id ? (
              <UiTabs
                items={{
                  index: {
                    title: customerTranslations.tab.index,
                    component: () => (
                      <CRow>
                        <CCol xl={6} lg={8} sm={9}>
                          {component}
                        </CCol>
                      </CRow>
                    ),
                  },
                  legalPersons: {
                    title: customerTranslations.tab.legalPersons,
                    component: () => (
                      <SystemLegalPersonList customerId={model.id}>
                        <UiListBtnNew title={legalPersonTranslations.new} to={systemLegalPersonRoute()} />
                      </SystemLegalPersonList>
                    ),
                  },
                }}
              />
            ) : (
              <CRow>
                <CCol xl={6} lg={8} sm={9}>
                  {component}
                </CCol>
              </CRow>
            )}
          </CCardBody>
        </CCard>
      )}
    </CForm>
  );
};
