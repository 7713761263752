export const serviceTypeTranslations = {
  route: {
    list: 'Типы сервисов',
    create: 'Типы сервисов (создание)',
    update: 'Типы сервисов (редактирование)',
  },
  new: 'Новый тип сервиса',
  isUrlActive: 'Есть URL',
  prefix: 'Префикс',
  name: 'Название',
  updated: 'Тип сервиса успешно обновлен',
  created: 'Тип сервиса успешно создан',
};
