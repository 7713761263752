export const orderTranslations = {
  route: {
    list: 'Заказы',
    create: 'Заказ (создание)',
    update: 'Заказ (редактирование)',
  },
  new: 'Новый заказ',
  creationDate: 'Дата начала (дата создания)',
  startingDate: 'Дата начала',
  completionDate: 'Дата завершения',
  customer: 'Заказчик',
  legalPersonToClientContract: 'Юридическое лицо',
  clientContractToService: 'Клиентский договор',
  serviceId: 'Сервис',
  status: 'Статус',
  manager: 'Аккаунт менеджер',
  numberOfOrder: 'Номер заказа',
  isTesting: 'Тестирование',
  product: 'Услуга',
  agent: 'Агент',
  created: 'Заказ успешно создан',
  updated: 'Заказ успешно обновлен',
  areEmptyRows: 'Необходимо заполнить значения в таблице услуг',
  emptyTariffPositions: 'Необходимо заполнить услуги',
  tab: {
    index: 'Основное',
    abonementProducts: 'Абонементные услуги',
    razProducts: 'Единовременные услуги',
  },
  orderStatus: {
    open: 'Открыт',
    closed: 'Завершён',
  },
};
