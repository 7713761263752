import React from 'react';
import { CHeader, CHeaderBrand, CSubheader, CBreadcrumbRouter, CHeaderNav, CToggler } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';

import TheHeaderDropdown from 'components/Layout/TheHeaderDropdown';
import { routes } from 'routes';
import { changeSettingsSidebarShowAction } from 'store/actions';
import { isSidebarShow } from 'store/selectors';

export const TheHeader = (): React.ReactElement => {
  const dispatch = useDispatch();
  const sidebarShow = useSelector(isSidebarShow);

  const toggleSidebar = () => {
    dispatch(changeSettingsSidebarShowAction(!sidebarShow));
  };

  return (
    <CHeader withSubheader>
      <CToggler inHeader className="ml-3 d-md-down-none" onClick={toggleSidebar} />
      <CHeaderBrand className="mx-auto d-lg-none" to="/system" />

      <CHeaderNav className="d-md-down-none mr-auto" />

      <CHeaderNav className="px-3">
        <TheHeaderDropdown />
      </CHeaderNav>

      <CSubheader className="px-3 justify-content-between">
        <CBreadcrumbRouter className="border-0 c-subheader-nav m-0 px-0 px-md-3" routes={routes} />
      </CSubheader>
    </CHeader>
  );
};
