import { useEffect, useState } from 'react';

import { defaultInitialFilter } from 'config';
import { doRequest } from 'utils/doRequest';
import { clearSearchParam, getSearchFilterParams, setSearchParam } from 'utils/urlParams';

let mounted = false;

export const useFilter = <T>(
  getData: (f: any) => Promise<T>,
  defaultFilter?: Record<string, any>
): {
  filter: any;
  onSearch: any;
  onReset: any;
  onPaginationChange: any;
  onChangePage: (p: number) => void;
  loading: boolean;
  model: T;
} => {
  useEffect(() => {
    mounted = true;
    return () => {
      mounted = false;
    };
  }, []);

  const [filter, setFilter] = useState<Record<string, any>>(() => ({ ...getSearchFilterParams(), ...defaultFilter }));
  const [loading, setLoading] = useState(true);
  const [model, setModel] = useState<any>([]);

  useEffect(() => {
    setLoading(true);

    doRequest(
      () => getData(filter),
      () => mounted
    )(setModel, () => setLoading(false));

    setSearchParam(filter);
  }, [filter, getData]);

  /**
   * не склеивать с текущим фильтром, т.к. бывают динамические значения фильтра
   * либо иметь этот факт ввиду
   */
  const onSearch = (filterValue: any) => setFilter({ ...filterValue, pageNumber: 1, pageLimit: filter.pageLimit });
  const onReset = () => {
    clearSearchParam();
    setFilter(defaultInitialFilter());
  };

  const onPaginationChange = (pageLimit: number) => {
    setFilter((f) => ({ ...f, pageLimit, pageNumber: 1 }));
  };
  const onChangePage = (pageNumber: number) => setFilter((f) => ({ ...f, pageNumber }));

  return {
    filter,
    onSearch,
    onReset,
    onPaginationChange,
    onChangePage,
    loading,
    model,
  };
};
