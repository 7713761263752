import React, { useCallback, useState } from 'react';

import { UiModal } from 'components/UI/UiModal';
import { SystemLegalPersonForm } from 'containers/System/LegalPerson/form';
import { SelectOptionType, UiSelect } from 'components/UI/UiSelect';
import { legalPersonTranslations } from 'translations/legalPerson';
import { LegalPersonModel } from 'models/LegalPerson';

// почти идентично UiSelectComponent Props
type Props = {
  register: any;
  name: string;
  options: SelectOptionType[] | { label: string; options: SelectOptionType[] }[];
  setValue: any;
  value: any;
  label: string;

  errors?: { [s: string]: unknown }; // объект с ошибками
  isLoading?: boolean;
  setOnSave?: boolean;
  multiple?: boolean;
  disabled?: boolean;
  allowEmptyValue?: boolean;
  modal?: boolean;
  legalTwo?: boolean;
};

/**
 * селект юрлиц с модалкой создания
 */
export const UiSelectLegalPersonModal = ({
  register,
  name,
  options,
  setValue,
  errors,
  value,
  label,
  multiple,
  isLoading = false,
  setOnSave = false,
  disabled = false,
  allowEmptyValue = false,
  modal = false,
  legalTwo = false,
}: Props): React.ReactElement => {
  const [showModal, setShowModal] = useState(false);
  const showModalFn = useCallback(() => {
    setShowModal(true);
  }, []);
  const hideModalFn = useCallback(() => {
    setShowModal(false);
  }, []);

  const onSaved = (savedItem: LegalPersonModel) => {
    if (setOnSave) {
      const valueToSet = { label: savedItem.name, value: savedItem.id };

      setValue(name, multiple ? [...(value || []), valueToSet] : valueToSet, { shouldValidate: true });
    }
  };

  return (
    <>
      <UiSelect
        label={label}
        register={register}
        name={name}
        options={options}
        setValue={setValue}
        value={value}
        errors={errors}
        appendFn={modal ? undefined : showModalFn}
        isLoading={isLoading}
        multiple={multiple}
        disabled={disabled}
        allowEmptyValue={allowEmptyValue}
      />
      {!modal && (
        <UiModal header={legalPersonTranslations.new} show={showModal} onClose={hideModalFn} size="xl">
          <SystemLegalPersonForm modal closeModal={hideModalFn} onSaved={onSaved} legalTwo={legalTwo} />
        </UiModal>
      )}
    </>
  );
};
