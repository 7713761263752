import React, { useCallback, useState } from 'react';

import { UiModal } from 'components/UI/UiModal';
import { SystemCustomerForm } from 'containers/System/Customer/form';
import { SelectOptionType, UiSelect } from 'components/UI/UiSelect';
import { customerTranslations } from 'translations/customer';
import { legalPersonTranslations } from 'translations/legalPerson';
import { CustomerModel } from 'models/Customer';

// почти идентично UiSelectComponent Props
type Props = {
  register: any;
  name: string;
  options: SelectOptionType[] | { label: string; options: SelectOptionType[] }[];
  setValue: any;
  value: any;

  modal?: boolean;
  errors?: { [s: string]: unknown }; // объект с ошибками
  allowEmptyValue?: boolean;
  disabled?: boolean;
  required?: boolean;
};

/**
 * селект заказчиков с модалкой создания
 */
export const UiSelectCustomerModal = ({
  register,
  name,
  options,
  setValue,
  errors,
  value,
  modal,
  allowEmptyValue = false,
  disabled = false,
  required = true,
}: Props): React.ReactElement => {
  const [showModal, setShowModal] = useState(false);
  const showModalFn = useCallback(() => {
    setShowModal(true);
  }, []);
  const hideModalFn = useCallback(() => {
    setShowModal(false);
  }, []);

  return (
    <>
      <UiSelect
        label={legalPersonTranslations.customer}
        register={register}
        name={name}
        options={options}
        setValue={setValue}
        value={value}
        errors={errors}
        required={required}
        appendFn={modal ? undefined : showModalFn}
        allowEmptyValue={allowEmptyValue}
        disabled={disabled}
      />
      {!modal && (
        <UiModal header={customerTranslations.new} show={showModal} onClose={hideModalFn}>
          <SystemCustomerForm
            modal
            closeModal={hideModalFn}
            onSaved={(savedItem: CustomerModel) => {
              setValue(name, { label: savedItem.name, value: savedItem.id }, { shouldValidate: true });
            }}
          />
        </UiModal>
      )}
    </>
  );
};
