import { CCard, CCardBody } from '@coreui/react';
import React from 'react';
import { Link } from 'react-router-dom';

import { UiTable } from 'components/UI/UiTable';
import { getProductType, ProductListModel, ProductModel } from 'models/Product';
import { getProductList } from 'api/product';
import { UiListBtnNew } from 'components/UI/UiListBtnNew';
import { systemProductRoute } from 'containers/System/Product/routes';
import { productTranslations } from 'translations/product';
import { formatCurrency } from 'utils/formatters';
import { useFilter } from 'utils/hooks/useFilter';
import { SystemProductFilter } from 'components/System/Product/filter';

const fields = [
  { key: 'id', label: 'ID' },
  { key: 'type', label: productTranslations.type },
  { key: 'name', label: productTranslations.name },
  { key: 'costPrice', label: productTranslations.costPrice },
];

type Props = {
  getData: typeof getProductList;
};

export const SystemProductListComponent = ({ getData }: Props): React.ReactElement => {
  const { onPaginationChange, loading, model, filter, onReset, onSearch, onChangePage } = useFilter<ProductListModel>(
    getData
  );

  return (
    <CCard>
      <CCardBody>
        <h3 className="text-center">{productTranslations.route.list}</h3>

        <UiListBtnNew title={productTranslations.new} to={systemProductRoute()} />

        <SystemProductFilter filter={filter} onSearch={onSearch} onReset={onReset} />

        <UiTable
          items={model}
          fields={fields}
          loading={loading}
          pageLimit={filter.pageLimit}
          onChangePage={onChangePage}
          scopedSlots={{
            id: ({ id }: ProductModel) => (
              <td>
                <Link to={systemProductRoute(id)}>{id}</Link>
              </td>
            ),
            type: ({ type }: ProductModel) => <td>{getProductType(type)}</td>,
            costPrice: ({ costPrice }: ProductModel) => <td>{formatCurrency(costPrice)}</td>,
          }}
          onPaginationChange={onPaginationChange}
        />
      </CCardBody>
    </CCard>
  );
};
