export const defaultSizeByPageTable = [10, 25, 50]; // выбор кол-ва записей на странице в таблицы

export const defaultInitialFilter = (): {
  pageNumber: number;
  pageLimit: number;
} => ({
  pageNumber: 1,
  pageLimit: defaultSizeByPageTable[1],
});

export const maxValueToGetAllDataWithPagination = 999; // макс значение для запроса, когда нужно получить все записи

export const HOST_URL = process.env.REACT_APP_HOST_URL;
export const API_URL = `${HOST_URL}/api`;
