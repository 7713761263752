import { getCurrentUserRole } from 'models/Auth';

/** Возвращает объект с полями которые нужно задизеблить в зависимости от прав пользователя */
export const getAgencyContractRights = (updateMode = false): { [key: string]: boolean } => {
  const { isAdmin, isChiefManager } = getCurrentUserRole();

  return {
    numberOfContract: updateMode,
    agencyLegalPersonId: updateMode && !(isAdmin || isChiefManager),
    invitedLegalPersonId: updateMode && !(isAdmin || isChiefManager),
    signingDate: updateMode && !(isAdmin || isChiefManager),
  };
};
