import { useCallback, useEffect, useState } from 'react';
import { getAllLegalPersonsList } from 'api/legalPerson';
import { SelectOptionsType } from 'components/UI/UiSelect';
import { doRequest } from 'utils/doRequest';

/** найти получателей, зависящих от заказчика */
export const useLegalPersonsOptions = (
  mounted = true
): [
  SelectOptionsType<number>, // Список
  (customerId?: number) => void, // Функция для загрузки списка
  boolean // Индикатор загрузки
] => {
  const [list, setList] = useState<SelectOptionsType<number>>([]);
  const [loading, setLoading] = useState(false);
  const getList = useCallback((customerId?: number) => {
    setLoading(true);
    doRequest(
      () => getAllLegalPersonsList({ customerId, isRecipient: true }),
      () => mounted
    )(
      ({ data }) => {
        setList(data.map(({ id, name }) => ({ value: id, label: name })));
      },
      () => {
        setLoading(false);
      },
      () => {
        setList([]);
      }
    );
  }, []);
  useEffect(() => {
    getList();
  }, []);

  return [list, getList, loading];
};
