import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { CCol, CForm, CRow } from '@coreui/react';

import { SelectOptionsType, SelectOptionType, UiSelect } from 'components/UI/UiSelect';
import { UiInput } from 'components/UI/UiInput';
import { UiDateRange } from 'components/UI/UiDateRange';
import { UiToolbar } from 'components/UI/UiToolbar';
import { agencyContractTranslations } from 'translations/agencyContract';
import { getMaxDate, getMinDate } from 'utils/date';
import { prepareFormData, prepareIds, setValueFilter } from 'utils/formFilter';
import { Optional } from 'utils/types';
import { AgencyContractFilterType } from 'models/AgencyContract';

type Props = {
  onSearch: (f: AgencyContractFilterType) => void;
  onReset: () => void;
  filter: any;
  legalPersonsAgentSelectOptions: SelectOptionsType<number>;
  legalPersonsRecipientSelectOptions: SelectOptionsType<number>;
};

type FormValues = Optional<{
  id: number;
  numberOfContract: string;
  agencyLegalPersonId: SelectOptionType<number>;
  invitedLegalPersonId: SelectOptionType<number>;
}> & {
  minSigningDate: Date | null;
  maxSigningDate: Date | null;
};

export const SystemAgencyContractFilter = ({
  onSearch,
  onReset,
  filter,
  legalPersonsAgentSelectOptions,
  legalPersonsRecipientSelectOptions,
}: Props): React.ReactElement => {
  const { register, handleSubmit, reset, setValue, watch, control } = useForm<FormValues>({
    defaultValues: {
      minSigningDate: null,
      maxSigningDate: null,
    },
  });

  const onLocalReset = () => {
    reset();
    onReset();
  };

  const onSearchLocal = (data: FormValues) =>
    onSearch(
      prepareFormData({
        ids: prepareIds(data.id),
        numberOfContract: data.numberOfContract?.length ? data.numberOfContract : undefined,
        agencyLegalPersonIds: prepareIds(data.agencyLegalPersonId?.value),
        invitedLegalPersonIds: prepareIds(data.invitedLegalPersonId?.value),
        minSigningDate: getMinDate(data.minSigningDate),
        maxSigningDate: getMaxDate(data.maxSigningDate),
      })
    );

  useEffect(() => {
    setValueFilter(filter, setValue, 'numberOfContract');

    if (filter.ids) {
      setValue('id', filter.ids[0]);
    }
  }, [filter, setValue]);

  return (
    <CForm onSubmit={handleSubmit(onSearchLocal)} onReset={onLocalReset}>
      <UiToolbar open={Object.values(watch()).some((f) => f)}>
        <CRow>
          <CCol xs="12" sm="6" md="4">
            <UiInput register={register} name="id" label="ID" />
          </CCol>
          <CCol xs="12" sm="6" md="4">
            <UiInput register={register} name="numberOfContract" label={agencyContractTranslations.numberOfContract} />
          </CCol>
          <CCol xs="12" sm="6" md="4">
            <UiSelect
              label={agencyContractTranslations.agencyLegalPersonId}
              name="agencyLegalPersonId"
              options={legalPersonsAgentSelectOptions}
              register={register}
              setValue={setValue}
              isClearable
              allowEmptyValue
              value={watch('agencyLegalPersonId')}
            />
          </CCol>
          <CCol xs="12" sm="6" md="4">
            <UiSelect
              label={agencyContractTranslations.invitedLegalPersonId}
              name="invitedLegalPersonId"
              options={legalPersonsRecipientSelectOptions}
              register={register}
              setValue={setValue}
              isClearable
              allowEmptyValue
              value={watch('invitedLegalPersonId')}
            />
          </CCol>
          <CCol xs="12" sm="6" md="4">
            <UiDateRange
              control={control}
              setValue={setValue}
              label={agencyContractTranslations.signingDate}
              nameMax="maxSigningDate"
              nameMin="minSigningDate"
              valueMax={watch('maxSigningDate')}
              valueMin={watch('minSigningDate')}
            />
          </CCol>
        </CRow>
      </UiToolbar>
    </CForm>
  );
};
