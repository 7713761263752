import { addHours, endOfDay, format, fromUnixTime, getUnixTime } from 'date-fns';

const getOffsetInMs = (): number => new Date().getTimezoneOffset() * 60;
const getOffsetInHours = (): number => new Date().getTimezoneOffset() / 60;

export const getEndOfDay = (date?: Date | number): Date =>
  endOfDay(date instanceof Date || typeof date === 'number' ? date : new Date());

/** GMT timestamp для сохранения в бэк */
export const getDateTime = (date: Date): number => Math.ceil(getUnixTime(date) - getOffsetInMs());

/** GMT форматирование времени (H:mm:ss) */
export const formatDate = (time: number, formatTo = 'dd.MM.yyyy', useOffset = true): string =>
  time < 1e5 ? '' : format(addHours(fromUnixTime(time), useOffset ? getOffsetInHours() : 0), formatTo);

/** GMT время для общего использования (вместо fromUnixTime) */
export const getUnixTimeGMT = (time: number | null): Date | undefined =>
  time ? addHours(fromUnixTime(time), getOffsetInHours()) : undefined;

/** минимальный timestamp переданной даты (00:00:00) */
export const getMinDate = (date?: Date | null): number | undefined => {
  if (date) {
    // date ? getDateTime(date) : undefined;
    return getDateTime(date);
  }
  return undefined;
};

/** максимальный timestamp переданной даты (23:59:59) */
export const getMaxDate = (date?: Date | null): number | undefined =>
  date ? getDateTime(getEndOfDay(date)) : undefined;
