import React from 'react';
import { CCard, CCardBody } from '@coreui/react';
import { Link } from 'react-router-dom';

import { UiTable } from 'components/UI/UiTable';
import { UiListBtnNew } from 'components/UI/UiListBtnNew';
import { serviceTypeTranslations } from 'translations/serviceType';
import { useFilter } from 'utils/hooks/useFilter';
import { getServiceTypeList } from 'api/serviceType';
import { ServiceTypeListModel, ServiceTypeModel } from 'models/ServiceType';
import { systemServiceTypeRoute } from 'containers/System/ServiceType/routes';

type Props = {
  getData: typeof getServiceTypeList;
};

const fields = [
  { key: 'id', label: 'ID' },
  { key: 'name', label: serviceTypeTranslations.name },
  { key: 'isUrlActive', label: serviceTypeTranslations.isUrlActive },
  { key: 'prefix', label: serviceTypeTranslations.prefix },
];

export const SystemServiceTypeListComponent = ({ getData }: Props): React.ReactElement => {
  const { onPaginationChange, loading, model, filter, onChangePage } = useFilter<ServiceTypeListModel>(getData);

  return (
    <CCard>
      <CCardBody>
        <h3 className="text-center">{serviceTypeTranslations.route.list}</h3>

        <UiListBtnNew title={serviceTypeTranslations.new} to={systemServiceTypeRoute()} />

        <UiTable
          items={model}
          fields={fields}
          loading={loading}
          pageLimit={filter.pageLimit}
          onChangePage={onChangePage}
          scopedSlots={{
            id: ({ id }: ServiceTypeModel) => (
              <td>
                <Link to={systemServiceTypeRoute(id)}>{id}</Link>
              </td>
            ),
            isUrlActive: ({ isUrlActive }: ServiceTypeModel) => <td>{isUrlActive ? 'Да' : 'Нет'}</td>,
          }}
          onPaginationChange={onPaginationChange}
        />
      </CCardBody>
    </CCard>
  );
};
