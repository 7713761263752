import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { CCol, CForm, CRow } from '@coreui/react';

import {
  clientContractMethodOfInvoiceSelectOptions,
  ClientContractMethodOfInvoiceType,
} from 'components/System/ClientContract/common';
import { UiDateRange } from 'components/UI/UiDateRange';
import { ClientContractFilterType } from 'models/ClientContract';
import { SelectOptionsType, SelectOptionType, UiSelect } from 'components/UI/UiSelect';
import { UiInput } from 'components/UI/UiInput';
import { UiToolbar } from 'components/UI/UiToolbar';
import { clientContractTranslations } from 'translations/clientContract';
import { getMaxDate, getMinDate } from 'utils/date';
import { prepareFormData, prepareIds, setValueFilter } from 'utils/formFilter';
import { baseFinder } from 'utils/listHelpers';
import { Optional } from 'utils/types';

type Props = {
  onSearch: (f: ClientContractFilterType) => void;
  onReset: () => void;
  filter: any;
  managerOptionsList: SelectOptionsType<string>;
  legalPersonsExecutorSelectOptions: SelectOptionsType<number>;
  legalPersonsRecipientSelectOptions: SelectOptionsType<number>;
  legalPersonsAllOptions: SelectOptionsType<number>;
};

type FormValues = Optional<{
  id: number;
  numberOfContract: string;
  rhsLegalPersonId: SelectOptionType<number>;
  lhsLegalPersonId: SelectOptionType<number>;
  agencyLegalPersonId: SelectOptionType<number>;
  methodOfInvoice: SelectOptionType<ClientContractMethodOfInvoiceType>;
  manager: SelectOptionType<string>;
}> & {
  dateStartingTo: Date | null;
  dateStartingFrom: Date | null;
  dateCompletionTo: Date | null;
  dateCompletionFrom: Date | null;
};

export const SystemClientContractFilter = ({
  onSearch,
  onReset,
  filter,
  managerOptionsList,
  legalPersonsExecutorSelectOptions,
  legalPersonsRecipientSelectOptions,
  legalPersonsAllOptions,
}: Props): React.ReactElement => {
  const { register, handleSubmit, reset, setValue, watch, control } = useForm<FormValues>({
    defaultValues: {
      dateStartingFrom: null,
      dateStartingTo: null,
      dateCompletionFrom: null,
      dateCompletionTo: null,
    },
  });

  const onLocalReset = () => {
    reset();
    onReset();
  };

  const onSearchLocal = (data: FormValues) =>
    onSearch(
      prepareFormData({
        ids: prepareIds(data.id),
        numberOfContract: data.numberOfContract?.length ? data.numberOfContract : undefined,
        methodOfInvoice: data.methodOfInvoice?.value || undefined,
        managers: data.manager ? [data.manager.value] : undefined,
        rhsLegalPersonId: data.rhsLegalPersonId?.value || undefined,
        lhsLegalPersonId: data.lhsLegalPersonId?.value || undefined,
        agencyLegalPersonId: data.agencyLegalPersonId?.value || undefined,
        dateStartingFrom: getMinDate(data.dateStartingFrom),
        dateStartingTo: getMaxDate(data.dateStartingTo),
        dateCompletionFrom: getMinDate(data.dateCompletionFrom),
        dateCompletionTo: getMaxDate(data.dateCompletionTo),
      })
    );

  useEffect(() => {
    setValueFilter(filter, setValue, 'numberOfContract');
    setValueFilter(filter, setValue, 'agencyLegalPersonId', () =>
      baseFinder(legalPersonsAllOptions, +filter.agencyLegalPersonId)
    );
    setValueFilter(filter, setValue, 'methodOfInvoice', () =>
      baseFinder(clientContractMethodOfInvoiceSelectOptions, filter.methodOfInvoice)
    );
    setValueFilter(filter, setValue, 'rhsLegalPersonId', () =>
      baseFinder(legalPersonsRecipientSelectOptions, +filter.rhsLegalPersonId)
    );
    setValueFilter(filter, setValue, 'lhsLegalPersonId', () =>
      baseFinder(legalPersonsExecutorSelectOptions, +filter.lhsLegalPersonId)
    );

    if (filter.ids) {
      setValue('id', filter.ids[0]);
    }
  }, [filter, setValue]);

  return (
    <CForm onSubmit={handleSubmit(onSearchLocal)} onReset={onLocalReset}>
      <UiToolbar open={Object.values(watch()).some((f) => f)}>
        <CRow>
          <CCol xs="12" sm="6" md="4">
            <UiInput register={register} name="id" label="ID" />
          </CCol>
          <CCol xs="12" sm="6" md="4">
            <UiInput register={register} name="numberOfContract" label={clientContractTranslations.numberOfContract} />
          </CCol>
          <CCol xs="12" sm="6" md="4">
            <UiSelect
              label={clientContractTranslations.rhsLegalPersonId}
              name="rhsLegalPersonId"
              options={legalPersonsRecipientSelectOptions}
              register={register}
              setValue={setValue}
              isClearable
              allowEmptyValue
              value={watch('rhsLegalPersonId')}
            />
          </CCol>
          <CCol xs="12" sm="6" md="4">
            <UiSelect
              label={clientContractTranslations.lhsLegalPersonId}
              name="lhsLegalPersonId"
              options={legalPersonsExecutorSelectOptions}
              register={register}
              setValue={setValue}
              isClearable
              allowEmptyValue
              value={watch('lhsLegalPersonId')}
            />
          </CCol>
          <CCol xs="12" sm="6" md="4">
            <UiSelect
              label={clientContractTranslations.agent}
              name="agencyLegalPersonId"
              options={legalPersonsAllOptions}
              register={register}
              setValue={setValue}
              isClearable
              allowEmptyValue
              value={watch('agencyLegalPersonId')}
            />
          </CCol>
          <CCol xs="12" sm="6" md="4">
            <UiSelect
              register={register}
              name="methodOfInvoice"
              options={clientContractMethodOfInvoiceSelectOptions}
              setValue={setValue}
              value={watch('methodOfInvoice')}
              isClearable
              allowEmptyValue
              label={clientContractTranslations.methodOfInvoice.title}
            />
          </CCol>
          <CCol xs="12" sm="6" md="4">
            <UiSelect
              label={clientContractTranslations.manager}
              name="manager"
              options={managerOptionsList}
              register={register}
              setValue={setValue}
              isClearable
              allowEmptyValue
              value={watch('manager')}
            />
          </CCol>
          <CCol xs="12" sm="6" md="4">
            <UiDateRange
              control={control}
              setValue={setValue}
              label={clientContractTranslations.startingDate}
              nameMax="dateStartingTo"
              nameMin="dateStartingFrom"
              valueMax={watch('dateStartingTo')}
              valueMin={watch('dateStartingFrom')}
            />
          </CCol>
          <CCol xs="12" sm="6" md="4">
            <UiDateRange
              control={control}
              setValue={setValue}
              label={clientContractTranslations.completionDate}
              nameMax="dateCompletionTo"
              nameMin="dateCompletionFrom"
              valueMax={watch('dateCompletionTo')}
              valueMin={watch('dateCompletionFrom')}
            />
          </CCol>
        </CRow>
      </UiToolbar>
    </CForm>
  );
};
