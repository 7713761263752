import { maxValueToGetAllDataWithPagination } from 'config';
import { ServiceFormType, ServiceListFilterType, ServiceListModel, ServiceModel } from 'models/Service';
import { httpGet, httpPost, httpPut } from 'utils/http';

const path = '/admin/services';

/** создать сервис */
export const createService = (model: ServiceFormType): Promise<ServiceModel> => httpPost(`${path}/service`, model);

/** обновить сервис */
export const updateService = (id: number, model: { manager: string }): Promise<ServiceModel> =>
  httpPut(`${path}/service/${id}`, model);

/** получить сервис */
export const getService = (id: number): Promise<ServiceModel> => httpGet(`${path}/service/${id}`);

/** получить список сервисов */
export const getServiceList = (filter?: ServiceListFilterType): Promise<ServiceListModel> =>
  httpGet(`${path}/filter`, filter);

/** получить список всех сервисов */
export const getAllServiceList = (filter?: ServiceListFilterType): Promise<ServiceListModel> =>
  httpGet(`${path}/filter`, { ...filter, pageLimit: maxValueToGetAllDataWithPagination });
