import React, { useEffect, useState } from 'react';

import { getAllCustomersList } from 'api/customer';
import { getAllLegalPersonsList } from 'api/legalPerson';
import { SelectOptionsType } from 'components/UI/UiSelect';
import { getInvoiceOnOrderList } from 'api/invoiceOnOrder';
import { SystemInvoiceOnOrderListComponent } from 'components/System/InvoiceOnOrder/list';
import { formatDate } from 'utils/date';
import { doRequest } from 'utils/doRequest';
import { getAllClientContracts } from 'api/clientContract';
import { getAllOrderList } from 'api/order';
import { getAllServiceList } from 'api/service';

let mounted = false;

export const SystemInvoiceOnOrderList: React.FC = () => {
  useEffect(() => {
    mounted = true;
    return () => {
      mounted = false;
    };
  }, []);

  const [models, setModels] = useState<{
    legalPersons: SelectOptionsType<number>;
    customers: SelectOptionsType<number>;
    clientContracts: SelectOptionsType<number>;
    services: SelectOptionsType<number>;
    orders: SelectOptionsType<number>;
  }>({ legalPersons: [], customers: [], clientContracts: [], services: [], orders: [] });

  useEffect(() => {
    doRequest(
      () =>
        Promise.all([
          getAllLegalPersonsList(),
          getAllCustomersList(),
          getAllClientContracts(),
          getAllServiceList(),
          getAllOrderList(),
        ]),
      () => mounted
    )(([legalPersons, customers, clientContracts, services, orders]) => {
      setModels({
        legalPersons: legalPersons.data.map(({ name, id }) => ({ value: id, label: name })),
        customers: customers.data.map(({ name, id }) => ({ value: id, label: name })),
        clientContracts: clientContracts.data.map(({ numberOfContract, id }) => ({
          value: id,
          label: numberOfContract,
        })),
        services: services.data.map(({ name, id }) => ({ value: id, label: name })),
        orders: orders.data.map(({ numberOfContract, startingDateOfContract, id }) => ({
          value: id,
          label: `${numberOfContract} от ${formatDate(startingDateOfContract)}`,
        })),
      });
    });
  }, []);

  return (
    <SystemInvoiceOnOrderListComponent
      getData={getInvoiceOnOrderList}
      customerOptionsList={models.customers}
      legalPersonsOptionsList={models.legalPersons}
      clientContractsOptionsList={models.clientContracts}
      servicesOptionsList={models.services}
      ordersOptionsList={models.orders}
    />
  );
};
