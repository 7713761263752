import { SelectOptionsType } from 'components/UI/UiSelect';
import { ClientContractModel } from 'models/ClientContract';
import { ServiceModel } from 'models/Service';
import { TariffModel } from 'models/Tariff';
import { AnyAction } from 'redux';

import { CustomerManagersListModel, CustomerModel } from 'models/Customer';
import { MyProfileType } from 'models/Auth';
import { LegalPersonModel } from 'models/LegalPerson';
import {
  ADD_CLIENT_CONTRACT,
  ADD_CUSTOMER,
  ADD_LEGAL_PERSON,
  ADD_LEGAL_PERSON2,
  ADD_SERVICE,
  ADD_TARIFF,
  CHANGE_SIDEBAR_SHOW,
  GET_CLIENT_CONTRACTS_OK,
  GET_CLIENT_CONTRACTS_START,
  GET_CUSTOMERS_OK,
  GET_CUSTOMERS_START,
  GET_LEGAL_PERSONS2_OK,
  GET_LEGAL_PERSONS2_START,
  GET_LEGAL_PERSONS_OK,
  GET_LEGAL_PERSONS_START,
  GET_MANAGERS_OK,
  GET_MANAGERS_START,
  GET_PROFILE_OK,
  GET_PROFILE_START,
  GET_SERVICES_OK,
  GET_SERVICES_START,
  GET_TARIFFS_OK,
  GET_TARIFFS_START,
} from 'store/constants';
import { formatDate } from 'utils/date';

export type globalReducerType = {
  profile: MyProfileType | null;
  profileLoading: boolean;

  managers: CustomerManagersListModel;
  managersLoading: boolean;

  customers: CustomerModel[];
  customersOptions: SelectOptionsType<number>; // sorry
  customersLoading: boolean;

  legalPersons: LegalPersonModel[];
  legalPersonsOptions: SelectOptionsType<number>; // sorry
  legalPersonsLoading: boolean;

  // SO SORRY
  legalPersons2: LegalPersonModel[];
  legalPersons2Options: SelectOptionsType<number>; // sorry
  legalPersons2Loading: boolean;

  tariffs: TariffModel[];
  tariffsOptions: SelectOptionsType<number>; // sorry
  tariffsLoading: boolean;

  services: ServiceModel[];
  servicesOptions: SelectOptionsType<number>; // sorry
  servicesLoading: boolean;

  clientContracts: ClientContractModel[];
  clientContractsOptions: { label: string; value: number; rawLabel: string }[]; // sorry
  clientContractsLoading: boolean;

  settings: {
    sidebarShow: boolean;
  };
};

const initialState: globalReducerType = {
  profile: null,
  profileLoading: true,

  managers: [],
  managersLoading: false,

  customers: [],
  customersOptions: [],
  customersLoading: false,

  legalPersons: [],
  legalPersonsOptions: [],
  legalPersonsLoading: false,

  legalPersons2: [],
  legalPersons2Options: [],
  legalPersons2Loading: false,

  tariffs: [],
  tariffsOptions: [],
  tariffsLoading: false,

  services: [],
  servicesOptions: [],
  servicesLoading: false,

  clientContracts: [],
  clientContractsOptions: [],
  clientContractsLoading: false,

  settings: {
    sidebarShow: true,
  },
};

export const storeName = 'global';

export const globalReducer = {
  [storeName]: (state: globalReducerType = initialState, { type, payload }: AnyAction): globalReducerType => {
    switch (type) {
      case GET_PROFILE_START: {
        return {
          ...state,
          profile: null,
          profileLoading: true,
        };
      }
      case GET_PROFILE_OK: {
        return {
          ...state,
          profile: payload.data,
          profileLoading: false,
        };
      }

      case GET_MANAGERS_START: {
        return {
          ...state,
          managers: [],
          managersLoading: true,
        };
      }
      case GET_MANAGERS_OK: {
        return {
          ...state,
          managers: payload.data,
          managersLoading: false,
        };
      }

      case GET_CUSTOMERS_START: {
        return {
          ...state,
          customers: [],
          customersLoading: true,
        };
      }
      case GET_CUSTOMERS_OK: {
        return {
          ...state,
          customers: payload.data.data,
          customersOptions: payload.data.data.map(({ name, id }: CustomerModel) => ({ value: id, label: name })),
          customersLoading: false,
        };
      }
      case ADD_CUSTOMER: {
        const foundId = state.customers.findIndex((item) => payload.data.id === item.id);
        const newArr = [...state.customers];
        const newArrOptions = [...state.customersOptions];

        if (foundId === -1) {
          newArr.push(payload.data);
          newArrOptions.push({ value: payload.data.id, label: payload.data.name });
        } else {
          newArr[foundId] = payload.data;
        }
        return {
          ...state,
          customers: newArr,
          customersOptions: newArrOptions,
        };
      }

      case GET_LEGAL_PERSONS_START: {
        return {
          ...state,
          legalPersons: [],
          legalPersonsLoading: true,
        };
      }
      case GET_LEGAL_PERSONS_OK: {
        return {
          ...state,
          legalPersons: payload.data.data,
          legalPersonsOptions: payload.data.data.map(({ name, id }: LegalPersonModel) => ({ value: id, label: name })),
          legalPersonsLoading: false,
        };
      }
      case ADD_LEGAL_PERSON: {
        const newArr = [...state.legalPersons];
        newArr.push(payload.data);
        return {
          ...state,
          legalPersons: newArr,
          legalPersonsOptions: [...state.legalPersonsOptions, { value: payload.data.id, label: payload.data.name }],
        };
      }

      case GET_LEGAL_PERSONS2_START: {
        return {
          ...state,
          legalPersons2: [],
          legalPersons2Loading: true,
        };
      }
      case GET_LEGAL_PERSONS2_OK: {
        return {
          ...state,
          legalPersons2: payload.data.data,
          legalPersons2Options: payload.data.data.map(({ name, id }: LegalPersonModel) => ({ value: id, label: name })),
          legalPersons2Loading: false,
        };
      }
      case ADD_LEGAL_PERSON2: {
        const newArr = [...state.legalPersons2];
        newArr.push(payload.data);
        return {
          ...state,
          legalPersons2: newArr,
          legalPersons2Options: [...state.legalPersons2Options, { value: payload.data.id, label: payload.data.name }],
        };
      }

      case GET_TARIFFS_START: {
        return {
          ...state,
          tariffs: [],
          tariffsOptions: [],
          tariffsLoading: true,
        };
      }
      case GET_TARIFFS_OK: {
        return {
          ...state,
          tariffs: payload.data.data,
          tariffsOptions: payload.data.data.map(({ name, id }: TariffModel) => ({ value: id, label: name })),
          tariffsLoading: false,
        };
      }
      case ADD_TARIFF: {
        const newArr = [...state.tariffs];
        newArr.push(payload.data);
        return {
          ...state,
          tariffs: newArr,
          tariffsOptions: [...state.tariffsOptions, { value: payload.data.id, label: payload.data.name }],
        };
      }

      case GET_SERVICES_START: {
        return {
          ...state,
          services: [],
          servicesOptions: [],
          servicesLoading: true,
        };
      }
      case GET_SERVICES_OK: {
        return {
          ...state,
          services: payload.data.data,
          servicesOptions: payload.data.data.map(({ name, id }: ServiceModel) => ({ value: id, label: name })),
          servicesLoading: false,
        };
      }
      case ADD_SERVICE: {
        const newArr = [...state.services];
        newArr.push(payload.data);
        return {
          ...state,
          services: newArr,
          servicesOptions: [...state.servicesOptions, { value: payload.data.id, label: payload.data.name }],
        };
      }

      case GET_CLIENT_CONTRACTS_START: {
        return {
          ...state,
          clientContracts: [],
          clientContractsOptions: [],
          clientContractsLoading: true,
        };
      }
      case GET_CLIENT_CONTRACTS_OK: {
        return {
          ...state,
          clientContracts: payload.data.data,
          clientContractsOptions: payload.data.data.map(
            ({ numberOfContract, startingDate, id }: ClientContractModel) => ({
              value: id,
              label: `${numberOfContract} от ${formatDate(startingDate)}`,
              rawLabel: numberOfContract,
            })
          ),
          clientContractsLoading: false,
        };
      }
      case ADD_CLIENT_CONTRACT: {
        const newArr = [...state.clientContracts];
        newArr.push(payload.data);
        return {
          ...state,
          clientContracts: newArr,
          clientContractsOptions: [
            ...state.clientContractsOptions,
            {
              value: payload.data.id,
              label: `${payload.data.numberOfContract} от ${formatDate(payload.data.creationDate)}`,
              rawLabel: payload.data.numberOfContract,
            },
          ],
        };
      }

      case CHANGE_SIDEBAR_SHOW: {
        return {
          ...state,
          settings: {
            sidebarShow: payload.show,
          },
        };
      }

      default:
        return state;
    }
  },
};
