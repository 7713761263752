import { UiDateRange } from 'components/UI/UiDateRange';
import { OrderModel, TOrdersFilter } from 'models/Order';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { CCol, CForm, CRow } from '@coreui/react';

import { ClientContractFilterType } from 'models/ClientContract';
import { SelectOptionsType, SelectOptionType, UiSelect } from 'components/UI/UiSelect';
import { UiInput } from 'components/UI/UiInput';
import { UiToolbar } from 'components/UI/UiToolbar';
import { orderTranslations } from 'translations/order';
import { getMaxDate, getMinDate } from 'utils/date';
import { prepareFormData, prepareIds } from 'utils/formFilter';
import {
  TSelectOptionClientContract,
  useClientsContractsOptions,
} from 'utils/hooks/SelectOptionsHooks/useClientsContractsOptions';
import { useLegalPersonsOptions } from 'utils/hooks/SelectOptionsHooks/useLegalPersonsOptions';
import { useServicesOptions } from 'utils/hooks/SelectOptionsHooks/useServicesOptions';
import { baseFinder } from 'utils/listHelpers';
import { Optional } from 'utils/types';

type Props = {
  onSearch: (f: ClientContractFilterType) => void;
  onReset: () => void;
  customerList: SelectOptionsType<number>;
  agencyList: SelectOptionsType<number>;
  managerList: SelectOptionsType<string>;
};

type FormValues = Optional<{
  id: number;
  numberOfOrder: string | null;
  customer: SelectOptionType<number> | null;
  legalPerson: SelectOptionType<number> | null;
  agencyLegalPersonId: SelectOptionType<number> | null;
  clientContract: TSelectOptionClientContract | null;
  service: SelectOptionType<number> | null;
  manager: SelectOptionType<string> | null;
  status: SelectOptionType<OrderModel['status']> | null;
}> & {
  dateStartingTo: Date | null;
  dateStartingFrom: Date | null;
  dateCompletionTo: Date | null;
  dateCompletionFrom: Date | null;
};

export const SystemOrderFilter = ({
  onSearch,
  onReset,
  customerList,
  managerList,
  agencyList,
}: Props): React.ReactElement => {
  const { register, handleSubmit, reset, setValue, watch, control } = useForm<FormValues>({
    defaultValues: {
      status: null,
      customer: null,
      numberOfOrder: null,
      legalPerson: null,
      clientContract: null,
      service: null,
      manager: null,
      dateStartingFrom: null,
      dateStartingTo: null,
      dateCompletionFrom: null,
      dateCompletionTo: null,
      agencyLegalPersonId: null,
    },
  });

  const onLocalReset = () => {
    reset();
    onReset();
  };

  const onSearchLocal = (data: FormValues) => {
    onSearch(
      prepareFormData<TOrdersFilter>({
        ids: prepareIds(data.id),
        numberOfOrder: data.numberOfOrder ? data.numberOfOrder : undefined,
        nameCustomer: data.customer?.label || undefined,
        nameLegalPerson: data.legalPerson?.label || undefined,
        numberOfContract: data.clientContract?.numberOfContract || undefined,
        serviceId: data.service?.value || undefined,
        agencyLegalPersonId: data.agencyLegalPersonId?.value || undefined,
        managers: data.manager?.value ? [data.manager?.value] : undefined,
        dateCreationFrom: getMinDate(data.dateStartingFrom),
        dateCreationTo: getMaxDate(data.dateStartingTo),
        dateCompletionFrom: getMinDate(data.dateCompletionFrom),
        dateCompletionTo: getMaxDate(data.dateCompletionTo),
        status: data.status?.value || undefined,
      })
    );
  };

  /** найти получателей, зависящих от заказчика */
  const [legalPersons, getLegalPersons, legalPersonsLoading] = useLegalPersonsOptions();

  /** найти клиентские договоры зависящие от юл. */
  const [clientContracts, getClientContracts, clientContractsLoading] = useClientsContractsOptions();

  const [services, getServices, servicesLoading] = useServicesOptions();

  /** Запросить список юр лиц */
  const customer = watch('customer');
  useEffect(() => {
    getLegalPersons(customer?.value ?? undefined);
  }, [customer]);

  /** Запросить список клиентских договоров */
  const legalPerson = watch('legalPerson');
  useEffect(() => {
    getClientContracts(legalPerson?.value ?? undefined);
  }, [legalPerson]);

  /** Запросить список сервисов */
  const clientContract = watch('clientContract');
  useEffect(() => {
    getServices(clientContract?.value ?? undefined);
  }, [clientContract]);

  /** Сбросить значение юр лица, если нет соответствий в списке*/
  useEffect(() => {
    if (legalPerson?.value && !baseFinder(legalPersons, legalPerson?.value)) {
      setValue('legalPerson', null);
    }
  }, [legalPersons]);

  /** Сбросить значение контракта КЛИЕНТА, если нет соответствий в списке*/
  useEffect(() => {
    if (clientContract?.value && !baseFinder(clientContracts, clientContract?.value)) {
      setValue('clientContract', null);
    }
  }, [clientContracts]);

  /** Сбросить значение сервиса, если нет соответствий в списке*/
  const service = watch('service');
  useEffect(() => {
    if (service?.value && !baseFinder(services, service?.value)) {
      setValue('service', null);
    }
  }, [service]);

  return (
    <CForm onSubmit={handleSubmit(onSearchLocal)} onReset={onLocalReset}>
      <UiToolbar open={Object.values(watch()).some((f) => f)}>
        <CRow>
          <CCol xs="12" sm="6" md="4">
            <UiInput register={register} name="id" label="ID" />
          </CCol>

          <CCol xs="12" sm="6" md="4">
            <UiInput register={register} name="numberOfOrder" label={orderTranslations.numberOfOrder} />
          </CCol>

          <CCol xs="12" sm="6" md="4">
            <UiSelect
              label={orderTranslations.customer}
              register={register}
              name="customer"
              options={customerList}
              setValue={setValue}
              value={watch('customer')}
              allowEmptyValue
            />
          </CCol>

          <CCol xs="12" sm="6" md="4">
            <UiSelect
              label={orderTranslations.legalPersonToClientContract}
              register={register}
              name="legalPerson"
              options={legalPersons}
              setValue={setValue}
              value={watch('legalPerson')}
              isLoading={legalPersonsLoading}
              allowEmptyValue
            />
          </CCol>

          <CCol xs="12" sm="6" md="4">
            <UiSelect
              label={orderTranslations.clientContractToService}
              register={register}
              name="clientContract"
              options={clientContracts}
              setValue={setValue}
              value={watch('clientContract')}
              isLoading={clientContractsLoading}
              allowEmptyValue
            />
          </CCol>

          <CCol xs="12" sm="6" md="4">
            <UiSelect
              label={orderTranslations.serviceId}
              register={register}
              name="service"
              options={services}
              setValue={setValue}
              value={watch('service')}
              isLoading={servicesLoading}
              allowEmptyValue
            />
          </CCol>

          <CCol xs="12" sm="6" md="4">
            <UiSelect
              label={orderTranslations.manager}
              register={register}
              name="manager"
              options={managerList}
              setValue={setValue}
              value={watch('manager')}
              allowEmptyValue
            />
          </CCol>

          <CCol xs="12" sm="6" md="4">
            <UiDateRange
              control={control}
              setValue={setValue}
              label={orderTranslations.creationDate}
              nameMax="dateStartingTo"
              nameMin="dateStartingFrom"
              valueMax={watch('dateStartingTo')}
              valueMin={watch('dateStartingFrom')}
            />
          </CCol>

          <CCol xs="12" sm="6" md="4">
            <UiDateRange
              control={control}
              setValue={setValue}
              label={orderTranslations.completionDate}
              nameMax="dateCompletionTo"
              nameMin="dateCompletionFrom"
              valueMax={watch('dateCompletionTo')}
              valueMin={watch('dateCompletionFrom')}
            />
          </CCol>

          <CCol xs="12" sm="6" md="4">
            <UiSelect
              label={orderTranslations.status}
              register={register}
              name="status"
              options={[
                {
                  label: orderTranslations.orderStatus.open,
                  value: 'open',
                },
                {
                  label: orderTranslations.orderStatus.closed,
                  value: 'closed',
                },
              ]}
              setValue={setValue}
              value={watch('status')}
              allowEmptyValue
              isClearable
            />
          </CCol>

          <CCol xs="12" sm="6" md="4">
            <UiSelect
              label={orderTranslations.agent}
              register={register}
              name="agencyLegalPersonId"
              options={agencyList}
              setValue={setValue}
              value={watch('agencyLegalPersonId')}
              allowEmptyValue
              isClearable
            />
          </CCol>
        </CRow>
      </UiToolbar>
    </CForm>
  );
};
