export const GET_PROFILE_START = '[GLOBAL] load profile [start]';
export const GET_PROFILE_OK = '[GLOBAL] load profile [ok]';

export const GET_MANAGERS_START = '[GLOBAL] get managers [start]';
export const GET_MANAGERS_OK = '[GLOBAL] get managers [ok]';

export const GET_CUSTOMERS_START = '[GLOBAL] get customers [start]';
export const GET_CUSTOMERS_OK = '[GLOBAL] get customers [ok]';
export const ADD_CUSTOMER = '[GLOBAL] add customers to store';

export const GET_LEGAL_PERSONS_START = '[GLOBAL] get legal persons [start]';
export const GET_LEGAL_PERSONS_OK = '[GLOBAL] get legal persons [ok]';
export const ADD_LEGAL_PERSON = '[GLOBAL] add legal person to store';

export const GET_LEGAL_PERSONS2_START = '[GLOBAL] get legal persons2 [start]';
export const GET_LEGAL_PERSONS2_OK = '[GLOBAL] get legal persons2 [ok]';
export const ADD_LEGAL_PERSON2 = '[GLOBAL] add legal person2 to store';

export const GET_TARIFFS_START = '[GLOBAL] get tariffs [start]';
export const GET_TARIFFS_OK = '[GLOBAL] get tariffs [ok]';
export const ADD_TARIFF = '[GLOBAL] add tariff to store';

export const GET_SERVICES_START = '[GLOBAL] get services [start]';
export const GET_SERVICES_OK = '[GLOBAL] get services [ok]';
export const ADD_SERVICE = '[GLOBAL] add service to store';

export const GET_CLIENT_CONTRACTS_START = '[GLOBAL] get client contracts [start]';
export const GET_CLIENT_CONTRACTS_OK = '[GLOBAL] get client contracts [ok]';
export const ADD_CLIENT_CONTRACT = '[GLOBAL] add client contract to store';

export const CHANGE_SIDEBAR_SHOW = '[GLOBAL SETTINGS] change sidebar show';
