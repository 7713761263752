import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getAllTariffsStartAction } from 'store/actions';
import { getTariffsSelector } from 'store/selectors';
import { prepareIds } from 'utils/formFilter';
import { SelectOptionsType } from 'components/UI/UiSelect';

/** Загрузить все не архивные тарифы */
export const useTariffsOptions = (): [
  SelectOptionsType<number>, // Список
  () => void, // Функция для загрузки списка
  boolean // Индикатор загрузки
] => {
  const dispatch = useDispatch();
  const { loading, options } = useSelector(getTariffsSelector);

  const getList = useCallback(
    (personId?: number) => {
      dispatch(getAllTariffsStartAction({ legalPersonIds: prepareIds(personId), isArchive: false }));
    },
    [dispatch]
  );
  useEffect(getList, []);

  return [options, getList, loading];
};
