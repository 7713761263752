import isEqual from 'lodash/isEqual';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { CCard, CCardBody, CCol, CForm, CRow } from '@coreui/react';
import cloneDeep from 'lodash/cloneDeep';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { getOrderRights } from 'components/System/Order/formHelpers';
import { UiSelectServiceModal } from 'components/UI/UiSelectServiceModal';
import { ExtendedProductModel, ProductTypesEnum } from 'models/Product';
import { UiRowCheckbox } from 'components/UI/UiCheckbox';
import { UiInput } from 'components/UI/UiInput';
import { UiSubmitButton } from 'components/UI/UiSubmitButton';
import { UiDatePicker } from 'components/UI/UiDatePicker';
import { SelectOptionsType, SelectOptionType, UiSelect } from 'components/UI/UiSelect';
import { UiSelectCustomerModal } from 'components/UI/UiSelectCustomerModal';
import { UiSelectLegalPersonModal } from 'components/UI/UiSelectLegalPersonModal';
import { getManagerId } from 'store/selectors';
import { legalPersonTranslations } from 'translations/legalPerson';
import { orderTranslations } from 'translations/order';
import { getMaxDate, getMinDate, getUnixTimeGMT } from 'utils/date';
import { formatModelNameId } from 'utils/formatters';
import { UiSelectClientContractModal } from 'components/UI/UiSelectClientContractModal';
import { OrderExtendedChangeType, OrderCreateType, OrderModel, OrderUpdateType } from 'models/Order';
import { UiTabs } from 'components/UI/UiTabs';
import { SystemOrderProductTableComponent } from 'components/System/Order/table';
import { baseFinder } from 'utils/listHelpers';

type Props = {
  model: OrderModel | null;
  customerList: SelectOptionsType<number>;
  subscrProducts: ExtendedProductModel[];
  razProducts: ExtendedProductModel[];
  findLegalPersonsByCustomer: (id: number) => void;
  saveLoading: boolean;
  onSave: (m: OrderCreateType | OrderUpdateType) => void;

  legalPersonsFoundByCustomerIdLoading: boolean;
  legalPersonsFoundByCustomerId: SelectOptionsType<number>;
  legalPersonsAllListSelectOptions: SelectOptionsType<number>;

  clientContractsSelectOptionsByLegalPersonLoading: boolean;
  clientContractsSelectOptionsByLegalPerson: { label: string; value: number; rawLabel: string }[];
  findClientContractsByLegalPerson: (id: number) => void;

  servicesSelectOptions: SelectOptionsType<number>;
  servicesSelectOptionsLoading: boolean;
  findServiceByClientContracts: (id: number) => void;

  findProductsByServiceId: (id?: number | null) => void;
  productsLoading: boolean;
  managerList: SelectOptionsType<string>;
};

type FormValues = {
  startingDate: Date | null;
  completionDate: Date | null;
  customerToLegalPerson: SelectOptionType<number> | null;
  legalPersonToClientContract: SelectOptionType<number> | null;
  clientContractToService: SelectOptionType<number> | null;
  serviceId: SelectOptionType<number> | null;
  numberOfOrder: string;
  isTesting: boolean;
  manager: SelectOptionType<string> | null;
};

export const SystemOrderFormComponent: React.FC<Props> = ({
  model,
  onSave,
  customerList,
  subscrProducts,
  razProducts,
  findLegalPersonsByCustomer,
  legalPersonsFoundByCustomerIdLoading,
  legalPersonsFoundByCustomerId,
  legalPersonsAllListSelectOptions,
  clientContractsSelectOptionsByLegalPerson,
  clientContractsSelectOptionsByLegalPersonLoading,
  findClientContractsByLegalPerson,
  servicesSelectOptions,
  servicesSelectOptionsLoading,
  findServiceByClientContracts,
  saveLoading,
  findProductsByServiceId,
  productsLoading,
  managerList,
}: Props) => {
  const updateMode = !!model?.id;

  const managerId = useSelector(getManagerId);

  const initValues = useMemo(
    () => ({
      startingDate: model?.startingDate ? getUnixTimeGMT(model.startingDate) : null,
      completionDate: model?.completionDate ? getUnixTimeGMT(model.completionDate) : null,
      numberOfOrder: model?.numberOfOrder,
      isTesting: model?.isTesting || false,
      customerToLegalPerson: model?.nameCustomer ? customerList.find((v) => v.label === model.nameCustomer) : null,
      manager: model?.manager ? baseFinder(managerList, model.manager) : baseFinder(managerList, managerId),
    }),
    []
  );

  const { register, handleSubmit, errors, setValue, watch, control } = useForm<FormValues>({
    defaultValues: initValues,
  });

  const [subscrSelected, setSubscrSelected] = useState<ExtendedProductModel[]>([]);
  const [razSelected, setRazSelected] = useState<ExtendedProductModel[]>([]);

  const disabledToSave = (() => {
    if (updateMode) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { manager, startingDate, customerToLegalPerson, ...initVals } = initValues;

      const creationTariffPositionRequests = [
        ...subscrSelected.map((item) => ({
          tariffPositionId: item.tariffPositionId,
          volume: item.volume || 0,
          percentageOfAgencyRemuneration: item.percentageOfAgencyRemuneration || 0,
          discount: item.discount || 0,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          providingDate: getMinDate(item.providingDate),
        })),
        ...razSelected.map((item) => ({
          tariffPositionId: item.tariffPositionId,
          volume: item.volume || 0,
          percentageOfAgencyRemuneration: item.percentageOfAgencyRemuneration || 0,
          discount: item.discount || 0,
          providingDate: null,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          providingDate: getMinDate(item.providingDate),
        })),
      ];

      return isEqual(
        {
          ...initVals,
          manager: manager?.value,
          customerToLegalPerson: customerToLegalPerson?.value,
          startingDate: model?.startingDate || null,
          completionDate: model?.completionDate || null,
          orderAndTariffPositions: model?.orderAndTariffPositions.map(
            ({ discount, percentageOfAgencyRemuneration, providingDate, tariffPositionId, volume }) => ({
              discount,
              percentageOfAgencyRemuneration,
              providingDate,
              tariffPositionId,
              volume,
            })
          ),
        },
        {
          isTesting: watch('isTesting'),
          startingDate: getMinDate(watch('startingDate')),
          completionDate: getMinDate(watch('completionDate')),
          manager: watch('manager')?.value,
          customerToLegalPerson: watch('customerToLegalPerson')?.value,
          numberOfOrder: watch('numberOfOrder'),
          orderAndTariffPositions: creationTariffPositionRequests.map(({ providingDate, ...rest }) => ({
            ...rest,
            providingDate: providingDate || null,
          })),
        }
      );
    }
    return false;
  })();

  const rights = getOrderRights(updateMode, model?.manager);

  /** --------------------------------------- */

  const [subscrOptions, setSubscrOptions] = useState<SelectOptionsType<number>>([]);
  const [razOptions, setRazOptions] = useState<SelectOptionsType<number>>([]);

  /** при изменении зависимостей очищать текущее состояние (выбранное в таблице и селекте) */
  useEffect(() => {
    setSubscrSelected([]);
    setRazSelected([]);

    setSubscrOptions(
      subscrProducts.map(({ id, name }) => ({
        value: id,
        label: name,
      }))
    );
    setRazOptions(
      razProducts.map(({ id, name }) => ({
        value: id,
        label: name,
      }))
    );

    /**
     * редактирование. вставить услуги после загрузки + обновить селект
     * должно выполняться 1 раз в самом начале (флаг productsFirstLoading)
     *
     * возможно стоит на флаг загрузки услуг опираться, а не на их наличие
     */
    if (model?.orderAndTariffPositions && (subscrProducts.length || razProducts.length)) {
      const prepareFn = (positions: OrderModel['orderAndTariffPositions']) =>
        positions.map((m) => ({
          ...m.tariffPosition.product,
          tariffPositionId: m.tariffPositionId,
          priceWithNds: m.tariffPosition.priceWithNds,
          priceWithoutNds: m.tariffPosition.priceWithoutNds,
          volume: m.volume,
          discount: m.discount,
          percentageOfAgencyRemuneration: m.percentageOfAgencyRemuneration,
          isInvoiceCreated: m.isInvoiceCreated,
          providingDate: m.providingDate,
        }));

      const subscrSelectedInitial: ExtendedProductModel[] = prepareFn(
        model.orderAndTariffPositions.filter(
          ({
            tariffPosition: {
              product: { type },
            },
          }) => type === ProductTypesEnum.SUBSCRIPTION
        )
      );
      const subscrSelectedIds = subscrSelectedInitial.map(({ id }) => id);
      const subscrOptionsInitial = subscrProducts.map(({ id, name }) => ({
        value: id,
        label: name,
      }));

      setSubscrSelected(subscrSelectedInitial);
      setSubscrOptions(subscrOptionsInitial.filter(({ value }) => !subscrSelectedIds.includes(value)));
      /** --- */
      const razSelectedInitial: ExtendedProductModel[] = prepareFn(
        model.orderAndTariffPositions.filter(
          ({
            tariffPosition: {
              product: { type },
            },
          }) => type === ProductTypesEnum.LUMP_SUM
        )
      );
      const razSelectedIds = razSelectedInitial.map(({ id }) => id);
      const razOptionsInitial = razProducts.map(({ id, name }) => ({
        value: id,
        label: name,
      }));

      setRazSelected(razSelectedInitial);
      setRazOptions(razOptionsInitial.filter(({ value }) => !razSelectedIds.includes(value)));
    }
  }, [subscrProducts, razProducts, model?.orderAndTariffPositions]);

  /** логика переноса из селекта в таблицу */
  const onToggleSelectTable = (type: ProductTypesEnum) => (gotValue: SelectOptionType | number) => {
    const setterSelected = type === ProductTypesEnum.SUBSCRIPTION ? setSubscrSelected : setRazSelected;
    const setterOptions = type === ProductTypesEnum.SUBSCRIPTION ? setSubscrOptions : setRazOptions;
    const data = type === ProductTypesEnum.SUBSCRIPTION ? subscrProducts : razProducts;
    const currentData = type === ProductTypesEnum.SUBSCRIPTION ? subscrSelected : razSelected;

    /** удаление */
    if (typeof gotValue === 'number') {
      const found = data.find(({ id }) => id === gotValue);

      if (found) {
        setterOptions([...subscrOptions, { label: found.name, value: found.id }]);
        setterSelected(currentData.filter((o) => o.id !== found.id));
      }
    } else {
      /** добавление */
      const found = data.find(({ id }) => id === gotValue.value);

      /** закинуть в выбранные таблицы и убрать из селекта */
      if (found) {
        setterSelected([...currentData, found]);
        setterOptions(subscrOptions.filter((o) => o.value !== found.id));
      }
    }
  };

  /** изменение любых значений услуги */
  const onAllChange = (type: ProductTypesEnum) => (item: OrderExtendedChangeType) => {
    const setterSelected = type === ProductTypesEnum.SUBSCRIPTION ? setSubscrSelected : setRazSelected;
    const currentData = type === ProductTypesEnum.SUBSCRIPTION ? subscrSelected : razSelected;

    const foundIndex = currentData.findIndex((v) => v.id === item.productId);

    if (foundIndex !== -1) {
      const newData = cloneDeep(currentData);
      newData[foundIndex][item.type] = item.value;

      setterSelected(newData);
    }
  };
  /** --------------------------------------- */

  /** редактирование. вставить юр.лицо после загрузки */
  useEffect(() => {
    const found = legalPersonsFoundByCustomerId.find((v) => v.label === model?.namePerson);

    if (found) {
      setValue('legalPersonToClientContract', found, { shouldValidate: true });
    }
  }, [legalPersonsFoundByCustomerId, model?.namePerson]);

  /** редактирование. вставить клиентский договор после загрузки */
  useEffect(() => {
    const found = clientContractsSelectOptionsByLegalPerson.find((v) => v.rawLabel === model?.numberOfContract);

    if (found) {
      setValue('clientContractToService', found, { shouldValidate: true });
    }
  }, [clientContractsSelectOptionsByLegalPerson, model?.numberOfContract]);

  /** редактирование. вставить сервис после загрузки */
  useEffect(() => {
    const found = baseFinder(servicesSelectOptions, model?.serviceId);

    if (found) {
      setValue('serviceId', found, { shouldValidate: true });
    }
  }, [servicesSelectOptions, model?.serviceId]);

  const watchingFn = useCallback(
    (value: SelectOptionType<number> | null, name: string, fnToCall: (v: number) => void) => () => {
      if (value?.value) {
        setValue(name, null);
        fnToCall(value.value);
      }
    },
    [setValue]
  );

  /** отслеживать изменение заказчика (загрузить юр.лиц) */
  const customer = watch('customerToLegalPerson');
  useEffect(watchingFn(customer, 'legalPersonToClientContract', findLegalPersonsByCustomer), [customer]);

  /** отслеживать изменение юр.лица (загрузить клиентские договоры) */
  const legalPerson = watch('legalPersonToClientContract');
  useEffect(watchingFn(legalPerson, 'clientContractToService', findClientContractsByLegalPerson), [legalPerson]);

  /** отслеживать изменение клиентского контракта (загрузить сервисы) */
  const clientContractToService = watch('clientContractToService');
  useEffect(watchingFn(clientContractToService, 'serviceId', findServiceByClientContracts), [clientContractToService]);

  /** отслеживать изменение сервиса */
  const serviceId = watch('serviceId');
  useEffect(() => {
    findProductsByServiceId(serviceId?.value);
  }, [serviceId]);

  const isTesting = watch('isTesting');

  const onSubmit = (data: FormValues) => {
    if (disabledToSave) {
      return;
    }
    const creationTariffPositionRequests = [
      ...subscrSelected.map((item) => ({
        tariffPositionId: item.tariffPositionId,
        volume: item.volume || 0,
        percentageOfAgencyRemuneration: item.percentageOfAgencyRemuneration || 0,
        discount: item.discount || 0,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        providingDate: getMinDate(item.providingDate),
      })),
      ...razSelected.map((item) => ({
        tariffPositionId: item.tariffPositionId,
        volume: item.volume || 0,
        percentageOfAgencyRemuneration: item.percentageOfAgencyRemuneration || 0,
        discount: item.discount || 0,
        providingDate: null,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        providingDate: getMinDate(item.providingDate),
      })),
    ];

    /** скидка и агентское вознаграждение может быть 0. объем обязателен */
    if (
      creationTariffPositionRequests.find(
        ({ volume, discount, percentageOfAgencyRemuneration }) =>
          !volume || discount === null || percentageOfAgencyRemuneration === null
      )
    ) {
      toast.warn(orderTranslations.areEmptyRows);
    } else if (creationTariffPositionRequests.length === 0) {
      toast.warn(orderTranslations.emptyTariffPositions);
    } else {
      let dataToSave;
      if (model?.id) {
        dataToSave = {
          id: model.id,
          startingDate: getMinDate(data.startingDate),
          completionDate: getMaxDate(data.completionDate),
          numberOfOrder: data.numberOfOrder,
          updatingTariffPositions: creationTariffPositionRequests,
          manager: data.manager?.value || undefined,
        };
      } else {
        dataToSave = {
          numberOfOrder: data.numberOfOrder,
          serviceId: data.serviceId?.value || 0,
          isTesting: data.isTesting,
          creationTariffPositionRequests,
          startingDate: getMinDate(data.startingDate),
          completionDate: getMaxDate(data.completionDate),
        };
      }
      onSave(dataToSave);
    }
  };

  return (
    <CForm onSubmit={handleSubmit(onSubmit)}>
      <CCard>
        <CCardBody>
          <CRow>
            <CCol xs={9}>
              <h3>
                {model?.id ? formatModelNameId(model.numberOfContract, model.id) : orderTranslations.route.create}
              </h3>

              <UiTabs
                stayAlive
                items={{
                  index: {
                    title: orderTranslations.tab.index,
                    component: () => (
                      <>
                        <CRow>
                          <CCol xs="12" sm="6" md="4">
                            <UiDatePicker
                              control={control}
                              name="startingDate"
                              setValue={setValue}
                              value={watch('startingDate')}
                              label={orderTranslations.creationDate}
                              errors={errors}
                              disabled={rights.startingDate}
                            />
                          </CCol>
                          <CCol xs="12" sm="6" md="4">
                            <UiDatePicker
                              control={control}
                              name="completionDate"
                              setValue={setValue}
                              value={watch('completionDate')}
                              label={orderTranslations.completionDate}
                              errors={errors}
                              disabled={rights.completionDate}
                            />
                          </CCol>
                          <CCol xs="12" sm="6" md="4">
                            <UiSelectCustomerModal
                              register={register}
                              name="customerToLegalPerson"
                              options={customerList}
                              setValue={setValue}
                              value={watch('customerToLegalPerson')}
                              errors={errors}
                              disabled={rights.customerToLegalPerson}
                            />
                          </CCol>
                          <CCol xs="12" sm="6" md="4">
                            <UiSelectLegalPersonModal
                              label={orderTranslations.legalPersonToClientContract}
                              register={register}
                              name="legalPersonToClientContract"
                              options={customer ? legalPersonsFoundByCustomerId : legalPersonsAllListSelectOptions}
                              setValue={setValue}
                              value={watch('legalPersonToClientContract')}
                              errors={errors}
                              isLoading={legalPersonsFoundByCustomerIdLoading}
                              setOnSave
                              disabled={rights.legalPersonToClientContract}
                            />
                          </CCol>
                          <CCol xs="12" sm="6" md="4">
                            <UiSelectClientContractModal
                              label={orderTranslations.clientContractToService}
                              register={register}
                              name="clientContractToService"
                              options={clientContractsSelectOptionsByLegalPerson}
                              setValue={setValue}
                              value={watch('clientContractToService')}
                              errors={errors}
                              isLoading={clientContractsSelectOptionsByLegalPersonLoading}
                              setOnSave
                              disabled={rights.clientContractToService}
                            />
                          </CCol>
                          <CCol xs="12" sm="6" md="4">
                            <UiSelectServiceModal
                              label={orderTranslations.serviceId}
                              register={register}
                              name="serviceId"
                              options={servicesSelectOptions}
                              setValue={setValue}
                              value={watch('serviceId')}
                              errors={errors}
                              isLoading={servicesSelectOptionsLoading}
                              disabled={rights.serviceId}
                              setOnSave
                            />
                          </CCol>
                          <CCol xs="12" sm="6" md="4">
                            <UiInput
                              register={register}
                              name="numberOfOrder"
                              label={orderTranslations.numberOfOrder}
                              errors={errors}
                              required
                              disabled={rights.numberOfOrder}
                            />
                          </CCol>
                          <CCol xs="12" sm="6" md="4" className="d-flex align-items-end">
                            <UiRowCheckbox
                              register={register}
                              name="isTesting"
                              label={orderTranslations.isTesting}
                              disabled={rights.isTesting}
                              onChange={({ target: { checked } }) => {
                                /** отслеживать изменение тестирования для установки определенных значений */
                                if (subscrSelected) {
                                  setSubscrSelected(
                                    subscrSelected.map((m) => ({
                                      ...m,
                                      discount: checked ? 100 : null,
                                      percentageOfAgencyRemuneration: checked ? 0 : null,
                                    }))
                                  );
                                }

                                if (razSelected) {
                                  setRazSelected(
                                    razSelected.map((m) => ({
                                      ...m,
                                      discount: checked ? 100 : null,
                                      percentageOfAgencyRemuneration: checked ? 0 : null,
                                    }))
                                  );
                                }
                              }}
                            />
                          </CCol>
                          <CCol xs="12" sm="6" md="4">
                            <UiSelect
                              label={legalPersonTranslations.manager}
                              register={register}
                              name="manager"
                              options={managerList}
                              setValue={setValue}
                              value={watch('manager')}
                              errors={errors}
                              required
                              disabled={rights.manager}
                            />
                          </CCol>
                        </CRow>
                      </>
                    ),
                  },
                  abonementProducts: {
                    title: orderTranslations.tab.abonementProducts,
                    component: () => (
                      <>
                        <UiSelect
                          register={register}
                          name="abonementProducts"
                          label={orderTranslations.product}
                          errors={errors}
                          options={subscrOptions}
                          setValue={setValue}
                          value={watch('abonementProducts')}
                          onChange={onToggleSelectTable(ProductTypesEnum.SUBSCRIPTION)}
                          isLoading={productsLoading}
                          allowEmptyValue
                          disabled={rights.abonementProducts}
                        />
                        <SystemOrderProductTableComponent
                          type="abonent"
                          key={isTesting.toString()}
                          items={subscrSelected}
                          onRemove={onToggleSelectTable(ProductTypesEnum.SUBSCRIPTION)}
                          onChange={onAllChange(ProductTypesEnum.SUBSCRIPTION)}
                          isTesting={isTesting}
                          disabledRemove={rights.abonementProductsRemove}
                        />
                      </>
                    ),
                  },
                  razProducts: {
                    title: orderTranslations.tab.razProducts,
                    component: () => (
                      <>
                        <UiSelect
                          register={register}
                          name="razProducts"
                          label={orderTranslations.product}
                          errors={errors}
                          options={razOptions}
                          setValue={setValue}
                          value={watch('razProducts')}
                          onChange={onToggleSelectTable(ProductTypesEnum.LUMP_SUM)}
                          isLoading={productsLoading}
                          allowEmptyValue
                          disabled={rights.razProducts}
                        />
                        <SystemOrderProductTableComponent
                          type="raz"
                          key={isTesting.toString()}
                          items={razSelected}
                          onRemove={onToggleSelectTable(ProductTypesEnum.LUMP_SUM)}
                          onChange={onAllChange(ProductTypesEnum.LUMP_SUM)}
                          isTesting={isTesting}
                          disabledRemove={rights.razProductsRemove}
                          extended
                        />
                      </>
                    ),
                  },
                }}
              />

              <UiSubmitButton saving={saveLoading} disabled={disabledToSave} />
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CForm>
  );
};
