import React from 'react';
import { CCard, CCardBody } from '@coreui/react';
import { Link } from 'react-router-dom';

import { UiListBtnNew } from 'components/UI/UiListBtnNew';
import { SystemClientContractFilter } from 'components/System/ClientContract/filter';
import { getClientContractMethodOfInvoice } from 'components/System/ClientContract/common';
import { SelectOptionsType } from 'components/UI/UiSelect';
import { getClientContractList } from 'api/clientContract';
import { UiTable } from 'components/UI/UiTable';
import { ClientContractListModel, ClientContractModel } from 'models/ClientContract';
import { systemClientContractRoute } from 'containers/System/ClientContract/routes';
import { clientContractTranslations } from 'translations/clientContract';
import { formatDate } from 'utils/date';
import { useFilter } from 'utils/hooks/useFilter';
import { getLegalPersonById, getManagerById } from 'utils/listHelpers';

const fields = [
  { key: 'id', label: 'ID' },
  { key: 'numberOfContract', label: clientContractTranslations.numberOfContract },
  { key: 'rhsLegalPersonId', label: clientContractTranslations.rhsLegalPersonId },
  { key: 'lhsLegalPersonId', label: clientContractTranslations.lhsLegalPersonId },
  { key: 'methodOfInvoice', label: clientContractTranslations.methodOfInvoice.title },
  { key: 'startingDate', label: clientContractTranslations.startingDate },
  { key: 'completionDate', label: clientContractTranslations.completionDate },
  { key: 'agents', label: clientContractTranslations.agents },
  { key: 'manager', label: clientContractTranslations.manager },
];

const getAgents = ({ services }: ClientContractModel): string => {
  const agents = services.reduce<any>((acc, { agencyContract }) => {
    if (agencyContract) {
      acc.push(agencyContract?.agencyLegalPerson.name); // для фильтра agencyContract?.agencyLegalPerson.id
    }
    return acc;
  }, []);

  const uniqueAgents = new Set();
  agents.forEach((v: string) => uniqueAgents.add(v));

  return [...uniqueAgents].join();
};

type Props = {
  getData: typeof getClientContractList;
  legalPersonsExecutorSelectOptions: SelectOptionsType<number>;
  legalPersonsRecipientSelectOptions: SelectOptionsType<number>;
  legalPersonsAllOptions: SelectOptionsType<number>;
  managersListSelectOptions: SelectOptionsType<string>;
  loadingFilterData: boolean;
  simple: boolean;

  addInfo?: React.ReactNode | React.ReactNode[];
};

export const SystemClientContractComponent = ({
  getData,
  legalPersonsExecutorSelectOptions,
  legalPersonsRecipientSelectOptions,
  legalPersonsAllOptions,
  managersListSelectOptions,
  loadingFilterData,
  simple,
  addInfo,
}: Props): React.ReactElement => {
  const {
    onPaginationChange,
    loading,
    model,
    filter,
    onReset,
    onSearch,
    onChangePage,
  } = useFilter<ClientContractListModel>(getData);

  const component = (
    <>
      {addInfo}

      {!simple && (
        <>
          <h3 className="text-center">{clientContractTranslations.route.list}</h3>

          <UiListBtnNew title={clientContractTranslations.new} to={systemClientContractRoute()} />

          {!loadingFilterData && (
            <SystemClientContractFilter
              filter={filter}
              onSearch={onSearch}
              onReset={onReset}
              managerOptionsList={managersListSelectOptions}
              legalPersonsExecutorSelectOptions={legalPersonsExecutorSelectOptions}
              legalPersonsRecipientSelectOptions={legalPersonsRecipientSelectOptions}
              legalPersonsAllOptions={legalPersonsAllOptions}
            />
          )}
        </>
      )}

      <UiTable
        items={model}
        fields={fields}
        loading={loading}
        pageLimit={filter.pageLimit}
        onChangePage={onChangePage}
        scopedSlots={{
          id: ({ id }: ClientContractModel) => (
            <td>
              <Link to={systemClientContractRoute(id)}>{id}</Link>
            </td>
          ),
          rhsLegalPersonId: ({ rhsLegalPersonId }: ClientContractModel) => (
            <td>{getLegalPersonById(legalPersonsRecipientSelectOptions, rhsLegalPersonId)}</td>
          ),
          lhsLegalPersonId: ({ lhsLegalPersonId }: ClientContractModel) => (
            <td>{getLegalPersonById(legalPersonsExecutorSelectOptions, lhsLegalPersonId)}</td>
          ),
          methodOfInvoice: ({ methodOfInvoice }: ClientContractModel) => (
            <td>{getClientContractMethodOfInvoice(methodOfInvoice)}</td>
          ),
          startingDate: ({ startingDate }: ClientContractModel) => <td>{formatDate(startingDate)}</td>,
          completionDate: ({ completionDate }: ClientContractModel) => (
            <td>{completionDate ? formatDate(completionDate) : ''}</td>
          ),
          manager: ({ manager }: ClientContractModel) => <td>{getManagerById(managersListSelectOptions, manager)}</td>,
          agents: (clientContract: ClientContractModel) => <td>{getAgents(clientContract)}</td>,
        }}
        onPaginationChange={onPaginationChange}
      />
    </>
  );

  return simple ? (
    component
  ) : (
    <CCard>
      <CCardBody>{component}</CCardBody>
    </CCard>
  );
};
