import React from 'react';
import { CCol, CFormGroup, CLabel, CRow } from '@coreui/react';

import { UiDatePicker } from 'components/UI/UiDatePicker';

type Props = {
  setValue: any;
  control: any;
  valueMin: Date | null;
  valueMax: Date | null;
  nameMin: string;
  nameMax: string;
  label: string;
};

export const UiDateRange = ({
  setValue,
  control,
  valueMin,
  valueMax,
  nameMin,
  nameMax,
  label,
}: Props): React.ReactElement => (
  <CFormGroup>
    <CLabel htmlFor={nameMin}>{label}</CLabel>
    <CRow>
      <CCol className="input-bind">
        <UiDatePicker
          id={nameMin}
          name={nameMin}
          setValue={setValue}
          control={control}
          value={valueMin}
          selectsStart
          startDate={valueMin}
          endDate={valueMax}
        />
      </CCol>
      <CCol>
        <UiDatePicker
          name={nameMax}
          setValue={setValue}
          control={control}
          value={valueMax}
          minDate={valueMin}
          selectsEnd
          startDate={valueMin}
          endDate={valueMax}
        />
      </CCol>
    </CRow>
  </CFormGroup>
);
