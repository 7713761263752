import isFunction from 'lodash/isFunction';
import isNaN from 'lodash/isNaN';
import toNumber from 'lodash/toNumber';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { createAgencyContract, getAgencyContract, updateAgencyContract } from 'api/agencyContract';
import { SystemAgencyContractFormComponent } from 'components/System/AgencyContract/form';
import { agencyContractTranslations } from 'translations/agencyContract';
import { doRequest } from 'utils/doRequest';
import { UiLoader } from 'components/UI/UiLoader';
import { AgencyContractFormType } from 'models/AgencyContract';
import { useRequest } from 'utils/hooks/useRequest';
import { SelectOptionsType } from 'components/UI/UiSelect';
import { getAllLegalPersonsList } from 'api/legalPerson';

type Props = {
  modal?: boolean; // признак вывода в модалке
  closeModal?: () => void; // опциональное закрытие модалки (при сохранении)
  onSaved?: (item: any) => void;
};

let mounted = false;

export const SystemAgenctContractForm = ({ modal = false, closeModal, onSaved }: Props): React.ReactElement => {
  useEffect(() => {
    mounted = true;
    return () => {
      mounted = false;
    };
  }, []);

  const query = useParams<{ id?: string }>();
  const numberId = toNumber(query.id);

  const getData = useCallback(() => (isNaN(numberId) || modal ? null : getAgencyContract(numberId)), [numberId]);
  const { model: initModel, loading } = useRequest(getData, null, () => mounted);

  const [key, setKey] = useState(1);
  const [model, setModel] = useState(initModel);
  useEffect(() => {
    setModel(initModel);
  }, [initModel]);

  /** найти юр.лиц-агентов и получателей */
  const [legalPersonsAgentSelectOptions, setLegalPersonsAgentSelectOptions] = useState<SelectOptionsType<number>>([]);
  const [legalPersonsAgentLoading, setLegalPersonsAgentLoading] = useState(true);

  const [legalPersonsRecipientSelectOptions, setLegalPersonsRecipientSelectOptions] = useState<
    SelectOptionsType<number>
  >([]);
  const [legalPersonsRecipientLoading, setLegalPersonsRecipientLoading] = useState(true);

  useEffect(() => {
    doRequest(
      () => Promise.all([getAllLegalPersonsList({ isAgent: true }), getAllLegalPersonsList({ isRecipient: true })]),
      () => mounted
    )(
      ([agents, recipients]) => {
        setLegalPersonsAgentSelectOptions(agents.data.map(({ id, name }) => ({ value: id, label: name })));
        setLegalPersonsRecipientSelectOptions(recipients.data.map(({ id, name }) => ({ value: id, label: name })));
      },
      () => {
        setLegalPersonsAgentLoading(false);
        setLegalPersonsRecipientLoading(false);
      }
    );
  }, []);

  const [saveLoading, setSaveLoading] = useState(false);
  const onSave = useCallback((data: AgencyContractFormType) => {
    setSaveLoading(true);
    const updateMode = data.id;

    const apiFn = updateMode ? () => updateAgencyContract(data.id as number, data) : () => createAgencyContract(data);

    doRequest(apiFn, () => mounted)(
      (savedItem) => {
        if (modal) {
          if (isFunction(closeModal)) {
            closeModal();
          }

          if (isFunction(onSaved)) {
            onSaved(savedItem);
          }
        }

        getAgencyContract(savedItem.id).then((response) => {
          setModel(response);
          setKey((k) => k + 1);
        });

        toast.success(updateMode ? agencyContractTranslations.updated : agencyContractTranslations.created);
      },
      () => {
        setSaveLoading(false);
      }
    );
  }, []);

  return legalPersonsAgentLoading || legalPersonsRecipientLoading || loading ? (
    <UiLoader />
  ) : (
    <SystemAgencyContractFormComponent
      key={key}
      model={model}
      saveLoading={saveLoading}
      legalPersonsAgentSelectOptions={legalPersonsAgentSelectOptions}
      legalPersonsRecipientSelectOptions={legalPersonsRecipientSelectOptions}
      onSave={onSave}
      modal={modal}
    />
  );
};
