import { applyMiddleware, createStore, Store } from 'redux';
import createSagaMiddleware from 'redux-saga';
// eslint-disable-next-line import/no-extraneous-dependencies
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { reducers } from 'store/reducers';
import { rootSaga } from 'store/rootSaga';

export const configureStore = (): Store => {
  const sagaMiddleware = createSagaMiddleware({
    onError(err) {
      // eslint-disable-next-line no-console
      console.error(err);
    },
  });

  const composeEnhancers = (...args: any[]): any => {
    const compose = composeWithDevTools({
      name: 'cortel-admin-devtools',
      trace: true,
    });

    return compose(...args);
  };
  const enhancer = composeEnhancers(applyMiddleware(sagaMiddleware));

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const store = createStore(reducers, enhancer);

  sagaMiddleware.run(rootSaga);

  return store;
};
