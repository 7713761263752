import isNaN from 'lodash/isNaN';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import toNumber from 'lodash/toNumber';

import { InvoiceForAgencyType } from 'models/InvoiceForAgency';
import { doRequest } from 'utils/doRequest';
import { getInvoiceForAgency } from 'api/invoiceForAgency';
import { UiLoader } from 'components/UI/UiLoader';
import { SystemInvoiceForAgencyFormComponent } from 'components/System/InvoceForAgency/form';

let mounted = false;

export const SystemInvoiceForAgencyForm: React.FC = () => {
  useEffect(() => {
    mounted = true;
    return () => {
      mounted = false;
    };
  }, []);

  const [model, setModel] = useState<InvoiceForAgencyType | null>(null);
  const [loading, setLoading] = useState(true);

  const { id } = useParams<{ id?: string }>();
  const numberId = toNumber(id);

  useEffect(() => {
    if (!isNaN(numberId)) {
      doRequest(
        () => getInvoiceForAgency(numberId),
        () => mounted
      )(
        (response) => {
          setModel(response);
        },
        () => {
          setLoading(false);
        }
      );
    }
  }, []);

  return loading ? <UiLoader /> : <SystemInvoiceForAgencyFormComponent model={model} />;
};
