import { CButton, CFormGroup, CLabel } from '@coreui/react';
import React, { CSSProperties, useEffect } from 'react';
import { RegisterOptions } from 'react-hook-form';
import Select, { ValueType } from 'react-select';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';

export type SelectOptionType<T = any> = {
  label: string;
  value: T;
};

export type SelectOptionsType<T = number | string> = SelectOptionType<T>[];

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
const groupBadgeStyles: CSSProperties = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};
const formatGroupLabel = (data: { label: string; options: any[] }) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

type Props = {
  register: any;
  name: string;
  options: SelectOptionType[] | { label: string; options: SelectOptionType[] }[];
  setValue: any;

  value?: SelectOptionType | SelectOptionType[] | null;
  errors?: { [s: string]: unknown }; // объект с ошибками
  multiple?: boolean;
  className?: string;
  required?: boolean;
  allowEmptyValue?: boolean; // разрешать ли пустые значения []
  isClearable?: boolean;
  placeholder?: string;
  appendFn?: () => void;
  isLoading?: boolean;
  onChange?: (v: SelectOptionType) => void;
  disabled?: boolean;
  closeMenuOnSelect?: boolean;
};

const UiSelectComponent = ({
  register,
  name,
  errors,
  options,
  placeholder = 'Выберите значение',
  setValue,
  multiple = false,
  className = '',
  required = false,
  allowEmptyValue = false,
  value,
  isClearable = false,
  appendFn,
  isLoading = false,
  onChange,
  disabled = false,
  closeMenuOnSelect = true,
}: Props): React.ReactElement => {
  const error = get(errors, name, false);

  /** определение/сбор класса */
  const formgroupClassName = [];
  if (className) {
    formgroupClassName.push(className);
  }
  if (error !== false) {
    formgroupClassName.push('has-danger');
  }
  if (required) {
    formgroupClassName.push('required');
  }

  /** валидация из простых параметров */
  const localValidation: RegisterOptions = {};
  if (required) {
    localValidation.required = true;
  }
  if (!allowEmptyValue) {
    localValidation.validate = (v: SelectOptionType | SelectOptionType[]) => {
      if (Array.isArray(v)) {
        return v.length > 0 ? true : 'Обязательное поле';
      }

      return v && v.value ? true : 'Обязательное поле';
    };
  }

  useEffect(() => {
    register({ name }, localValidation);
    if (value) {
      setValue(name, value);
    }
    // localValidation динамична, поэтому будет всегда новым объектом
    // eslint-disable-next-line
  }, [register, name]);

  const component = (
    <Select
      className={`form-select-control${error ? ' border-danger' : ''} flex-1 ${multiple ? '' : 'single'}`}
      classNamePrefix="react-select"
      id={name}
      isMulti={multiple}
      closeMenuOnSelect={closeMenuOnSelect}
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      // @ts-ignore
      onChange={(optionValue: ValueType<SelectOptionType, false>) => {
        if (isFunction(onChange)) {
          onChange(optionValue as SelectOptionType);
        } else {
          setValue(name, optionValue ? (optionValue as SelectOptionType) : null, { shouldValidate: true });
        }
      }}
      value={value || null}
      placeholder={placeholder}
      options={options}
      /** noOptionsMessage должен быть функцией */
      noOptionsMessage={() => 'Нет значений для выбора'}
      formatGroupLabel={formatGroupLabel}
      isClearable={isClearable}
      isLoading={isLoading}
      loadingMessage={() => 'Данные загружаются'}
      isDisabled={disabled}
    />
  );

  return appendFn ? (
    <div className="input-group">
      {component}
      <div className="input-group-append">
        <CButton type="button" block color="secondary" onClick={appendFn} disabled={disabled}>
          +
        </CButton>
      </div>
    </div>
  ) : (
    component
  );
};

export const UiSelect = ({ label, ...props }: Props & { label: string }): React.ReactElement => (
  <CFormGroup>
    <CLabel>{label}</CLabel>
    <UiSelectComponent {...props} />
  </CFormGroup>
);
