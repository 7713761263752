import { SelectOptionsType, SelectOptionType } from 'components/UI/UiSelect';

export const baseFinder = <T>(list: SelectOptionsType<T>, value: T): SelectOptionType<T> | null =>
  list.find((m) => m.value === value) || null;

export const baseFinderLabel = <T>(notFoundText: string) => (list: SelectOptionsType<T>, id: T): string => {
  const found = baseFinder(list, id);
  return found ? found.label : notFoundText;
};

/**
 * инфа о заказчике по ид
 */
export const getCustomerById = baseFinderLabel<number>('Заказчик не найден');

/**
 * инфа о менеджере по ид
 */
export const getManagerById = baseFinderLabel<string>('Аккаунт-менеджер не найден');

/**
 * инфа о юр.лице по ид
 */
export const getLegalPersonById = baseFinderLabel<number>('Юридическое лицо не найдено');
