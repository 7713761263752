import { prepareFormatValueToNumber, UiFormatNumber } from 'components/UI/UiFormatNumber';
import isEqual from 'lodash/isEqual';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { CCard, CCardBody, CCol, CForm, CRow } from '@coreui/react';

import { UiInput } from 'components/UI/UiInput';
import { getProductRights } from 'components/System/Product/formHelpers';
import { SelectOptionType, UiSelect } from 'components/UI/UiSelect';
import { UiSubmitButton } from 'components/UI/UiSubmitButton';
import {
  ProductCreateType,
  ProductModel,
  ProductTypesEnum,
  productTypesSelectOptions,
  ProductUpdateType,
} from 'models/Product';
import { productTranslations } from 'translations/product';
import { formatModelNameId } from 'utils/formatters';
import { prepareFormData } from 'utils/formFilter';
import { baseFinder } from 'utils/listHelpers';

type Props = {
  model: ProductModel | null;
  saveLoading: boolean;
  onSave: (data: ProductCreateType | ProductUpdateType, id: number | undefined) => void;
  modal: boolean;
};

type FormValues = {
  name: string;
  type: SelectOptionType<ProductTypesEnum> | null;
  costPrice: string | number;
  unitOfMeasure: string;
};

export const SystemProductFormComponent = ({ model, saveLoading, onSave, modal }: Props): React.ReactElement => {
  const updateMode = !!model?.id;

  const initValues = useMemo(
    () => ({
      name: model?.name,
      costPrice: model?.costPrice,
      unitOfMeasure: model?.unitOfMeasure,
      type: baseFinder(productTypesSelectOptions, model?.type),
    }),
    []
  );

  const { register, handleSubmit, errors, setValue, watch, control } = useForm<FormValues>({
    defaultValues: initValues,
  });

  const disabledToSave = (() => {
    if (updateMode) {
      const { type, ...initVals } = initValues;

      return isEqual(
        {
          ...initVals,
          type: type?.value,
        },
        {
          name: watch('name'),
          costPrice: watch('costPrice'),
          unitOfMeasure: watch('unitOfMeasure'),
          type: watch('type')?.value,
        }
      );
    }
    return false;
  })();

  const rights = getProductRights(updateMode);

  const onSubmit = (data: FormValues) => {
    if (!disabledToSave) {
      const saveModel: ProductCreateType | ProductUpdateType = updateMode
        ? {
            name: data.name,
          }
        : {
            name: data.name,
            type: data.type?.value || ProductTypesEnum.SUBSCRIPTION,
            costPrice: prepareFormatValueToNumber(data.costPrice),
            unitOfMeasure: data.unitOfMeasure,
          };

      onSave(prepareFormData(saveModel), model?.id);
    }
  };

  const component = (
    <>
      <UiInput
        register={register}
        name="name"
        label={productTranslations.name}
        errors={errors}
        required
        disabled={rights.name}
      />
      <UiSelect
        register={register}
        name="type"
        label={productTranslations.type}
        errors={errors}
        options={productTypesSelectOptions}
        setValue={setValue}
        value={watch('type')}
        required
        disabled={rights.type}
      />
      <UiFormatNumber
        name="costPrice"
        label={productTranslations.costPrice}
        errors={errors}
        required
        disabled={rights.costPrice}
        control={control}
        watch={watch}
      />
      <UiInput
        register={register}
        name="unitOfMeasure"
        label={productTranslations.unitOfMeasure}
        errors={errors}
        required
        disabled={rights.unitOfMeasure}
      />

      <UiSubmitButton saving={saveLoading} disabled={disabledToSave} />
    </>
  );

  return (
    <CForm onSubmit={handleSubmit(onSubmit)}>
      {modal ? (
        component
      ) : (
        <CCard>
          <CCardBody>
            <h3>{model?.id ? formatModelNameId(model.name, model.id) : productTranslations.route.create}</h3>

            <CRow>
              <CCol xl={6} lg={8} sm={9}>
                {component}
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      )}
    </CForm>
  );
};
