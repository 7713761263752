import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { CCol, CForm, CRow } from '@coreui/react';

import { SelectOptionsType, SelectOptionType, UiSelect } from 'components/UI/UiSelect';
import { UiInput } from 'components/UI/UiInput';
import { UiToolbar } from 'components/UI/UiToolbar';
import { customerTranslations } from 'translations/customer';
import { prepareFormData, prepareIds, setValueFilter } from 'utils/formFilter';
import { Optional } from 'utils/types';
import { UiRowCheckbox } from 'components/UI/UiCheckbox';
import { CustomersFilterType } from 'models/Customer';

type Props = {
  onSearch: (f: CustomersFilterType) => void;
  onReset: () => void;
  filter: any;
  managerList: SelectOptionsType<string>;
};

type FormValues = Optional<{
  id: number;
  name: string;
  isPartner: boolean;
  manager: SelectOptionType<string>;
}>;

export const SystemCustomerFilter = ({ onSearch, onReset, filter, managerList }: Props): React.ReactElement => {
  const { register, handleSubmit, reset, setValue, watch } = useForm<FormValues>();

  const onLocalReset = () => {
    reset();
    onReset();
  };

  const onSearchLocal = (data: FormValues) =>
    onSearch(
      prepareFormData({
        ids: prepareIds(data.id),
        name: data.name?.length ? data.name : undefined,
        isPartner: data.isPartner ? true : undefined,
        managers: data.manager ? [data.manager.value] : undefined,
      })
    );

  useEffect(() => {
    setValueFilter(filter, setValue, 'name');
    setValueFilter(filter, setValue, 'isPartner');

    if (filter.ids) {
      setValue('id', filter.ids[0]);
    }
  }, [filter, setValue]);

  return (
    <CForm onSubmit={handleSubmit(onSearchLocal)} onReset={onLocalReset}>
      <UiToolbar open={Object.values(watch()).some((f) => f)}>
        <CRow>
          <CCol xs="12" sm="6" md="4">
            <UiInput register={register} name="id" label="ID" />
          </CCol>
          <CCol xs="12" sm="6" md="4">
            <UiInput register={register} name="name" label={customerTranslations.name} />
          </CCol>
          <CCol xs="12" sm="6" md="4">
            <UiRowCheckbox
              register={register}
              name="isPartner"
              label={customerTranslations.partner}
              horizontalCentered
            />
          </CCol>
          <CCol xs="12" sm="6" md="4">
            <UiSelect
              label={customerTranslations.manager}
              name="manager"
              options={managerList}
              register={register}
              setValue={setValue}
              isClearable
              allowEmptyValue
              value={watch('manager')}
            />
          </CCol>
        </CRow>
      </UiToolbar>
    </CForm>
  );
};
