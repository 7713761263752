import { CPagination } from '@coreui/react';
import React from 'react';

type Props = {
  totalPages: number;
  activePage: number;
  setPage: (p: number) => void;
};

export const UiPagination = ({ totalPages, activePage, setPage }: Props): React.ReactElement | null =>
  totalPages < 2 ? null : (
    <CPagination
      activePage={activePage}
      pages={totalPages}
      onActivePageChange={(page: number) => {
        if (page > 0) {
          setPage(page);
        }
      }}
      align="center"
    />
  );
