export const agencyContractTranslations = {
  route: {
    list: 'Агентские договоры',
    create: 'Агентский договор (создание)',
    update: 'Агентский договор (редактирование)',
  },
  new: 'Новый агентский договор',
  numberOfContract: 'Номер',
  customer: 'Заказчик',
  agencyLegalPersonId: 'Юр. лицо (агент)',
  invitedLegalPersonId: 'Юр. лицо (приглашенное агентом)',
  manager: 'Аккаунт-менеджер',
  created: 'Агентский договор успешно создан',
  updated: 'Агентский договор успешно обновлен',
  creationDate: 'Дата создания',
  signingDate: 'Дата подписания договора с агентом',
};
