import { maxValueToGetAllDataWithPagination } from 'config';
import { TariffFilterType, TariffListModel, TariffModel, TariffCreateType, TariffUpdateType } from 'models/Tariff';
import { httpGet, httpPost, httpPut } from 'utils/http';

const path = '/admin/tariffs';

/** создать тариф */
export const createTariff = (model: TariffCreateType): Promise<TariffModel> => httpPost(`${path}/tariff`, model);

/** обновить тариф */
export const updateTariff = (id: number, model: TariffUpdateType): Promise<TariffModel> =>
  httpPut(`${path}/tariff/${id}`, model);

/** получить список тарифов */
export const getTariffList = (filter?: TariffFilterType): Promise<TariffListModel> => httpGet(`${path}/filter`, filter);

/** получить тариф */
export const getTariff = (id: number): Promise<TariffModel> => httpGet(`${path}/tariff/${id}`);

/** получить все тарифы */
export const getAllTariffs = (filter?: TariffFilterType): Promise<TariffListModel> =>
  getTariffList({ ...filter, pageLimit: maxValueToGetAllDataWithPagination });

/** получить НДС */
export const getAPINds = (): Promise<number> =>
  httpGet<{ number: number }>(`${path}/nds-coefficient`).then(({ number }) => number);
