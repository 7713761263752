import React from 'react';
import { useForm } from 'react-hook-form';
import { CCol, CForm, CRow } from '@coreui/react';

import { UiInput } from 'components/UI/UiInput';
import { UiToolbar } from 'components/UI/UiToolbar';
import { invoiceForAgencyTranslations } from 'translations/InvoiceForAgency';
import { InvoiceForAgencyFilterType } from 'models/InvoiceForAgency';
import { getMaxDate, getMinDate } from 'utils/date';
import { prepareFormData, prepareIds } from 'utils/formFilter';
import { Optional } from 'utils/types';
import { UiDateRange } from 'components/UI/UiDateRange';
import { SelectOptionsType, SelectOptionType, UiSelect } from 'components/UI/UiSelect';

type Props = {
  onSearch: (f: InvoiceForAgencyFilterType) => void;
  onReset: () => void;
  legalPersons: SelectOptionsType<number>;
  agencyContracts: SelectOptionsType<number>;
};

type FormValues = Optional<{
  id: number;
  agencyLegalPersonIds: SelectOptionType<number>;
  invitedLegalPersonIds: SelectOptionType<number>;
  agencyContractIds: SelectOptionType<number>;
}> & {
  minCreationDate: Date | null;
  maxCreationDate: Date | null;
  minBeginOfInvoicePeriodDate: Date | null;
  maxFinishOfInvoicePeriodDate: Date | null;
};

export const SystemInvoiceForAgencyFilter = ({
  onSearch,
  onReset,
  legalPersons,
  agencyContracts,
}: Props): React.ReactElement => {
  const { register, handleSubmit, reset, setValue, watch, control } = useForm<FormValues>({
    defaultValues: {
      minCreationDate: null,
      maxCreationDate: null,
      minBeginOfInvoicePeriodDate: null,
      maxFinishOfInvoicePeriodDate: null,
    },
  });

  const onLocalReset = () => {
    reset();
    onReset();
  };

  const onSearchLocal = (data: FormValues) =>
    onSearch(
      prepareFormData({
        ids: prepareIds(data.id),
        minCreationDate: getMinDate(data.minCreationDate),
        maxCreationDate: getMaxDate(data.maxCreationDate),
        minBeginOfInvoicePeriodDate: getMinDate(data.minBeginOfInvoicePeriodDate),
        maxFinishOfInvoicePeriodDate: getMaxDate(data.maxFinishOfInvoicePeriodDate),
        agencyLegalPersonIds: data.agencyLegalPersonIds ? [data.agencyLegalPersonIds.value] : undefined,
        invitedLegalPersonIds: data.invitedLegalPersonIds ? [data.invitedLegalPersonIds.value] : undefined,
        agencyContractIds: data.agencyContractIds ? [data.agencyContractIds.value] : undefined,
      })
    );

  return (
    <CForm onSubmit={handleSubmit(onSearchLocal)} onReset={onLocalReset}>
      <UiToolbar open={Object.values(watch()).some((f) => f)}>
        <CRow>
          <CCol xs="12" sm="6" md="4">
            <UiInput register={register} name="id" label="ID" />
          </CCol>
          <CCol xs="12" sm="6" md="4">
            <UiDateRange
              control={control}
              setValue={setValue}
              label={invoiceForAgencyTranslations.creationDate}
              nameMax="maxCreationDate"
              nameMin="minCreationDate"
              valueMax={watch('maxCreationDate')}
              valueMin={watch('minCreationDate')}
            />
          </CCol>
          <CCol xs="12" sm="6" md="4">
            <UiDateRange
              control={control}
              setValue={setValue}
              label={invoiceForAgencyTranslations.invoicePeriod}
              nameMax="maxFinishOfInvoicePeriodDate"
              nameMin="minBeginOfInvoicePeriodDate"
              valueMax={watch('maxFinishOfInvoicePeriodDate')}
              valueMin={watch('minBeginOfInvoicePeriodDate')}
            />
          </CCol>
          <CCol xs="12" sm="6" md="4">
            <UiSelect
              label={invoiceForAgencyTranslations.agent}
              register={register}
              name="agencyLegalPersonIds"
              options={legalPersons}
              setValue={setValue}
              value={watch('agencyLegalPersonIds')}
              allowEmptyValue
              isClearable
            />
          </CCol>
          <CCol xs="12" sm="6" md="4">
            <UiSelect
              label={invoiceForAgencyTranslations.agencyContract}
              register={register}
              name="agencyContractIds"
              options={agencyContracts}
              setValue={setValue}
              value={watch('agencyContractIds')}
              allowEmptyValue
              isClearable
            />
          </CCol>
          <CCol xs="12" sm="6" md="4">
            <UiSelect
              label={invoiceForAgencyTranslations.invitedLegalPersonIds}
              register={register}
              name="invitedLegalPersonIds"
              options={legalPersons}
              setValue={setValue}
              value={watch('invitedLegalPersonIds')}
              allowEmptyValue
              isClearable
            />
          </CCol>
        </CRow>
      </UiToolbar>
    </CForm>
  );
};
