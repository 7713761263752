import { MyProfileType } from 'models/Auth';
import { ClientContractFilterType, ClientContractListModel, ClientContractModel } from 'models/ClientContract';
import { CustomerListModel, CustomerManagersListModel, CustomerModel } from 'models/Customer';
import { LegalPersonFilterType, LegalPersonListModel, LegalPersonModel } from 'models/LegalPerson';
import { ServiceListFilterType, ServiceListModel, ServiceModel } from 'models/Service';
import { TariffFilterType, TariffListModel, TariffModel } from 'models/Tariff';
import {
  ADD_CLIENT_CONTRACT,
  ADD_CUSTOMER,
  ADD_LEGAL_PERSON,
  ADD_LEGAL_PERSON2,
  ADD_SERVICE,
  ADD_TARIFF,
  CHANGE_SIDEBAR_SHOW,
  GET_CLIENT_CONTRACTS_OK,
  GET_CLIENT_CONTRACTS_START,
  GET_CUSTOMERS_OK,
  GET_CUSTOMERS_START,
  GET_LEGAL_PERSONS2_OK,
  GET_LEGAL_PERSONS2_START,
  GET_LEGAL_PERSONS_OK,
  GET_LEGAL_PERSONS_START,
  GET_MANAGERS_OK,
  GET_MANAGERS_START,
  GET_PROFILE_OK,
  GET_PROFILE_START,
  GET_SERVICES_OK,
  GET_SERVICES_START,
  GET_TARIFFS_OK,
  GET_TARIFFS_START,
} from 'store/constants';
import { BaseActionType } from 'store/types';

/** профиль */
export const getProfileStartAction = (): BaseActionType => ({
  type: GET_PROFILE_START,
});

export const getProfileOkAction = (data: MyProfileType): BaseActionType => ({
  type: GET_PROFILE_OK,
  payload: {
    data,
  },
});

/** менеджеры */
export const getAllManagersStartAction = (): BaseActionType => ({
  type: GET_MANAGERS_START,
});

export const getAllManagersOkAction = (data: CustomerManagersListModel): BaseActionType => ({
  type: GET_MANAGERS_OK,
  payload: {
    data,
  },
});

/** заказчики */
export const getAllCustomersStartAction = (): BaseActionType => ({
  type: GET_CUSTOMERS_START,
});

export const getAllCustomersOkAction = (data: CustomerListModel): BaseActionType => ({
  type: GET_CUSTOMERS_OK,
  payload: {
    data,
  },
});

export const addCustomersToStoreAction = (data: CustomerModel): BaseActionType => ({
  type: ADD_CUSTOMER,
  payload: {
    data,
  },
});

/** юр.лица */
export const getAllLegalPersonsStartAction = (filter?: LegalPersonFilterType): BaseActionType => ({
  type: GET_LEGAL_PERSONS_START,
  payload: {
    filter,
  },
});

export const getAllLegalPersonsOkAction = (data: LegalPersonListModel): BaseActionType => ({
  type: GET_LEGAL_PERSONS_OK,
  payload: {
    data,
  },
});

export const addLegalPersonsAction = (data: LegalPersonModel): BaseActionType => ({
  type: ADD_LEGAL_PERSON,
  payload: {
    data,
  },
});

/** юр.лица 2 */
export const getAllLegalPersons2StartAction = (filter?: LegalPersonFilterType): BaseActionType => ({
  type: GET_LEGAL_PERSONS2_START,
  payload: {
    filter,
  },
});

export const getAllLegalPersons2OkAction = (data: LegalPersonListModel): BaseActionType => ({
  type: GET_LEGAL_PERSONS2_OK,
  payload: {
    data,
  },
});

export const addLegalPersons2Action = (data: LegalPersonModel): BaseActionType => ({
  type: ADD_LEGAL_PERSON2,
  payload: {
    data,
  },
});

/** тарифы */
export const getAllTariffsStartAction = (filter?: TariffFilterType): BaseActionType => ({
  type: GET_TARIFFS_START,
  payload: {
    filter,
  },
});

export const getAllTariffsOkAction = (data: TariffListModel): BaseActionType => ({
  type: GET_TARIFFS_OK,
  payload: {
    data,
  },
});

export const addTariffAction = (data: TariffModel): BaseActionType => ({
  type: ADD_TARIFF,
  payload: {
    data,
  },
});

/** сервисы */
export const getAllServicesStartAction = (filter?: ServiceListFilterType): BaseActionType => ({
  type: GET_SERVICES_START,
  payload: {
    filter,
  },
});

export const getAllServicesOkAction = (data: ServiceListModel): BaseActionType => ({
  type: GET_SERVICES_OK,
  payload: {
    data,
  },
});

export const addServiceAction = (data: ServiceModel): BaseActionType => ({
  type: ADD_SERVICE,
  payload: {
    data,
  },
});

/** клиентские договоры */
export const getAllClientContractsStartAction = (filter?: ClientContractFilterType): BaseActionType => ({
  type: GET_CLIENT_CONTRACTS_START,
  payload: {
    filter,
  },
});

export const getAllClientContractsOkAction = (data: ClientContractListModel): BaseActionType => ({
  type: GET_CLIENT_CONTRACTS_OK,
  payload: {
    data,
  },
});

export const addClientContractAction = (data: ClientContractModel): BaseActionType => ({
  type: ADD_CLIENT_CONTRACT,
  payload: {
    data,
  },
});

/** настройки */
export const changeSettingsSidebarShowAction = (show: boolean): BaseActionType => ({
  type: CHANGE_SIDEBAR_SHOW,
  payload: {
    show,
  },
});
