export const legalPersonTranslations = {
  new: 'Новое юридическое лицо',
  name: 'Имя',
  inn: 'ИНН',
  kpp: 'КПП',
  customer: 'Заказчик',
  manager: 'Аккаунт-менеджер',
  role: {
    title: 'Роль',
    isRecipient: 'Получатель',
    isExecutor: 'Исполнитель',
    isAgent: 'Агент',
    isNDSPayer: 'Плательщик НДС',
  },
  route: {
    list: 'Юридические лица',
    create: 'Юридические лица (создание)',
    update: 'Юридические лица (редактирование)',
  },
  created: 'Юридическое лицо успешно создано',
  updated: 'Юридическое лицо успешно обновлено',
  rolesRequired: 'Должна быть выбрана минимум одна роль',
  notUniqueKppInn: 'Пара КПП и ИНН должна быть уникальна',
  organizationType: 'Тип организации',
  tab: {
    index: 'Основное',
    clientContractsWherePersonRhs: 'Клиентские договоры на предоставление услуг',
    clientContractsWherePersonLhs: 'Клиентские договоры на получение услуг',
  },
  createCustomer: 'Название ЮЛ совпадает с Заказчиком',
};
