import { store } from 'App';
import { RootReducer } from 'store/reducers';

// eslint-disable-next-line no-shadow
export enum RoleEnum {
  ADMIN = 'ADMIN', // суперадмин
  CHIEF_MANAGER = 'CHIEF_MANAGER', // главный менеджер
  MANAGER = 'MANAGER', // менеджер
}

export type MyProfileType = {
  account: {
    environment: string;
    homeAccountId: string;
    idTokenClaims: {
      aud: string;
      exp: number;
      iat: number;
      iss: string;
      name: string;
      nbf: number;
      oid: string;
      preferred_username: string;
      rh: string;
      sub: string;
      tid: string;
      uti: string;
      ver: string;
    };
    localAccountId: string;
    name: string;
    tenantId: string;
    username: string;
  };
  roles: RoleEnum;
};

export type getCurrentUserRoleType = {
  isAdmin: boolean;
  isChiefManager: boolean;
  isManager: boolean;
};
/** получить роль(первую и единственную?) пользователя */
export const getCurrentUserRole = (): getCurrentUserRoleType => {
  const {
    global: { profile },
  }: RootReducer = store.getState();

  const role = profile?.roles[0];

  return {
    isAdmin: role === RoleEnum.ADMIN,
    isChiefManager: role === RoleEnum.CHIEF_MANAGER,
    isManager: role === RoleEnum.MANAGER,
  };
};

/** является ли пользователь владельцем сущности */
export const getIsOwner = (entityManagerId = ''): boolean => {
  const {
    global: { profile },
  }: RootReducer = store.getState();
  const id = profile?.account?.localAccountId || null;
  return id === entityManagerId;
};
