import { CButton, CDataTable, CInput } from '@coreui/react';
import React from 'react';

import { productTranslations } from 'translations/product';
import { ExtendedProductModel } from 'models/Product';
import { tariffTranslations } from 'translations/tariff';
import { OrderExtendedChangeType } from 'models/Order';
import { ClearDatePicker } from 'components/UI/UiDatePicker';
import { getUnixTimeGMT } from 'utils/date';
import { formatCurrency } from 'utils/formatters';

const inputProps = {
  type: 'number',
  min: 0,
  maxLength: 10,
  step: 1,
};

type Props = {
  items: ExtendedProductModel[];
  onRemove: (id: number) => void;
  onChange: (m: OrderExtendedChangeType) => void;
  isTesting: boolean;
  disabledRemove?: boolean;
  extended?: boolean;
  type: 'abonent' | 'raz';
};

export const SystemOrderProductTableComponent: React.FC<Props> = ({
  items,
  onRemove,
  onChange,
  isTesting,
  disabledRemove = false,
  extended = false,
  type: innerType,
}: Props) => {
  const onInputChange = (
    id: number,
    type: 'volume' | 'discount' | 'percentageOfAgencyRemuneration' | 'providingDate'
  ) => ({ target: { value } }: any) => {
    onChange({
      productId: id,
      type,
      value: +value,
    });
  };

  const fields = [
    { key: 'id', label: 'ID' },
    { key: 'name', label: productTranslations.name },
    { key: 'volume', label: productTranslations.volume },
    { key: 'unitOfMeasure', label: productTranslations.unitOfMeasure },
    { key: 'priceWithoutNds', label: tariffTranslations.priceWithoutNds },
    { key: 'priceWithNds', label: tariffTranslations.priceWithNds },
    { key: 'discount', label: productTranslations.discount },
    { key: 'percentageOfAgencyRemuneration', label: productTranslations.percentageOfAgencyRemuneration },
    extended && { key: 'providingDate', label: productTranslations.providingDate },
    extended && { key: 'isInvoiceCreated', label: productTranslations.isInvoiceCreated },
    { key: 'actions', label: '' },
  ].filter((v) => v);

  return (
    <>
      {items.length ? (
        <CDataTable
          items={items}
          fields={fields}
          responsive
          striped
          border
          scopedSlots={{
            priceWithoutNds: ({ priceWithoutNds }: ExtendedProductModel) => <td>{formatCurrency(priceWithoutNds)}</td>,
            priceWithNds: ({ priceWithNds }: ExtendedProductModel) => <td>{formatCurrency(priceWithNds)}</td>,
            volume: ({ id }: ExtendedProductModel) => (
              <td>
                <CInput
                  key={id}
                  onBlur={onInputChange(id, 'volume')}
                  defaultValue={items.find((item) => item.id === id)?.volume ?? undefined}
                  {...inputProps}
                />
              </td>
            ),
            discount: ({ id }: ExtendedProductModel) => (
              <td>
                <CInput
                  {...inputProps}
                  key={id}
                  onBlur={onInputChange(id, 'discount')}
                  defaultValue={items.find((item) => item.id === id)?.discount ?? undefined}
                  disabled={isTesting}
                />
              </td>
            ),
            percentageOfAgencyRemuneration: ({ id }: ExtendedProductModel) => (
              <td>
                <CInput
                  {...inputProps}
                  key={id}
                  onBlur={onInputChange(id, 'percentageOfAgencyRemuneration')}
                  defaultValue={items.find((item) => item.id === id)?.percentageOfAgencyRemuneration ?? undefined}
                  disabled={isTesting}
                />
              </td>
            ),
            actions: ({ id }: ExtendedProductModel) => (
              <td>
                <CButton
                  block
                  color="danger"
                  onClick={() => {
                    onRemove(id);
                  }}
                  disabled={disabledRemove}
                >
                  X
                </CButton>
              </td>
            ),
            providingDate: ({ id, providingDate }: ExtendedProductModel) => (
              <td>
                <ClearDatePicker
                  onChange={(d: Date) => {
                    onInputChange(id, 'providingDate')({ target: { value: d } });
                  }}
                  value={providingDate ? getUnixTimeGMT(providingDate) : null}
                />
              </td>
            ),
            isInvoiceCreated: ({ isInvoiceCreated }: ExtendedProductModel) => (
              // eslint-disable-next-line no-nested-ternary
              <td>{isInvoiceCreated === null ? '' : isInvoiceCreated ? 'Да' : 'Нет'}</td>
            ),
          }}
        />
      ) : null}

      {items.length > 0 && (
        <>
          <h4>
            Общая сумма {innerType === 'abonent' ? 'абонементных' : 'единовременных'} услуг без НДС:&nbsp;
            {formatCurrency(
              items.reduce(
                (acc, { volume, priceWithoutNds, discount }) =>
                  acc + (volume || 0) * priceWithoutNds * (1 - (discount || 0) / 100),
                0
              )
            )}
          </h4>
          <h4>
            Общая сумма {innerType === 'abonent' ? 'абонементных' : 'единовременных'} услуг с НДС:&nbsp;
            {formatCurrency(
              items.reduce(
                (acc, { volume, priceWithNds, discount }) =>
                  acc + (volume || 0) * priceWithNds * (1 - (discount || 0) / 100),
                0
              )
            )}
          </h4>
        </>
      )}
    </>
  );
};
