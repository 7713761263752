import { CButton, CCard, CCol, CCollapse, CRow } from '@coreui/react';
import React, { useEffect, useState } from 'react';

type Props = {
  children: React.ReactNode | React.ReactNode[];
  initialOpen?: boolean;
  open?: boolean;
};

export const UiToolbar = ({ children, initialOpen, open }: Props): React.ReactElement => {
  const [isOpen, setIsOpen] = useState(initialOpen);

  useEffect(() => {
    if (open) {
      setIsOpen(open);
    }
  }, [open, setIsOpen]);

  return (
    <CCard className="p-3">
      <CRow>
        <CCol xs="12" className="d-flex justify-content-end">
          <CButton color="primary" onClick={() => setIsOpen(!isOpen)}>
            Фильтр
          </CButton>
        </CCol>
        <CCol xs="12">
          <CCollapse show={isOpen}>
            {children}
            <CRow>
              <CCol xs="12" className="d-flex justify-content-end mb-3 mr-3 mt-3">
                <CButton type="reset">Очистить</CButton>
                <CButton type="submit" color="primary" className="ml-3">
                  Найти
                </CButton>
              </CCol>
            </CRow>
          </CCollapse>
        </CCol>
      </CRow>
    </CCard>
  );
};
