import React, { useCallback, useEffect, useState } from 'react';

import { getAllLegalPersonsList } from 'api/legalPerson';
import { SelectOptionsType } from 'components/UI/UiSelect';
import { doRequest } from 'utils/doRequest';
import { getAgencyContractList } from 'api/agencyContract';
import { SystemAgencyContractListComponent } from 'components/System/AgencyContract/list';

let mounted = false;

export const SystemAgencyContractList = (): React.ReactElement => {
  useEffect(() => {
    mounted = true;
    return () => {
      mounted = false;
    };
  }, []);

  const [legalPersonsAgentSelectOptions, setLegalPersonsAgent] = useState<SelectOptionsType<number>>([]);
  const [legalPersonsRecipientSelectOptions, setLegalPersonsRecipient] = useState<SelectOptionsType<number>>([]);
  const [legalPersonsLoading, setLegalPersonsLoading] = useState(true);

  useEffect(() => {
    doRequest(
      () => Promise.all([getAllLegalPersonsList({ isAgent: true }), getAllLegalPersonsList({ isRecipient: true })]),
      () => mounted
    )(
      ([{ data: agents }, { data: recipients }]) => {
        setLegalPersonsAgent(agents.map(({ name, id }) => ({ value: id, label: name })));
        setLegalPersonsRecipient(recipients.map(({ name, id }) => ({ value: id, label: name })));
      },
      () => {
        setLegalPersonsLoading(false);
      }
    );
  }, []);

  const getData = useCallback((filter) => getAgencyContractList(filter), []);

  return (
    <SystemAgencyContractListComponent
      getData={getData}
      legalPersonsAgentSelectOptions={legalPersonsAgentSelectOptions}
      legalPersonsRecipientSelectOptions={legalPersonsRecipientSelectOptions}
      loadingFilterData={legalPersonsLoading}
    />
  );
};
