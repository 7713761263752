import { getAllCustomersList } from 'api/customer';
import isFunction from 'lodash/isFunction';
import isNaN from 'lodash/isNaN';
import toNumber from 'lodash/toNumber';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { createClientContract, getClientContract, updateClientContract } from 'api/clientContract';
import { ClientContractCreateType, ClientContractUpdateType } from 'models/ClientContract';
import { getAllLegalPersonsList } from 'api/legalPerson';
import { SelectOptionsType } from 'components/UI/UiSelect';
import { SystemClientContractFormComponent } from 'components/System/ClientContract/form';
import { addClientContractAction } from 'store/actions';
import { clientContractTranslations } from 'translations/clientContract';
import { doRequest } from 'utils/doRequest';
import { UiLoader } from 'components/UI/UiLoader';
import { useAllManagers } from 'utils/hooks/useAllManagers';
import { useRequest } from 'utils/hooks/useRequest';

type Props = {
  modal?: boolean; // признак вывода в модалке
  closeModal?: () => void; // опциональное закрытие модалки (при сохранении)
  onSaved?: (item: any) => void;
};

let mounted = false;

export const SystemClientContractForm = ({ modal = false, closeModal, onSaved }: Props): React.ReactElement => {
  useEffect(() => {
    mounted = true;
    return () => {
      mounted = false;
    };
  }, []);

  const query = useParams<{ id?: string }>();
  const numberId = toNumber(query.id);

  const dispatch = useDispatch();

  const getData = useCallback(() => (isNaN(numberId) || modal ? null : getClientContract(numberId)), [numberId]);
  const { model: initModel, loading } = useRequest(getData, null, () => mounted);

  const [key, setKey] = useState(1);
  const [model, setModel] = useState(initModel);
  useEffect(() => {
    setModel(initModel);
  }, [initModel]);

  const [customerList, setCustomerList] = useState<SelectOptionsType<number>>([]);
  const [customerListLoading, setCustomerListLoading] = useState(true);

  useEffect(() => {
    doRequest(getAllCustomersList, () => true)(
      ({ data }) => {
        setCustomerList(data.map((m) => ({ value: m.id, label: m.name })));
      },
      () => {
        setCustomerListLoading(false);
      }
    );
  }, []);

  const { managersListSelectOptions: managerList, loading: managersLoading } = useAllManagers();

  /** найти юр.лиц-исполнителей */
  const [legalPersonsExecutorSelectOptions, setLegalPersonsExecutor] = useState<SelectOptionsType<number>>([]);
  const [legalPersonsRecipientsSelectOptions, setLegalPersonsRecipients] = useState<SelectOptionsType<number>>([]);
  const [legalPersonsLoading, setLegalPersonsLoading] = useState(true);

  useEffect(() => {
    doRequest(
      () => Promise.all([getAllLegalPersonsList({ isExecutor: true }), getAllLegalPersonsList({ isRecipient: true })]),
      () => mounted
    )(
      ([executors, recipients]) => {
        setLegalPersonsExecutor(executors.data.map(({ name, id }) => ({ value: id, label: name })));
        setLegalPersonsRecipients(recipients.data.map(({ name, id }) => ({ value: id, label: name })));
      },
      () => {
        setLegalPersonsLoading(false);
      }
    );
  }, []);

  const [legalPersonsFoundByCustomerId, setLegalPersonsFoundByCustomerId] = useState<SelectOptionsType<number>>([]);
  const [legalPersonsFoundByCustomerIdLoading, setLegalPersonsFoundByCustomerIdLoading] = useState(false);

  const findLegalPersonsByCustomerId = useCallback((customerId: number) => {
    setLegalPersonsFoundByCustomerIdLoading(true);

    /** найти получателей, зависящих от заказчика */
    doRequest(
      () => getAllLegalPersonsList({ customerId, isRecipient: true }),
      () => mounted
    )(
      ({ data }) => {
        setLegalPersonsFoundByCustomerId(data.map(({ id, name }) => ({ value: id, label: name })));
      },
      () => {
        setLegalPersonsFoundByCustomerIdLoading(false);
      },
      () => {
        setLegalPersonsFoundByCustomerId([]);
      }
    );
  }, []);

  const [saveLoading, setSaveLoading] = useState(false);
  const onSave = useCallback((id: number | undefined, data: ClientContractCreateType | ClientContractUpdateType) => {
    setSaveLoading(true);
    const updateMode = !!id;

    const apiFn = updateMode
      ? () => updateClientContract(id as number, data as ClientContractUpdateType)
      : () => createClientContract(data as ClientContractCreateType);

    doRequest(apiFn, () => mounted)(
      (savedItem) => {
        if (modal) {
          if (isFunction(closeModal)) {
            closeModal();
          }

          if (isFunction(onSaved)) {
            onSaved(savedItem);
          }
        }

        dispatch(addClientContractAction(savedItem));

        /** грязные хаки */
        getClientContract(savedItem.id).then((response) => {
          setModel(response);
          setKey((k) => k + 1);
        });

        toast.success(updateMode ? clientContractTranslations.updated : clientContractTranslations.created);
      },
      () => {
        setSaveLoading(false);
      }
    );
  }, []);

  return customerListLoading || legalPersonsLoading || loading || managersLoading ? (
    <UiLoader />
  ) : (
    <SystemClientContractFormComponent
      key={key}
      model={model}
      saveLoading={saveLoading}
      customerList={customerList}
      findLegalPersonsByCustomerId={findLegalPersonsByCustomerId}
      legalPersonsFoundByCustomerIdLoading={legalPersonsFoundByCustomerIdLoading}
      legalPersonsFoundByCustomerId={legalPersonsFoundByCustomerId}
      legalPersonsListSelectOptions={legalPersonsExecutorSelectOptions}
      legalPersonsAllListSelectOptions={legalPersonsRecipientsSelectOptions}
      managerList={managerList}
      onSave={onSave}
      modal={modal}
    />
  );
};
