import { maxValueToGetAllDataWithPagination } from 'config';
import {
  ServiceTypeFormType,
  ServiceTypeListFilterType,
  ServiceTypeListModel,
  ServiceTypeModel,
} from 'models/ServiceType';
import { httpGet, httpPost, httpPut } from 'utils/http';

const path = '/admin/service-types';

/** получить список типов сервисов */
export const getServiceTypeList = (filter?: ServiceTypeListFilterType): Promise<ServiceTypeListModel> =>
  httpGet(`${path}/filter`, filter);

/** получить список типов сервисов */
export const getAllServiceTypeList = (filter?: ServiceTypeListFilterType): Promise<ServiceTypeListModel> =>
  httpGet(`${path}/filter`, { ...filter, ageLimit: maxValueToGetAllDataWithPagination });

/** получить тип сервиса */
export const getServiceType = (id: number): Promise<ServiceTypeModel> => httpGet(`${path}/service-type/${id}`);

/** создать тип сервиса */
export const createServiceType = (model: ServiceTypeFormType): Promise<ServiceTypeModel> =>
  httpPost(`${path}/service-type`, model);

/** обновить тип сервиса */
export const updateServiceType = (id: number, model: ServiceTypeFormType): Promise<ServiceTypeModel> =>
  httpPut(`${path}/service-type/${id}`, model);
