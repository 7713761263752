export const productTranslations = {
  route: {
    list: 'Услуги',
    create: 'Услуга (создание)',
    update: 'Услуга (редактирование)',
  },
  new: 'Новая услуга',
  name: 'Наименование',
  type: 'Тип',
  costPrice: 'Себестоимость, руб. (без НДС)',
  unitOfMeasure: 'Единица измерения',
  created: 'Услуга успешно создана',
  updated: 'Услуга успешно обновлена',
  volume: 'Объем',
  discount: 'Скидка, %',
  percentageOfAgencyRemuneration: 'Агент. вознагр., %',
  providingDate: 'Дата предоставления',
  isInvoiceCreated: 'Начисление создано',
};
