import React, { useEffect, useState } from 'react';

import { getInvoiceForAgencyList } from 'api/invoiceForAgency';
import { SystemInvoiceForAgencyListComponent } from 'components/System/InvoceForAgency/list';
import { formatDate } from 'utils/date';
import { doRequest } from 'utils/doRequest';
import { getAllAgencyContract } from 'api/agencyContract';
import { getAllLegalPersonsList } from 'api/legalPerson';
import { SelectOptionsType } from 'components/UI/UiSelect';

let mounted = false;

export const SystemInvoiceForAgencyList: React.FC = () => {
  useEffect(() => {
    mounted = true;
    return () => {
      mounted = false;
    };
  }, []);

  const [models, setModels] = useState<{
    legalPersons: SelectOptionsType<number>;
    contracts: SelectOptionsType<number>;
  }>({ legalPersons: [], contracts: [] });

  useEffect(() => {
    doRequest(
      () => Promise.all([getAllLegalPersonsList(), getAllAgencyContract()]),
      () => mounted
    )(([legalPersons, contracts]) => {
      setModels({
        legalPersons: legalPersons.data.map(({ name, id }) => ({ value: id, label: name })),
        contracts: contracts.data.map(({ id, creationDate }) => ({
          value: id,
          label: `№${id} от ${formatDate(creationDate)}`,
        })),
      });
    });
  }, []);

  return (
    <SystemInvoiceForAgencyListComponent
      getData={getInvoiceForAgencyList}
      legalPersons={models.legalPersons}
      agencyContracts={models.contracts}
    />
  );
};
