import { SelectOptionsType } from 'components/UI/UiSelect';
import { getCurrentUserRole } from 'models/Auth';
import { ProductTypesEnum } from 'models/Product';
import { TariffPositions, TariffProductInfoType } from 'models/Tariff';

export const findTariffsHelper = (type: ProductTypesEnum, data?: TariffPositions | null): TariffProductInfoType[] =>
  data
    ? data
        .filter(({ product }) => product.type === type)
        .map(({ productId, priceWithNds, priceWithoutNds }) => ({
          productId,
          priceWithNds,
          priceWithoutNds,
        }))
    : [];

/** собрать массив актуальных юрлиц */
export const getLegalPersonAndTariffRequests = (
  legalPersonIds: SelectOptionsType<number> | null,
  dataArr?: { legalPersonId: number }[] | null
): { legalPersonId: number; isAllow: boolean }[] => {
  /** isAllow непонятно что делает */
  const creationLegalPersonAndTariffRequests: {
    legalPersonId: number;
    isAllow: boolean;
  }[] = dataArr
    ? dataArr.map(({ legalPersonId }) => ({
        legalPersonId,
        isAllow: false,
      }))
    : [];

  if (legalPersonIds) {
    legalPersonIds.forEach(({ value }) => {
      const foundIndex = creationLegalPersonAndTariffRequests.findIndex(({ legalPersonId }) => legalPersonId === value);

      if (foundIndex !== -1) {
        creationLegalPersonAndTariffRequests[foundIndex].isAllow = true;
      } else {
        creationLegalPersonAndTariffRequests.push({
          legalPersonId: value,
          isAllow: true,
        });
      }
    });
    return creationLegalPersonAndTariffRequests;
  }
  return [];
};

/** инфа о актуальных услугах и удаленных (только ид) */
export const getTariffPositions = (
  actual: TariffProductInfoType[],
  modelPositions?: TariffPositions
): {
  actual: TariffProductInfoType[];
  forDelete: number[];
} => ({
  actual: actual.map(({ productId, priceWithoutNds, priceWithNds }) => ({
    productId,
    priceWithoutNds: +parseFloat(priceWithoutNds.toString()).toFixed(2),
    priceWithNds: +parseFloat(priceWithNds.toString()).toFixed(2),
  })),
  forDelete: modelPositions
    ? modelPositions.reduce<number[]>((acc, { productId, id }) => {
        const found = actual.findIndex((a) => a.productId === productId);

        if (found === -1) {
          acc.push(id);
        }

        return acc;
      }, [])
    : [],
});

/** Возвращает объект с полями которые нужно задизеблить в зависимости от прав пользователя */
export const getTariffRights = (updateMode = false, createBasedOn = false): { [key: string]: boolean } => {
  if (createBasedOn) {
    // всегда можно
    return {
      name: false,
      legalPersonIds: false,
      isArchive: false,
      abonementProducts: false,
      abonementProductsRemove: false,
      razProducts: false,
      razProductsRemove: false,
    };
  }
  const { isAdmin, isChiefManager } = getCurrentUserRole();

  return {
    name: updateMode,
    legalPersonIds: updateMode && !(isAdmin || isChiefManager),
    isArchive: updateMode && !(isAdmin || isChiefManager),
    abonementProducts: updateMode && !(isAdmin || isChiefManager),
    abonementProductsRemove: updateMode && !isAdmin,
    razProducts: updateMode && !(isAdmin || isChiefManager),
    razProductsRemove: updateMode && !isAdmin,
  };
};
