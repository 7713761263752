import toNumber from 'lodash/toNumber';
import isString from 'lodash/isString';

/**
 * установить значение из фильтра, если есть
 */
export const setValueFilter = (
  filter: Record<string, any>,
  setValue: (name: string, value: any) => void,
  name: string,
  getValue: () => any = () => null
): void => {
  if (filter[name]) {
    setValue(name, getValue() || filter[name]);
  }
};

/**
 * превращение id в массив ids только из целых, не включая 0
 */
export const prepareIds = (id?: string | number): [number] | undefined => {
  const numberId = toNumber(id);

  return numberId ? [numberId] : undefined;
};

/**
 * - trim и удаление лишних пробелов в строковых полях (без рекурсии)
 * - удаление пустых массивов
 */
export const prepareFormData = <T>(model: T): T =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Object.fromEntries(
    Object.entries(model)
      .map(([key, value]) => [key, isString(value) ? value.trim().replace(/\s+/g, ' ') : value])
      .filter(([, value]) => !(Array.isArray(value) && value.length === 0))
  );
