import React, { useCallback, useState } from 'react';

import { SystemProductForm } from 'containers/System/Product/form';
import { ProductModel } from 'models/Product';
import { UiModal } from 'components/UI/UiModal';
import { SelectOptionType, UiSelect } from 'components/UI/UiSelect';
import { productTranslations } from 'translations/product';
import { CButton, CCol, CRow } from '@coreui/react';

// почти идентично UiSelectComponent Props
type Props = {
  register: any;
  name: string;
  options: SelectOptionType[] | { label: string; options: SelectOptionType[] }[];
  setValue: any;
  value: any;
  onChange: (v: SelectOptionType | SelectOptionType[]) => void;
  onChangeNew: (v: ProductModel) => void;
  label: string;

  errors?: { [s: string]: unknown }; // объект с ошибками
  isLoading?: boolean;
  disabled?: boolean;
};

export const UiSelectProductModal = ({
  register,
  name,
  options,
  setValue,
  errors,
  value,
  label,
  onChange,
  onChangeNew,
  isLoading,
  disabled = false,
}: Props): React.ReactElement => {
  const [showModal, setShowModal] = useState(false);
  const showModalFn = useCallback(() => {
    setShowModal(true);
  }, [onChange]);
  const hideModalFn = useCallback(() => {
    setShowModal(false);
  }, []);

  const onSave = useCallback((savedItem: ProductModel) => {
    onChangeNew(savedItem);
    onChange({ label: savedItem.name, value: savedItem.id });
  }, []);

  return (
    <CRow>
      <CCol xs={6}>
        <UiSelect
          register={register}
          name={name}
          label={label}
          errors={errors}
          options={options}
          setValue={setValue}
          value={value}
          onChange={onChange}
          allowEmptyValue
          appendFn={showModalFn}
          isLoading={isLoading}
          disabled={disabled}
          closeMenuOnSelect={false}
        />
        <UiModal header={productTranslations.new} show={showModal} onClose={hideModalFn}>
          <SystemProductForm modal closeModal={hideModalFn} onSaved={onSave} />
        </UiModal>
      </CCol>
      <CCol xs={3}>
        {/* eslint-disable-next-line jsx-a11y/label-has-for */}
        <label>&nbsp;</label>
        <CButton
          block
          color="primary"
          type="button"
          disabled={disabled || options.length === 0}
          onClick={() => {
            onChange(options as SelectOptionType[]);
          }}
        >
          Добавить все
        </CButton>
      </CCol>
    </CRow>
  );
};
