import React from 'react';
import { useForm } from 'react-hook-form';
import { CCol, CForm, CRow } from '@coreui/react';

import { InvoiceOnOrderFilterType } from 'models/InvoiceOnOrder';
import { SelectOptionsType, SelectOptionType, UiSelect } from 'components/UI/UiSelect';
import { UiInput } from 'components/UI/UiInput';
import { UiToolbar } from 'components/UI/UiToolbar';
import { invoiceOnOrderTranslations } from 'translations/invoiceOnOrder';
import { getMaxDate, getMinDate } from 'utils/date';
import { prepareFormData, prepareIds } from 'utils/formFilter';
import { Optional } from 'utils/types';
import { UiDateRange } from 'components/UI/UiDateRange';

type Props = {
  onSearch: (f: InvoiceOnOrderFilterType) => void;
  onReset: () => void;
  customerList: SelectOptionsType<number>;
  legalPersonsOptionsList: SelectOptionsType<number>;
  clientContractsOptionsList: SelectOptionsType<number>;
  servicesOptionsList: SelectOptionsType<number>;
  ordersOptionsList: SelectOptionsType<number>;
};

type FormValues = Optional<{
  id: number;
  customerIds: SelectOptionType<number>;
  legalPersonIds: SelectOptionType<number>;
  clientContractIds: SelectOptionType<number>;
  serviceIds: SelectOptionType<number>;
  orderIds: SelectOptionType<number>;
  isReversal: SelectOptionType<number>;
}> & {
  minCreationDate: Date | null;
  maxCreationDate: Date | null;
  minBeginOfInvoicePeriodDate: Date | null;
  maxFinishOfInvoicePeriodDate: Date | null;
};

export const SystemInvoiceOnOrderFilter = ({
  onSearch,
  onReset,
  customerList,
  legalPersonsOptionsList,
  clientContractsOptionsList,
  servicesOptionsList,
  ordersOptionsList,
}: Props): React.ReactElement => {
  const { register, handleSubmit, reset, setValue, watch, control } = useForm<FormValues>({
    defaultValues: {
      minCreationDate: null,
      maxCreationDate: null,
    },
  });

  const onLocalReset = () => {
    reset();
    onReset();
  };

  const onSearchLocal = (data: FormValues) =>
    onSearch(
      prepareFormData({
        ids: prepareIds(data.id),
        minCreationDate: getMinDate(data.minCreationDate),
        maxCreationDate: getMaxDate(data.maxCreationDate),
        minBeginOfInvoicePeriodDate: getMinDate(data.minBeginOfInvoicePeriodDate),
        maxFinishOfInvoicePeriodDate: getMaxDate(data.maxFinishOfInvoicePeriodDate),
        customerIds: data.customerIds ? [data.customerIds.value] : undefined,
        legalPersonIds: data.legalPersonIds ? [data.legalPersonIds.value] : undefined,
        clientContractIds: data.clientContractIds ? [data.clientContractIds.value] : undefined,
        serviceIds: data.serviceIds ? [data.serviceIds.value] : undefined,
        orderIds: data.orderIds ? [data.orderIds.value] : undefined,
        isReversal: data.isReversal ? data.isReversal.value === 1 : undefined,
      })
    );

  return (
    <CForm onSubmit={handleSubmit(onSearchLocal)} onReset={onLocalReset}>
      <UiToolbar open={Object.values(watch()).some((f) => f)}>
        <CRow>
          <CCol xs="12" sm="6" md="4">
            <UiInput register={register} name="id" label="ID" />
          </CCol>
          <CCol xs="12" sm="6" md="4">
            <UiDateRange
              control={control}
              setValue={setValue}
              label={invoiceOnOrderTranslations.creationDate}
              nameMax="maxCreationDate"
              nameMin="minCreationDate"
              valueMax={watch('maxCreationDate')}
              valueMin={watch('minCreationDate')}
            />
          </CCol>
          <CCol xs="12" sm="6" md="4">
            <UiDateRange
              control={control}
              setValue={setValue}
              label={invoiceOnOrderTranslations.invoicePeriod}
              nameMax="maxFinishOfInvoicePeriodDate"
              nameMin="minBeginOfInvoicePeriodDate"
              valueMax={watch('maxFinishOfInvoicePeriodDate')}
              valueMin={watch('minBeginOfInvoicePeriodDate')}
            />
          </CCol>

          <CCol xs="12" sm="6" md="4">
            <UiSelect
              label={invoiceOnOrderTranslations.customer}
              register={register}
              name="customerIds"
              options={customerList}
              setValue={setValue}
              value={watch('customerIds')}
              allowEmptyValue
              isClearable
            />
          </CCol>

          <CCol xs="12" sm="6" md="4">
            <UiSelect
              label={invoiceOnOrderTranslations.legalPerson}
              register={register}
              name="legalPersonIds"
              options={legalPersonsOptionsList}
              setValue={setValue}
              value={watch('legalPersonIds')}
              allowEmptyValue
              isClearable
            />
          </CCol>

          <CCol xs="12" sm="6" md="4">
            <UiSelect
              label={invoiceOnOrderTranslations.clientContract}
              register={register}
              name="clientContractIds"
              options={clientContractsOptionsList}
              setValue={setValue}
              value={watch('clientContractIds')}
              allowEmptyValue
              isClearable
            />
          </CCol>

          <CCol xs="12" sm="6" md="4">
            <UiSelect
              label={invoiceOnOrderTranslations.service}
              register={register}
              name="serviceIds"
              options={servicesOptionsList}
              setValue={setValue}
              value={watch('serviceIds')}
              allowEmptyValue
              isClearable
            />
          </CCol>

          <CCol xs="12" sm="6" md="4">
            <UiSelect
              label={invoiceOnOrderTranslations.order}
              register={register}
              name="orderIds"
              options={ordersOptionsList}
              setValue={setValue}
              value={watch('orderIds')}
              allowEmptyValue
              isClearable
            />
          </CCol>

          <CCol xs="12" sm="6" md="4">
            <UiSelect
              label={invoiceOnOrderTranslations.isReversal}
              register={register}
              name="isReversal"
              options={[
                { value: 1, label: 'Да' },
                { value: -1, label: 'Нет' },
              ]}
              setValue={setValue}
              value={watch('isReversal')}
              allowEmptyValue
              isClearable
            />
          </CCol>
        </CRow>
      </UiToolbar>
    </CForm>
  );
};
