export const tariffTranslations = {
  route: {
    list: 'Тарифы',
    create: 'Тариф (создание)',
    createBasedOn: 'Тариф (создание на основании существующего тарифа)',
    update: 'Тариф (редактирование)',
  },
  new: 'Новый тариф',
  name: 'Наименование',
  legalPersons: 'Исполнители',
  abonementProducts: 'Абонементные услуги',
  razProducts: 'Единовременные услуги',
  product: 'Услуга',
  isActive: 'Активный тариф',
  isArchive: 'Архивный тариф',
  priceWithoutNds: 'Цена без НДС',
  priceWithNds: 'Цена с НДС',
  created: 'Тариф успешно создан',
  updated: 'Тариф успешно обновлен',
  ndsNeeded: 'Необходимо заполнить данные НДС',
};
