import { getAllCustomersList } from 'api/customer';
import { SelectOptionsType } from 'components/UI/UiSelect';
import React, { useEffect, useState } from 'react';
import { getServiceList } from 'api/service';
import { SystemServiceListComponent } from 'components/System/Service/list';
import { doRequest } from 'utils/doRequest';

export const SystemServiceList = (): React.ReactElement => {
  const [customersListSelectOptions, setCustomersListSelectOptions] = useState<SelectOptionsType<number>>([]);

  useEffect(() => {
    doRequest(
      getAllCustomersList,
      () => true
    )(({ data }) => {
      setCustomersListSelectOptions(data.map((m) => ({ value: m.id, label: m.name })));
    });
  }, []);

  return <SystemServiceListComponent getData={getServiceList} customerOptionsList={customersListSelectOptions} />;
};
