import { maxValueToGetAllDataWithPagination } from 'config';
import {
  CustomerFormModel,
  CustomerManagersListModel,
  CustomerModel,
  CustomersFilterType,
  CustomerListModel,
} from 'models/Customer';
import { httpGet, httpPost, httpPut } from 'utils/http';

const path = '/admin/customers';

/** получить список покупателей */
export const getCustomersList = (filter?: CustomersFilterType): Promise<CustomerListModel> =>
  httpGet(`${path}/filter`, filter);

/** получить список всех покупателей */
export const getAllCustomersList = (filter?: CustomersFilterType): Promise<CustomerListModel> =>
  getCustomersList({ ...filter, pageLimit: maxValueToGetAllDataWithPagination });

/** получить покупателя */
export const getCustomer = (id: number): Promise<CustomerModel> => httpGet(`${path}/customer/${id}`);

/** создать покупателя */
export const createCustomer = (model: CustomerFormModel): Promise<CustomerModel> => httpPost(`${path}/customer`, model);

/** редактировать покупателя */
export const updateCustomer = (id: number, model: CustomerFormModel): Promise<CustomerModel> =>
  httpPut(`${path}/customer/${id}`, model);

/** получить список всех менеджеров */
export const getAllCustomersManagersList = (): Promise<CustomerManagersListModel> =>
  httpGet(`${path}/managers`, { pageLimit: maxValueToGetAllDataWithPagination });
