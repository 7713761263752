import { InvoiceForAgencyFilterType, InvoiceForAgencyListType, InvoiceForAgencyType } from 'models/InvoiceForAgency';
import { httpGet } from 'utils/http';

const path = '/admin/invoices-for-agency';

/** получить агентские вонаграждения */
export const getInvoiceForAgencyList = (filter?: InvoiceForAgencyFilterType): Promise<InvoiceForAgencyListType> =>
  httpGet(`${path}/filter`, filter);

/** получить агентское вонаграждение */
export const getInvoiceForAgency = (id: number): Promise<InvoiceForAgencyType> =>
  httpGet(`${path}/invoice-for-agency/${id}`);
