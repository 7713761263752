import { getAllAgencyContract } from 'api/agencyContract';
import { useCallback, useEffect, useState } from 'react';
import { SelectOptionsType } from 'components/UI/UiSelect';
import { formatDate } from 'utils/date';
import { doRequest } from 'utils/doRequest';

/** Найти агентские договоры по юл агента */
export const useAgencyContractsOptions = (
  mounted = true
): [
  SelectOptionsType<number>, // Список
  (personId?: number) => void, // Функция для загрузки списка
  boolean // Индикатор загрузки
] => {
  const [list, setList] = useState<SelectOptionsType<number>>([]);
  const [loading, setLoading] = useState(false);
  const getList = useCallback((personId?: number) => {
    setLoading(true);
    doRequest(
      () => getAllAgencyContract({ agencyLegalPersonIds: personId ? [personId] : undefined }),
      () => mounted
    )(
      ({ data }) => {
        setList(
          data.map(({ id, creationDate, numberOfContract }) => ({
            value: id,
            label: `${numberOfContract} от ${formatDate(creationDate)}`,
          }))
        );
      },
      () => {
        setLoading(false);
      },
      () => {
        setList([]);
      }
    );
  }, []);
  useEffect(() => {
    getList();
  }, []);

  return [list, getList, loading];
};
