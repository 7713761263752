import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { CContainer } from '@coreui/react';

import { routes } from 'routes';

export const TheContent = (): React.ReactElement => (
  <main className="c-main">
    <CContainer fluid>
      <Switch>
        {routes.map(
          (route) =>
            route.component && (
              <Route key={route.path} path={route.path} exact={route.exact} component={route.component} />
            )
        )}
      </Switch>
    </CContainer>
  </main>
);
