import { CModal, CModalBody, CModalHeader } from '@coreui/react';
import React from 'react';
import { createPortal } from 'react-dom';

type Props = {
  header: string;
  children: React.ReactNode | React.ReactNode[];

  show?: boolean;
  size?: '' | 'sm' | 'lg' | 'xl';
  onClose?: () => void;
};

/**
 * контролируемая модалка для вывода контента (форм)
 * через портал, чтобы отдельно все было. не пересекались формы
 */
export const UiModal = ({ header, children, show = false, size = '', onClose }: Props): React.ReactElement | null =>
  show
    ? createPortal(
        <CModal show={show} size={size} onClose={onClose}>
          <CModalHeader closeButton>
            <h3 className="text-center modal-header-title">{header}</h3>
          </CModalHeader>
          <CModalBody>{children}</CModalBody>
        </CModal>,
        document.body
      )
    : null;
