import { getCurrentUserRole, getIsOwner } from 'models/Auth';
import { getMinDate } from 'utils/date';

/** Возвращает объект с полями которые нужно задизеблить в зависимости от прав пользователя */
export const getClientContractRights = (updateMode = false, managerId = ''): { [key: string]: boolean } => {
  const { isAdmin } = getCurrentUserRole();
  const isOwner = getIsOwner(managerId);

  return {
    numberOfContract: updateMode,
    startingDate: updateMode && !isAdmin,
    completionDate: updateMode && !isAdmin,
    customerToRhs: updateMode,
    rhsLegalPersonId: updateMode,
    lhsLegalPersonId: updateMode,
    methodOfInvoice: updateMode,
    manager: !(updateMode && isAdmin),

    isOwner,
  };
};

export const prepareDateToUpdate = (date: Date | null, oldDate?: number): number | undefined => {
  const newDate = getMinDate(date);
  return newDate === oldDate ? undefined : newDate;
};
