import React from 'react';
import { CCard, CCardBody } from '@coreui/react';
import { Link } from 'react-router-dom';

import { UiTable } from 'components/UI/UiTable';
import { InvoiceForAgencyListType, InvoiceForAgencyType } from 'models/InvoiceForAgency';
import { getInvoiceForAgencyList } from 'api/invoiceForAgency';
import { invoiceForAgencyTranslations } from 'translations/InvoiceForAgency';
import { formatDate } from 'utils/date';
import { formatCurrency } from 'utils/formatters';
import { useFilter } from 'utils/hooks/useFilter';
import { SystemInvoiceForAgencyFilter } from 'components/System/InvoceForAgency/filter';
import { SelectOptionsType } from 'components/UI/UiSelect';
import { systemInvoceAgencyRoute } from 'containers/System/invoiceForAgency/routes';

const fields = [
  { key: 'id', label: 'ID' },
  { key: 'creationDate', label: invoiceForAgencyTranslations.creationDate },
  { key: 'invoicePeriod', label: invoiceForAgencyTranslations.invoicePeriod },
  { key: 'agent', label: invoiceForAgencyTranslations.agent },
  { key: 'agencyContract', label: invoiceForAgencyTranslations.agencyContract },
  { key: 'ul', label: invoiceForAgencyTranslations.invitedLegalPersonIds },
  { key: 'remunerationWithNds', label: invoiceForAgencyTranslations.remunerationWithNds },
];

type Props = {
  getData: typeof getInvoiceForAgencyList;
  legalPersons: SelectOptionsType<number>;
  agencyContracts: SelectOptionsType<number>;
};

export const SystemInvoiceForAgencyListComponent: React.FC<Props> = ({
  getData,
  legalPersons,
  agencyContracts,
}: Props) => {
  const {
    onPaginationChange,
    loading,
    model,
    filter,
    onReset,
    onSearch,
    onChangePage,
  } = useFilter<InvoiceForAgencyListType>(getData);

  return (
    <CCard>
      <CCardBody>
        <h3 className="text-center">{invoiceForAgencyTranslations.route.list}</h3>

        <SystemInvoiceForAgencyFilter
          onSearch={onSearch}
          onReset={onReset}
          legalPersons={legalPersons}
          agencyContracts={agencyContracts}
        />

        <UiTable
          items={model}
          fields={fields}
          loading={loading}
          pageLimit={filter.pageLimit}
          onChangePage={onChangePage}
          scopedSlots={{
            id: ({ id }: InvoiceForAgencyType) => (
              <td>
                <Link to={systemInvoceAgencyRoute(id)}>{id}</Link>
              </td>
            ),
            creationDate: ({ creationDate }: InvoiceForAgencyType) => <td>{formatDate(creationDate)}</td>,
            invoicePeriod: ({ beginOfInvoicePeriod, finishOfInvoicePeriod }: InvoiceForAgencyType) => (
              <td>
                {formatDate(beginOfInvoicePeriod)} - {formatDate(finishOfInvoicePeriod)}
              </td>
            ),
            agent: ({ agencyContract }: InvoiceForAgencyType) => <td>{agencyContract.agencyLegalPerson.name}</td>,
            agencyContract: ({ agencyContract }: InvoiceForAgencyType) => (
              <td>{`№${agencyContract.id} от ${formatDate(agencyContract.creationDate)}`}</td>
            ),
            ul: ({ agencyContract }: InvoiceForAgencyType) => <td>{agencyContract.invitedLegalPerson.name}</td>,
            remunerationWithNds: ({ remunerationWithNds }: InvoiceForAgencyType) => (
              <td>{formatCurrency(remunerationWithNds)}</td>
            ),
          }}
          onPaginationChange={onPaginationChange}
        />
      </CCardBody>
    </CCard>
  );
};
