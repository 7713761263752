import { CCard, CCardBody, CCol, CRow } from '@coreui/react';
import isNil from 'lodash/isNil';
import React from 'react';

import { AgencyContractModel } from 'models/AgencyContract';
import { ClientContractModel } from 'models/ClientContract';
import { InvoiceOnOrderType } from 'models/InvoiceOnOrder';
import { getClientContractMethodOfInvoice } from 'components/System/ClientContract/common';
import { SelectOptionsType } from 'components/UI/UiSelect';
import { invoiceOnOrderTranslations } from 'translations/invoiceOnOrder';
import { formatDate } from 'utils/date';
import { formatCurrency, formatModelNameId } from 'utils/formatters';
import { baseFinder } from 'utils/listHelpers';

const noData = 'Не указано';

const InfoBlock = ({ label, value }: { label: string; value?: string | number | null }) => (
  <CCol xs="12" sm="6" md="4" className="mb-2">
    {/* eslint-disable-next-line jsx-a11y/label-has-for */}
    <label className="form-label">{label}</label>
    <input type="text" className="form-control" defaultValue={isNil(value) ? noData : value} disabled />
  </CCol>
);

type Props = {
  model: InvoiceOnOrderType | null;
  clientContract: ClientContractModel | null;
  agencyContract: AgencyContractModel | null;
  legalPersonsExecutorSelectOptions: SelectOptionsType<number>;
};

export const SystemInvoiceOnOrderFormComponent: React.FC<Props> = ({
  model,
  clientContract,
  agencyContract,
  legalPersonsExecutorSelectOptions,
}: Props) =>
  model ? (
    <CCard>
      <CCardBody>
        <h3>{formatModelNameId('Начисление', model.id)}</h3>
        <CRow>
          <InfoBlock label={invoiceOnOrderTranslations.creationDate} value={formatDate(model.creationDate)} />
          <InfoBlock
            label={invoiceOnOrderTranslations.invoicePeriod}
            value={`${formatDate(model.beginOfInvoicePeriod)} - ${formatDate(model.finishOfInvoicePeriod)}`}
          />
          <InfoBlock label={invoiceOnOrderTranslations.customer} value={model.legalPerson.customer.name} />
          <InfoBlock label={invoiceOnOrderTranslations.legalPerson} value={model.legalPerson.name} />
          <InfoBlock label={invoiceOnOrderTranslations.numberOfContract} value={model.order.service.clientContractId} />
          <InfoBlock
            label={invoiceOnOrderTranslations.methodOfInvoice}
            value={
              clientContract?.methodOfInvoice ? getClientContractMethodOfInvoice(clientContract.methodOfInvoice) : null
            }
          />
          <InfoBlock
            label={invoiceOnOrderTranslations.clientContractStartingDate}
            value={clientContract?.startingDate ? formatDate(clientContract.startingDate) : null}
          />
          <InfoBlock
            label={invoiceOnOrderTranslations.clientContractCompletionDate}
            value={clientContract?.completionDate ? formatDate(clientContract.completionDate) : null}
          />
          <InfoBlock
            label={invoiceOnOrderTranslations.lhsLegalPersonId}
            value={baseFinder(legalPersonsExecutorSelectOptions, clientContract?.lhsLegalPersonId)?.label || null}
          />
          <InfoBlock label={invoiceOnOrderTranslations.serviceName} value={model.order.service.name} />
          <InfoBlock
            label={invoiceOnOrderTranslations.serviceAgencyContractId}
            value={agencyContract ? `№${agencyContract.id} от ${formatDate(agencyContract.creationDate)}` : '-----'}
          />
          <InfoBlock
            label={invoiceOnOrderTranslations.agent}
            value={agencyContract ? agencyContract.agencyLegalPerson.name : '-----'}
          />
          <InfoBlock label={invoiceOnOrderTranslations.orderId} value={model.order.id} />
          <InfoBlock
            label={invoiceOnOrderTranslations.orderStartingDate}
            value={model.order.startingDate ? formatDate(model.order.startingDate) : null}
          />{' '}
          <InfoBlock
            label={invoiceOnOrderTranslations.orderCompletionDate}
            value={model.order.completionDate ? formatDate(model.order.completionDate) : null}
          />
          <InfoBlock label={invoiceOnOrderTranslations.costWithNds} value={formatCurrency(model.costWithNds)} />
          <InfoBlock label={invoiceOnOrderTranslations.costWithoutNds} value={formatCurrency(model.costWithoutNds)} />
          <InfoBlock label={invoiceOnOrderTranslations.isReversal} value={model.isReversal ? 'Да' : 'Нет'} />
        </CRow>
      </CCardBody>
    </CCard>
  ) : (
    <h1>Сущность не найдена</h1>
  );
