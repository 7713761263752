import { getAllServiceList } from 'api/service';
import { useCallback, useEffect, useState } from 'react';
import { SelectOptionsType } from 'components/UI/UiSelect';
import { doRequest } from 'utils/doRequest';

/** Найти найти сервисы по клиентскому договору */
export const useServicesOptions = (
  mounted = true
): [
  SelectOptionsType<number>, // Список
  (personId?: number) => void, // Функция для загрузки списка
  boolean // Индикатор загрузки
] => {
  const [list, setList] = useState<SelectOptionsType<number>>([]);
  const [loading, setLoading] = useState(false);
  const getList = useCallback((clientContractId?: number) => {
    setLoading(true);
    doRequest(
      () => getAllServiceList({ clientContractIds: clientContractId ? [clientContractId] : undefined }),
      () => mounted
    )(
      ({ data }) => {
        setList(
          data.map(({ id, name }) => ({
            value: id,
            label: name,
          }))
        );
      },
      () => {
        setLoading(false);
      },
      () => {
        setList([]);
      }
    );
  }, []);
  useEffect(() => {
    getList();
  }, []);

  return [list, getList, loading];
};
