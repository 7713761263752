import { legalPersonTranslations } from 'translations/legalPerson';

/**
 * роли юр.лиц по набору прав
 */
export const getLegalPersonRolesByRights = (obj: {
  isRecipient: boolean;
  isAgent: boolean;
  isExecutor: boolean;
  isNDSPayer: boolean;
}): string =>
  // уточнить типы
  Object.entries(obj)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    .map(([key, value]) => (value ? legalPersonTranslations.role[key] : null))
    .filter((m) => m)
    .join(', ');
