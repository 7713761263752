import { InvoiceOnOrderFilterType, InvoiceOnOrderListType, InvoiceOnOrderType } from 'models/InvoiceOnOrder';
import { httpGet } from 'utils/http';

const path = '/admin/invoices-on-order';

/** получить начислений */
export const getInvoiceOnOrderList = (filter?: InvoiceOnOrderFilterType): Promise<InvoiceOnOrderListType> =>
  httpGet(`${path}/filter`, filter);

/** получить начисление */
export const getInvoiceOnOrder = (id: number): Promise<InvoiceOnOrderType> => httpGet(`${path}/invoice-on-order/${id}`);
