import { CCard, CCardBody, CCol, CForm, CRow } from '@coreui/react';
import isEqual from 'lodash/isEqual';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { getCurrentUserRole } from 'models/Auth';
import { ServiceModel } from 'models/Service';
import { UiRowCheckbox } from 'components/UI/UiCheckbox';
import { SelectOptionsType, SelectOptionType, UiSelect } from 'components/UI/UiSelect';
import { UiSelectAgentContractModal } from 'components/UI/UiSelectAgentContractModal';
import { UiSelectClientContractModal } from 'components/UI/UiSelectClientContractModal';
import { UiSelectCustomerModal } from 'components/UI/UiSelectCustomerModal';
import { UiSelectLegalPersonModal } from 'components/UI/UiSelectLegalPersonModal';
import { UiSelectTariffModal } from 'components/UI/UiSelectTariffModal';
import { UiSubmitButton } from 'components/UI/UiSubmitButton';
import { getManagerId } from 'store/selectors';
import { customerTranslations } from 'translations/customer';
import { serviceTranslations } from 'translations/service';
import { formatModelNameId } from 'utils/formatters';
import { UiInput } from 'components/UI/UiInput';
import { prepareFormData } from 'utils/formFilter';
import { TSelectOptionClientContract } from 'utils/hooks/SelectOptionsHooks/useClientsContractsOptions';
import { baseFinder } from 'utils/listHelpers';
import { UiSelectServiceTypeModal } from 'components/UI/UiSelectServiceTypeModal';

type TCustomerOption = SelectOptionType<number> & { isPartner?: boolean };

type Props = {
  model: ServiceModel | null;
  saveLoading: boolean;
  onSave: (data: any) => void;
  customerList: SelectOptionsType<number>;
  findLegalPersonsByCustomerId: (id?: number) => void;
  legalPersonsFoundByCustomerIdLoading: boolean;
  legalPersonsFoundByCustomerId: SelectOptionsType<number>;

  findClientContracts: (id?: number) => void;
  clientContracts: TSelectOptionClientContract[];
  clientContractsLoading: boolean;
  findAgencyContracts: (id?: number) => void;
  agencyContracts: SelectOptionsType<number>;
  agencyContractsLoading: boolean;
  tariffs: SelectOptionsType<number>;
  tariffsLoading: boolean;
  getTariffs: (id?: number) => void;
  agents: SelectOptionsType<number>;
  managerList: SelectOptionsType<string>;
  modal: boolean;
  serviceTypes: SelectOptionsType<number>;
};

type FormValues = {
  name: string;
  customer: TCustomerOption | null;
  legalPerson: SelectOptionType<number> | null;
  clientContract: TSelectOptionClientContract | null;
  isAgent: boolean;
  legalPersonAgent?: SelectOptionType<number> | null;
  agencyContract?: SelectOptionType<number> | null;
  tariff: SelectOptionType<number> | null;
  manager: SelectOptionType<string> | null;
  serviceTypeId: SelectOptionType<number> | null;
  serviceTypeValue: string | null;
};

export const SystemServiceFormComponent = ({
  model,
  onSave,
  saveLoading,
  customerList,
  findLegalPersonsByCustomerId,
  legalPersonsFoundByCustomerIdLoading,
  legalPersonsFoundByCustomerId,
  findClientContracts,
  clientContracts,
  clientContractsLoading,
  findAgencyContracts,
  agencyContracts,
  agencyContractsLoading,
  agents,
  tariffs,
  tariffsLoading,
  getTariffs,
  managerList,
  modal,
  serviceTypes,
}: Props): React.ReactElement => {
  const updateMode = !!model?.id;

  const managerId = useSelector(getManagerId);

  const initValues = useMemo(
    () => ({
      name: model?.name || '',
      customer: baseFinder(customerList, model?.clientContract?.rhsLegalPerson?.customerId) || null,
      legalPerson: baseFinder(legalPersonsFoundByCustomerId, model?.clientContract?.rhsLegalPersonId) || null,
      clientContract: baseFinder(clientContracts, model?.clientContractId) || null,
      isAgent: !!model?.agencyContractId,
      legalPersonAgent: baseFinder(agents, model?.agencyContract?.agencyLegalPersonId) || null,
      agencyContract: baseFinder(agencyContracts, model?.agencyContractId) || null,
      tariff: baseFinder(tariffs, model?.tariffId) || null,
      manager: model?.manager ? baseFinder(managerList, model.manager) : baseFinder(managerList, managerId),
      serviceTypeId: baseFinder(serviceTypes, model?.serviceType.id) || null,
      serviceTypeValue: model?.serviceTypeValue || '',
    }),
    []
  );

  const { register, handleSubmit, errors, watch, setValue } = useForm<FormValues>({
    defaultValues: initValues,
  });

  const disabledToSave = (() => {
    if (updateMode) {
      const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        customer,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        legalPerson,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        clientContract,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        agencyContract,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        tariff,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        manager,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        serviceTypeId,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        legalPersonAgent,
        ...initVals
      } = initValues;

      return isEqual(
        {
          ...initVals,
          clientContract: model?.clientContractId,
          agencyContract: model?.agencyContractId,
          tariff: model?.tariffId,
          serviceTypeId: model?.serviceType.id,
          manager: model?.manager,
        },
        {
          name: watch('name'),
          isAgent: watch('isAgent'),
          serviceTypeValue: watch('serviceTypeValue'),
          clientContract: watch('clientContract')?.value,
          agencyContract: watch('agencyContract')?.value || null,
          tariff: watch('tariff')?.value,
          serviceTypeId: watch('serviceTypeId')?.value,
          manager: watch('manager')?.value,
        }
      );
    }
    return false;
  })();

  /** nullable tariffs fix */
  useEffect(() => {
    setValue('tariff', baseFinder(tariffs, model?.tariffId) || null);
  }, [tariffs]);

  const { isAdmin } = getCurrentUserRole();

  const onSubmit = (data: FormValues) => {
    if (!disabledToSave) {
      onSave(
        prepareFormData({
          id: model?.id,
          name: data.name,
          clientContractId: data.clientContract?.value || undefined,
          agencyContractId: data.isAgent ? data.agencyContract?.value : undefined,
          tariffId: data.tariff?.value || undefined,
          manager: (updateMode && data.manager?.value) || undefined,
          serviceTypeId: data.serviceTypeId?.value,
          serviceTypeValue: data.serviceTypeValue,
        })
      );
    }
  };

  /** Запросить список юр лиц */
  const customer = watch('customer');
  useEffect(() => {
    findLegalPersonsByCustomerId(customer?.value ?? undefined);
    // очистить чекбокс при переключении
    if (customer?.isPartner) {
      setValue('isAgent', false);
    }
  }, [customer]);

  /** Запросить список клиентских договоров */
  const legalPerson = watch('legalPerson');
  useEffect(() => {
    findClientContracts(legalPerson?.value ?? undefined);
  }, [legalPerson]);

  /** Запросить список агентских договоров */
  const legalPersonAgent = watch('legalPersonAgent');
  useEffect(() => {
    findAgencyContracts(legalPersonAgent?.value ?? undefined);
  }, [legalPersonAgent]);

  /** Запросить список тарифов по клиентскому договору */
  const clientContract = watch('clientContract');
  useEffect(() => {
    getTariffs(clientContract?.lhsLegalPersonId ?? undefined);
    setValue('tariff', null);
  }, [clientContract]);

  /** Сбросить значение юр лица, если нет соответствий в списке*/
  useEffect(() => {
    if (legalPerson?.value && !baseFinder(legalPersonsFoundByCustomerId, legalPerson?.value)) {
      setValue('legalPerson', null);
    }
  }, [legalPersonsFoundByCustomerId]);

  /** Сбросить значение контракта КЛИЕНТА, если нет соответствий в списке*/
  useEffect(() => {
    if (clientContract?.value && !baseFinder(clientContracts, clientContract?.value)) {
      setValue('clientContract', null);
    }
  }, [clientContracts]);

  /** Сбросить значение контракта АГЕНТА, если нет соответствий в списке*/
  const agencyContract = watch('agencyContract');
  useEffect(() => {
    if (agencyContract?.value && !baseFinder(agencyContracts, agencyContract?.value)) {
      setValue('agencyContract', null);
    }
  }, [agencyContracts]);

  /** Сбросить значение тарифа, если нет соответствий в списке*/
  const tariff = watch('tariff');
  useEffect(() => {
    if (tariff?.value && !baseFinder(tariffs, tariff?.value)) {
      setValue('tariff', null);
    }
  }, [tariff]);

  return (
    <CForm onSubmit={handleSubmit(onSubmit)}>
      <CCard>
        <CCardBody>
          {!modal && <h3>{model?.id ? formatModelNameId(null, model.id) : serviceTranslations.route.create}</h3>}

          <CRow>
            <CCol xs={modal ? 12 : 9}>
              <CRow>
                <CCol xs="12" sm="6" md="4">
                  <UiInput
                    register={register}
                    label={serviceTranslations.name}
                    name="name"
                    errors={errors}
                    required
                    disabled={updateMode}
                  />
                </CCol>

                <CCol xs="12" sm="6" md="4">
                  <UiSelectCustomerModal
                    register={register}
                    name="customer"
                    options={customerList}
                    setValue={setValue}
                    value={watch('customer')}
                    errors={errors}
                    disabled={updateMode}
                    modal={modal}
                  />
                </CCol>

                <CCol xs="12" sm="6" md="4">
                  <UiSelectLegalPersonModal
                    label={serviceTranslations.legalPerson}
                    register={register}
                    name="legalPerson"
                    options={legalPersonsFoundByCustomerId}
                    setValue={setValue}
                    value={watch('legalPerson')}
                    errors={errors}
                    isLoading={legalPersonsFoundByCustomerIdLoading}
                    setOnSave
                    disabled={updateMode}
                    modal={modal}
                  />
                </CCol>

                <CCol xs="12" sm="6" md="4">
                  <UiSelectServiceTypeModal
                    label={serviceTranslations.serviceTypeId}
                    register={register}
                    name="serviceTypeId"
                    options={serviceTypes}
                    setValue={setValue}
                    value={watch('serviceTypeId')}
                    errors={errors}
                    disabled={updateMode}
                    setOnSave
                    onSaved={(savedOption) => {
                      /** fast magic */
                      serviceTypes.push(savedOption);
                    }}
                  />
                </CCol>

                <CCol xs="12" sm="6" md="4">
                  <UiInput
                    label={serviceTranslations.serviceTypeValue}
                    register={register}
                    name="serviceTypeValue"
                    errors={errors}
                    required
                    disabled={updateMode}
                  />
                </CCol>

                <CCol xs="12" sm="6" md="4">
                  <UiSelectClientContractModal
                    label={serviceTranslations.clientContract}
                    register={register}
                    name="clientContract"
                    options={clientContracts}
                    setValue={setValue}
                    value={watch('clientContract')}
                    errors={errors}
                    isLoading={clientContractsLoading}
                    setOnSave
                    disabled={updateMode}
                    modal={modal}
                  />
                </CCol>

                <CCol xs="12" sm="6" md="4" className="d-flex align-items-end">
                  <UiRowCheckbox
                    register={register}
                    name="isAgent"
                    label={serviceTranslations.isAgencyContract}
                    disabled={(customer?.isPartner ?? false) || updateMode}
                  />
                </CCol>

                <CCol xs="12" sm="6" md="4">
                  <UiSelectLegalPersonModal
                    label={serviceTranslations.agent}
                    register={register}
                    name="legalPersonAgent"
                    options={agents}
                    setValue={setValue}
                    value={watch('legalPersonAgent')}
                    errors={errors}
                    isLoading={legalPersonsFoundByCustomerIdLoading}
                    setOnSave
                    disabled={!watch('isAgent') || updateMode}
                    allowEmptyValue={!watch('isAgent')}
                    modal={modal}
                  />
                </CCol>

                <CCol xs="12" sm="6" md="4">
                  <UiSelectAgentContractModal
                    label={serviceTranslations.agencyContract}
                    register={register}
                    name="agencyContract"
                    options={agencyContracts}
                    setValue={setValue}
                    value={watch('agencyContract')}
                    errors={errors}
                    setOnSave
                    isLoading={agencyContractsLoading}
                    disabled={!watch('isAgent') || updateMode}
                    allowEmptyValue={!watch('isAgent')}
                    modal={modal}
                  />
                </CCol>

                <CCol xs="12" sm="6" md="4">
                  <UiSelectTariffModal
                    label={serviceTranslations.tariff}
                    register={register}
                    name="tariff"
                    options={tariffs}
                    setValue={setValue}
                    value={watch('tariff')}
                    isLoading={tariffsLoading}
                    errors={errors}
                    setOnSave
                    disabled={updateMode}
                    modal={modal}
                  />
                </CCol>

                <CCol xs="12" sm="6" md="4">
                  <UiSelect
                    label={customerTranslations.manager}
                    register={register}
                    name="manager"
                    options={managerList}
                    setValue={setValue}
                    value={watch('manager')}
                    errors={errors}
                    required
                    disabled={!(updateMode && isAdmin)}
                  />
                </CCol>
              </CRow>

              <CRow>
                <CCol xs="12" sm="6" md="4">
                  <UiSubmitButton saving={saveLoading} disabled={disabledToSave} />
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CForm>
  );
};
