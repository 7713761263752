import { SystemOrderFilter } from 'components/System/Order/filter';
import { SelectOptionsType } from 'components/UI/UiSelect';
import { UiTable } from 'components/UI/UiTable';
import { OrderListModel, OrderModel } from 'models/Order';
import React from 'react';
import { CCard, CCardBody } from '@coreui/react';

import { UiListBtnNew } from 'components/UI/UiListBtnNew';
import { systemOrderRoute } from 'containers/System/Order/routes';
import { getOrderList } from 'api/order';
import { Link } from 'react-router-dom';
import { orderTranslations } from 'translations/order';
import { formatDate } from 'utils/date';
import { useFilter } from 'utils/hooks/useFilter';
import { baseFinder } from 'utils/listHelpers';

type Props = {
  getData: typeof getOrderList;
  customerOptionsList: SelectOptionsType<number>;
  agencyList: SelectOptionsType<number>;
  managerList: SelectOptionsType<string>;
};

const fields = [
  { key: 'id', label: 'ID' },
  { key: 'numberOfOrder', label: orderTranslations.numberOfOrder },
  { key: 'nameCustomer', label: orderTranslations.customer },
  { key: 'namePerson', label: orderTranslations.legalPersonToClientContract },
  { key: 'contract', label: orderTranslations.clientContractToService },
  { key: 'startingDate', label: orderTranslations.startingDate },
  { key: 'completionDate', label: orderTranslations.completionDate },
  { key: 'nameService', label: orderTranslations.serviceId },
  { key: 'agencyLegalPersonName', label: orderTranslations.agent },
  { key: 'status', label: orderTranslations.status },
  { key: 'manager', label: orderTranslations.manager },
];

export const SystemOrderListComponent: React.FC<Props> = ({
  getData,
  customerOptionsList,
  managerList,
  agencyList,
}: Props) => {
  const { onPaginationChange, loading, model, filter, onReset, onSearch, onChangePage } = useFilter<OrderListModel>(
    getData
  );

  return (
    <CCard>
      <CCardBody>
        <h3 className="text-center">{orderTranslations.route.list}</h3>

        <UiListBtnNew title={orderTranslations.new} to={systemOrderRoute()} />

        <SystemOrderFilter
          onSearch={onSearch}
          onReset={onReset}
          customerList={customerOptionsList}
          managerList={managerList}
          agencyList={agencyList}
        />

        <UiTable
          items={model}
          fields={fields}
          loading={loading}
          pageLimit={filter.pageLimit}
          onChangePage={onChangePage}
          scopedSlots={{
            id: ({ id }: OrderModel) => (
              <td>
                <Link to={systemOrderRoute(id)}>{id}</Link>
              </td>
            ),
            contract: ({ numberOfContract, startingDateOfContract }: OrderModel) => (
              <td>
                {numberOfContract} от {formatDate(startingDateOfContract)}
              </td>
            ),
            startingDate: ({ startingDate, creationDate }: OrderModel) => (
              <td>{formatDate(startingDate || creationDate)}</td>
            ),
            completionDate: ({ completionDate }: OrderModel) => (
              <td>{completionDate ? formatDate(completionDate) : '-'}</td>
            ),
            status: ({ status }: OrderModel) => <td>{orderTranslations.orderStatus[status] || '-'}</td>,
            manager: ({ manager }: OrderModel) => <td>{baseFinder(managerList, manager)?.label}</td>,
            agencyLegalPersonName: ({ agencyLegalPersonName }: OrderModel) => <td>{agencyLegalPersonName}</td>,
          }}
          onPaginationChange={onPaginationChange}
        />
      </CCardBody>
    </CCard>
  );
};
