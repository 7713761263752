import { SelectOptionsType } from 'components/UI/UiSelect';
import { clientContractTranslations } from 'translations/clientContract';
import { baseFinder } from 'utils/listHelpers';

export type ClientContractMethodOfInvoiceType = 'prepaid' | 'postpaid';

/** типы договоров */
export const clientContractMethodOfInvoiceSelectOptions: SelectOptionsType<ClientContractMethodOfInvoiceType> = [
  {
    label: clientContractTranslations.methodOfInvoice.prepaid,
    value: 'prepaid',
  },
  {
    label: clientContractTranslations.methodOfInvoice.postpaid,
    value: 'postpaid',
  },
];

export const getClientContractMethodOfInvoice = (id: ClientContractMethodOfInvoiceType): string =>
  baseFinder(clientContractMethodOfInvoiceSelectOptions, id)?.label || 'Тип договора не найден';
