import { SelectOptionsType } from 'components/UI/UiSelect';
import { BaseFilterListType, BaseListModelType } from 'models/base';
import { CustomerModel } from 'models/Customer';
import { Optional } from 'utils/types';

export type LegalPersonRolesType = {
  isRecipient: boolean;
  isExecutor: boolean;
  isAgent: boolean;
  isNDSPayer: boolean;
};

export type OrganizationsType = 'LEGAL' | 'INDIVIDUAL';

export const OrganizationOptions: SelectOptionsType<OrganizationsType> = [
  {
    value: 'LEGAL',
    label: 'ЮЛ',
  },
  {
    value: 'INDIVIDUAL',
    label: 'ИП',
  },
];

export type InfoByInnResponseType = {
  kpp: string;
  organizationType: string;
  shortOrganizationName: string;
};

export type LegalPersonModel = {
  id: number;
  name: string;
  inn: number;
  kpp: number;
  customerId: number;
  customer: CustomerModel;
  manager: string;
  organizationType: 'LEGAL' | 'INDIVIDUAL';
} & LegalPersonRolesType;

export type LegalPersonFormModel = Omit<
  LegalPersonModel,
  'id' | 'manager' | 'customer' | 'isNDSPayer' | 'inn' | 'kpp'
> & {
  id?: number;
  isNDSPayer?: boolean; // не передавать, если =false
  inn?: number;
  kpp?: number;
  manager?: string;
};

export type LegalPersonListModel = BaseListModelType<LegalPersonModel>;

export type LegalPersonFilterType = Optional<
  BaseFilterListType &
    Omit<LegalPersonModel, 'id'> & {
      ids: number[];
      managers: string[];
    }
>;
