import { CButton, CCol, CRow } from '@coreui/react';
import React, { memo } from 'react';
import { Link } from 'react-router-dom';

type Props = {
  title: string;
  to: string;
};

export const UiListBtnNew = memo(
  ({ title, to }: Props): React.ReactElement => (
    <CRow className="justify-content-center mb-4 mt-3">
      <CCol xs={3}>
        <CButton tag={Link} block color="primary" to={to}>
          {title}
        </CButton>
      </CCol>
    </CRow>
  )
);
