import isEqual from 'lodash/isEqual';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { CCard, CCardBody, CCol, CForm, CRow } from '@coreui/react';

import { UiSelectLegalPersonModal } from 'components/UI/UiSelectLegalPersonModal';
import { UiSubmitButton } from 'components/UI/UiSubmitButton';
import { UiInput } from 'components/UI/UiInput';
import { tariffTranslations } from 'translations/tariff';
import { formatDate } from 'utils/date';
import { formatModelNameId } from 'utils/formatters';
import { SelectOptionsType } from 'components/UI/UiSelect';
import { UiTabs } from 'components/UI/UiTabs';
import { ProductModel, ProductTypesEnum } from 'models/Product';
import { SystemTariffProductTableComponent } from 'components/System/Tariff/table';
import { UiRowCheckbox } from 'components/UI/UiCheckbox';
import { TariffCreateType, TariffUpdateType, TariffModel } from 'models/Tariff';
import { prepareFormData } from 'utils/formFilter';
import { useProductSelectTable } from 'utils/hooks/product/tariff';
import { baseFinder } from 'utils/listHelpers';
import { UiSelectProductModal } from 'components/UI/UiSelectProductModal';

import {
  getLegalPersonAndTariffRequests,
  getTariffPositions,
  getTariffRights,
} from 'components/System/Tariff/formHelpers';

type Props = {
  model: TariffModel | null;
  executors: SelectOptionsType<number>;
  subscrProducts: ProductModel[];
  razProducts: ProductModel[];
  saveLoading: boolean;
  onSave: (data: TariffCreateType | TariffUpdateType) => void;
  modal: boolean;
  createBasedOn: boolean;
  nds: number;
};

type FormValues = {
  name: string;
  legalPersonIds: SelectOptionsType<number> | null;
  isArchive: boolean;
};

export const SystemTariffFormComponent = ({
  model,
  executors,
  saveLoading,
  subscrProducts,
  razProducts,
  onSave,
  modal,
  createBasedOn,
  nds,
}: Props): React.ReactElement => {
  const updateMode = !!model?.id;

  const initValues = useMemo(
    () => ({
      isArchive: model?.archivingDate !== null || false,
      name: model?.name,
      legalPersonIds: (() => {
        if (model?.legalPersonAndTariffs) {
          const arr = model.legalPersonAndTariffs.reduce<SelectOptionsType<number>>((acc, { legalPersonId }) => {
            const found = baseFinder(executors, legalPersonId);

            if (found) {
              acc.push(found);
            }
            return acc;
          }, []);
          return arr.length ? arr : null;
        }
        return null;
      })(),
    }),
    []
  );

  const { register, handleSubmit, errors, setValue, watch } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: initValues,
  });

  const rights = getTariffRights(updateMode, createBasedOn);
  const {
    onAddFromSelect,
    subscrProductsOptions,
    subscrProductsSelected,
    razProductsOptions,
    razProductsSelected,
    subscrSelected,
    razSelected,
    onRemove,
    onNDSChange,
  } = useProductSelectTable(subscrProducts, razProducts, model?.tariffPositions);

  const disabledToSave = (() => {
    if (updateMode) {
      const { legalPersonIds, ...initVals } = initValues;

      const subscrTariffPositionsInfo = getTariffPositions(
        subscrSelected,
        model?.tariffPositions.filter(({ product: { type } }) => type === ProductTypesEnum.SUBSCRIPTION)
      );
      const lumpSumTariffPositionsInfo = getTariffPositions(
        razSelected,
        model?.tariffPositions.filter(({ product: { type } }) => type === ProductTypesEnum.LUMP_SUM)
      );

      return isEqual(
        {
          ...initVals,
          legalPersonIds: legalPersonIds?.map(({ value }) => value).join(),
          tariffPositions: model?.tariffPositions
            .filter(({ product: { type } }) => type === ProductTypesEnum.SUBSCRIPTION)
            .map(({ priceWithNds, priceWithoutNds, productId }) => ({
              priceWithNds,
              priceWithoutNds,
              productId,
            })),
          lumpSumPositions: model?.tariffPositions
            .filter(({ product: { type } }) => type === ProductTypesEnum.LUMP_SUM)
            .map(({ priceWithNds, priceWithoutNds, productId }) => ({
              priceWithNds,
              priceWithoutNds,
              productId,
            })),
        },
        {
          isArchive: watch('isArchive'),
          name: watch('name'),
          legalPersonIds: watch('legalPersonIds')
            ?.map(({ value }) => value)
            .join(),
          tariffPositions: subscrTariffPositionsInfo.actual,
          lumpSumPositions: lumpSumTariffPositionsInfo.actual,
        }
      );
    }
    return false;
  })();

  const onSubmit = (data: FormValues) => {
    if (disabledToSave) {
      return;
    }
    const creationTariffPositionRequests = [...subscrSelected, ...razSelected];

    /** есть ли незаполненные данные ндс во всех услугах */
    const isEmptyNds = creationTariffPositionRequests.find(
      ({ priceWithNds, priceWithoutNds }) => !priceWithNds || !priceWithoutNds
    );

    if (isEmptyNds) {
      toast.warning(tariffTranslations.ndsNeeded);
    } else if (data.legalPersonIds) {
      const legalPersonsInfo = getLegalPersonAndTariffRequests(data.legalPersonIds, model?.legalPersonAndTariffs);
      const subscrTariffPositionsInfo = getTariffPositions(
        subscrSelected,
        model?.tariffPositions.filter(({ product: { type } }) => type === ProductTypesEnum.SUBSCRIPTION)
      );
      const lumpSumTariffPositionsInfo = getTariffPositions(
        razSelected,
        model?.tariffPositions.filter(({ product: { type } }) => type === ProductTypesEnum.LUMP_SUM)
      );

      if (model?.id && !createBasedOn) {
        // редактирование
        const updateObj: TariffUpdateType = {
          id: model.id,
          isArchive: data.isArchive,
          updatingLegalPersonAndTariffs: legalPersonsInfo.filter(({ isAllow }) => isAllow),
          updatingSubscriptionTariffPositions: subscrTariffPositionsInfo.actual,
          updatingLumpSumTariffPositions: lumpSumTariffPositionsInfo.actual,
          deletingLegalPersonAndTariffIds: legalPersonsInfo
            .filter(({ isAllow }) => !isAllow)
            .map(({ legalPersonId }) => legalPersonId),
          deletingSubscriptionTariffPositionIds: subscrTariffPositionsInfo.forDelete,
          deletingLumpSumTariffPositionIds: lumpSumTariffPositionsInfo.forDelete,
        };

        onSave(prepareFormData(updateObj));
      } else {
        // создание
        const createObj: TariffCreateType = {
          name: data.name,
          isArchive: data.isArchive,
          creationTariffPositionRequests: getTariffPositions(creationTariffPositionRequests).actual,
          creationLegalPersonAndTariffRequests: legalPersonsInfo,
          archivingDate: null,
        };

        onSave(prepareFormData(createObj));
      }
    }
  };

  return (
    <CForm onSubmit={handleSubmit(onSubmit)}>
      <CCard>
        <CCardBody>
          <CRow>
            <CCol xs={12}>
              {!modal && !createBasedOn && (
                <h3>{model?.id ? formatModelNameId(model.name, model.id) : tariffTranslations.route.create}</h3>
              )}
              {createBasedOn && <h3>Cоздание на основании существующего тарифа (ID: {model?.id})</h3>}

              <CRow>
                <CCol xs="12" sm="6" md="3">
                  <UiInput
                    register={register}
                    name="name"
                    label={tariffTranslations.name}
                    errors={errors}
                    required
                    disabled={rights.name}
                  />
                </CCol>
                <CCol xs="12" sm="6" md="3">
                  <UiSelectLegalPersonModal
                    register={register}
                    name="legalPersonIds"
                    label={tariffTranslations.legalPersons}
                    errors={errors}
                    options={executors}
                    setValue={setValue}
                    value={watch('legalPersonIds')}
                    multiple
                    setOnSave
                    disabled={rights.legalPersonIds}
                  />
                </CCol>
                <CCol xs="12" sm="6" md="2" className="mt-4">
                  <UiRowCheckbox
                    className="mt-2"
                    register={register}
                    name="isArchive"
                    label={tariffTranslations.isArchive}
                    disabled={rights.isArchive}
                  />
                </CCol>
                {model?.archivingDate && watch('isArchive') && (
                  <CCol xs="12" sm="6" md="3" className="mt-4">
                    Дата архивации
                    <div>{formatDate(model.archivingDate, 'dd.MM.yyyy kk:mm:ss', false)}</div>
                  </CCol>
                )}
              </CRow>
            </CCol>
          </CRow>

          <UiTabs
            items={{
              abonementProducts: {
                title: tariffTranslations.abonementProducts,
                component: () => (
                  <>
                    <UiSelectProductModal
                      register={register}
                      name="abonementProducts"
                      label={tariffTranslations.product}
                      errors={errors}
                      options={subscrProductsOptions}
                      setValue={setValue}
                      value={watch('abonementProducts')}
                      onChange={onAddFromSelect(ProductTypesEnum.SUBSCRIPTION)}
                      onChangeNew={(newVal) => {
                        subscrProducts.push(newVal); // мутация для пересчета массива
                      }}
                      disabled={rights.abonementProducts}
                    />
                    <SystemTariffProductTableComponent
                      items={subscrProductsSelected}
                      onRemove={onRemove(ProductTypesEnum.SUBSCRIPTION)}
                      onNDSChange={onNDSChange(ProductTypesEnum.SUBSCRIPTION)}
                      ndsInfo={subscrSelected}
                      nds={nds}
                      disabledRemove={rights.abonementProductsRemove}
                    />
                  </>
                ),
              },
              razProducts: {
                title: tariffTranslations.razProducts,
                component: () => (
                  <>
                    <UiSelectProductModal
                      register={register}
                      name="razProducts"
                      label={tariffTranslations.product}
                      errors={errors}
                      options={razProductsOptions}
                      setValue={setValue}
                      value={watch('razProducts')}
                      onChange={onAddFromSelect(ProductTypesEnum.LUMP_SUM)}
                      onChangeNew={(newVal) => {
                        razProducts.push(newVal); // мутация для пересчета массива
                      }}
                      disabled={rights.razProducts}
                    />
                    <SystemTariffProductTableComponent
                      items={razProductsSelected}
                      onRemove={onRemove(ProductTypesEnum.LUMP_SUM)}
                      onNDSChange={onNDSChange(ProductTypesEnum.LUMP_SUM)}
                      ndsInfo={razSelected}
                      nds={nds}
                      disabledRemove={rights.razProductsRemove}
                    />
                  </>
                ),
              },
            }}
          />

          <UiSubmitButton
            saving={saveLoading}
            disabled={(subscrSelected.length === 0 && razSelected.length === 0) || disabledToSave}
          />
        </CCardBody>
      </CCard>
    </CForm>
  );
};
