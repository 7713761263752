import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { API_URL } from 'config';

import { toast } from 'react-toastify';
import { customHistory } from 'utils/customHistory';

axios.defaults.baseURL = API_URL;
axios.defaults.withCredentials = true;

axios.interceptors.response.use(
  ({ data }) => data,
  (error: AxiosError) => {
    // eslint-disable-next-line no-console
    console.error('http Error:', error);

    if (error.response?.status === 401) {
      customHistory.push('/login');
    } else if (error.response?.data) {
      try {
        toast.error(error.response.data.message);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    }

    return Promise.reject(error);
  }
);

type httpGetType = <R>(path: string, params?: any, data?: AxiosRequestConfig) => Promise<R>;
type httpPostType = <R>(path: string, data?: any, headers?: Record<string, string>) => Promise<R>;
type httpPutType = <R>(path: string, data?: any, headers?: Record<string, string>) => Promise<R>;

export const httpGet: httpGetType = (path: string, params = {}, config = {}) =>
  axios.get(path, {
    params,
    ...config,
  });

export const httpPost: httpPostType = (path: string, data = {}, headers = {}) =>
  axios.post(path, data, {
    headers: { ...headers },
  });

export const httpPut: httpPutType = (path: string, data = {}, headers = {}) =>
  axios.put(path, data, {
    headers: { ...headers },
  });
