import { CButton } from '@coreui/react';
import React from 'react';

type Props = {
  saving: boolean;

  disabled?: boolean;
};

export const UiSubmitButton = ({ saving, disabled }: Props): React.ReactElement => (
  <CButton block color="primary" type="submit" disabled={saving || disabled}>
    {saving ? 'Идет сохранение' : 'Сохранить'}
  </CButton>
);
