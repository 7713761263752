import { maxValueToGetAllDataWithPagination } from 'config';
import {
  LegalPersonFilterType,
  LegalPersonModel,
  LegalPersonListModel,
  LegalPersonFormModel,
  InfoByInnResponseType,
} from 'models/LegalPerson';
import { httpGet, httpPost, httpPut } from 'utils/http';

const path = '/admin/persons';

/** создать юр.лицо */
export const createLegalPerson = (model: LegalPersonFormModel): Promise<LegalPersonModel> =>
  httpPost(`${path}/person`, model);

/** обновить юр.лицо */
export const updateLegalPerson = (id: number, model: LegalPersonFormModel): Promise<LegalPersonModel> =>
  httpPut(`${path}/person/${id}`, model);

/** получить юр.лицо */
export const getLegalPerson = (id: number): Promise<LegalPersonModel> => httpGet(`${path}/person/${id}`);

/** получить список юр.лиц */
export const getLegalPersonsList = (filter?: LegalPersonFilterType): Promise<LegalPersonListModel> =>
  httpGet(`${path}/filter`, filter);

/** получить список всех юр.лиц */
export const getAllLegalPersonsList = (filter?: LegalPersonFilterType): Promise<LegalPersonListModel> =>
  getLegalPersonsList({ ...filter, pageLimit: maxValueToGetAllDataWithPagination });

/** проверить пару инн-кпп через список юр.лиц */
export const checkInnKppLegalPerson = (inn: number, kpp: number): Promise<LegalPersonListModel> =>
  getLegalPersonsList({ inn, kpp });

/** получить инфу по инн */
export const getDataByInn = (inn: number): Promise<InfoByInnResponseType> =>
  httpGet(`${path}/dadata/info-by-inn/${inn}`);
