import React, { useCallback, useEffect, useState } from 'react';

import { getAllLegalPersonsList } from 'api/legalPerson';
import { SelectOptionsType } from 'components/UI/UiSelect';
import { getClientContractList } from 'api/clientContract';
import { SystemClientContractComponent } from 'components/System/ClientContract/list';
import { doRequest } from 'utils/doRequest';
import { useAllManagers } from 'utils/hooks/useAllManagers';

let mounted = false;

type Props = {
  lhsLegalPersonId?: number; // поиск по конкретному юр.лицу (исполнитель)
  rhsLegalPersonId?: number; // поиск по конкретному юр.лицу (получатель)
  children?: React.ReactNode | React.ReactNode[]; // доп. инфа для вывода
};

export const SystemClientContractList = ({
  lhsLegalPersonId,
  rhsLegalPersonId,
  children,
}: Props): React.ReactElement => {
  useEffect(() => {
    mounted = true;
    return () => {
      mounted = false;
    };
  }, []);

  const { managersListSelectOptions, loading: managersLoading } = useAllManagers();

  const [legalPersonsExecutorSelectOptions, setLegalPersonsExecutor] = useState<SelectOptionsType<number>>([]);
  const [legalPersonsRecipientSelectOptions, setLegalPersonsRecipient] = useState<SelectOptionsType<number>>([]);
  const [legalPersonsAllOptions, setLegalPersonsAllOptions] = useState<SelectOptionsType<number>>([]);
  const [legalPersonsExecutorLoading, setLegalPersonsExecutorLoading] = useState(true);

  useEffect(() => {
    doRequest(getAllLegalPersonsList, () => mounted)(
      ({ data }) => {
        setLegalPersonsExecutor(
          data.filter(({ isExecutor }) => isExecutor).map(({ name, id }) => ({ value: id, label: name }))
        );
        setLegalPersonsRecipient(
          data.filter(({ isRecipient }) => isRecipient).map(({ name, id }) => ({ value: id, label: name }))
        );
        setLegalPersonsAllOptions(data.map(({ name, id }) => ({ value: id, label: name })));
      },
      () => {
        setLegalPersonsExecutorLoading(false);
      }
    );
  }, []);

  const getData = useCallback((filter) => getClientContractList({ rhsLegalPersonId, lhsLegalPersonId, ...filter }), [
    rhsLegalPersonId,
    lhsLegalPersonId,
  ]);

  return (
    <SystemClientContractComponent
      getData={getData}
      legalPersonsExecutorSelectOptions={legalPersonsExecutorSelectOptions}
      legalPersonsRecipientSelectOptions={legalPersonsRecipientSelectOptions}
      legalPersonsAllOptions={legalPersonsAllOptions}
      managersListSelectOptions={managersListSelectOptions}
      loadingFilterData={managersLoading || legalPersonsExecutorLoading}
      simple={Boolean(lhsLegalPersonId) || Boolean(rhsLegalPersonId)}
      addInfo={children}
    />
  );
};
