import React, { useEffect, useState } from 'react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import { Control, Controller } from 'react-hook-form';
import { CFormGroup, CLabel } from '@coreui/react';
import { formatCurrency } from 'utils/formatters';

type Props = {
  control: Control;
  watch: (v: string) => any;
  name: string;
  errors: { [s: string]: any };
  label?: string | React.ReactNode;
  id?: string;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  validation?: any;
  initClassName?: string;
  required?: boolean; // сокращение от validation={{ required: true }}
  min?: number | boolean; // сокращение от validation={{ min }}
  max?: number; // максимальное конкретное значение. проверяется при вводе
  maxLength?: number; // максимальная длина числа до точки(целое). проверяется при вводе
  allowNegative?: boolean;
  allowDecimals?: boolean; // разрешать ввод дробных значений
  decimalScale?: number; // кол-во знаков после запятой
  system?: boolean;
};

export const UiFormatNumber: React.FC<Props> = ({
  control,
  watch,
  name,
  errors,
  label = null,
  id,
  disabled = false,
  placeholder,
  className,
  validation = {},
  initClassName = '',
  required = false,
  min = false,
  max,
  maxLength = 10,
  allowNegative = false,
  allowDecimals = true,
  decimalScale = 2,
  system,
}: Props) => {
  const [active, setActive] = useState(false);
  const error = errors[name];

  /** определение/сбор класса */
  const formGroupClassName = [initClassName];
  if (active || watch(`${name}`)) {
    formGroupClassName.push('value');
  }
  if (className) {
    formGroupClassName.push(className);
  }
  if (!isEmpty(error)) {
    formGroupClassName.push('has-danger');
  }

  const localValidation = { ...validation };
  if (required) {
    localValidation.required = 'Обязательное поле';
    formGroupClassName.push('required');
  }

  if (isNumber(min)) {
    localValidation.min = {
      value: min,
      message: `Значение должно быть минимум ${min}`,
    };
  }

  return (
    <>
      <CFormGroup className={formGroupClassName.filter((cName) => cName).join(' ')}>
        {label && <CLabel htmlFor={id}>{label}</CLabel>}
        <Controller
          control={control}
          decimalScale={decimalScale}
          thousandSeparator=" "
          allowNegative={allowNegative}
          decimalSeparator=","
          as={
            <NumberFormat
              isAllowed={({ floatValue, value: val }) => {
                const allowedErrors = [];
                if (!allowDecimals && val.indexOf('.') !== -1) {
                  return false;
                }
                if (maxLength && val.split('.')[0].length > maxLength) {
                  allowedErrors.push('maxLength');
                }
                if (max && floatValue && max && floatValue > max) {
                  allowedErrors.push('max');
                }
                return !allowedErrors.length;
              }}
            />
          }
          name={name}
          rules={localValidation}
          placeholder={placeholder}
          id={id}
          onFocus={() => setActive(true)}
          onBlur={() => setActive(false)}
          required={required}
          disabled={disabled}
          className="form-control"
        />
        {!system && <hr className="input-hr" />}
        {error && (
          <div className={`form-control-feedback m-0${system ? ' form-error-block' : ''}`}>{error.message}</div>
        )}
      </CFormGroup>
    </>
  );
};

export const prepareFormatValueToNumber = (v: string | number): number =>
  +v.toString().replace(/\s+/gm, '').replace(',', '.');

type RawProps = {
  onValueChange: (v: NumberFormatValues) => void;
  value?: number;
  maxLength?: number;
};
export const RawUiFormatNumber: React.FC<RawProps> = ({ onValueChange, value, maxLength = 10 }: RawProps) => {
  const [valForWaiting, setValForWaiting] = useState<NumberFormatValues>({
    floatValue: undefined,
    formattedValue: '',
    value: '',
  });

  const [localValue, setLocalValue] = useState<string | number | undefined>(value);

  useEffect(() => {
    setLocalValue(value ? +value : '');

    if (value) {
      setValForWaiting({
        floatValue: Number((+value).toFixed(2)),
        formattedValue: formatCurrency(value),
        value: formatCurrency(value),
      });
    }
  }, [value]);

  return (
    <NumberFormat
      className="form-control"
      thousandSeparator=" "
      allowNegative={false}
      decimalScale={2}
      decimalSeparator=","
      isAllowed={({ value: val }) => {
        const allowedErrors = [];

        if (maxLength && val.split('.')[0].length > maxLength) {
          allowedErrors.push('maxLength');
        }
        return !allowedErrors.length;
      }}
      onValueChange={(e: NumberFormatValues) => {
        setValForWaiting(e);
        setLocalValue(e.floatValue);
      }}
      isNumericString
      onBlur={() => {
        // лучший вариант, без рекурсивного зацикливания при изменении
        onValueChange(valForWaiting);
      }}
      value={localValue ? (+localValue).toFixed(2) : localValue}
    />
  );
};
