import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { UiLoader } from 'components/UI/UiLoader';
import { getProfileOkAction, getProfileStartAction } from 'store/actions';
import { getProfileSelector } from 'store/selectors';
import { TheLayout } from 'components/Layout/TheLayout';
import { getMe } from 'api/auth';
import { doRequest } from 'utils/doRequest';

let mounted = false;

export const SystemIndex = (): React.ReactElement => {
  useEffect(() => {
    mounted = true;
    return () => {
      mounted = false;
    };
  }, []);

  const dispatch = useDispatch();
  const { loading } = useSelector(getProfileSelector);

  useEffect(() => {
    dispatch(getProfileStartAction());

    doRequest(
      getMe,
      () => mounted
    )((profile) => {
      dispatch(getProfileOkAction(profile));
    });
  }, []);

  return loading ? <UiLoader /> : <TheLayout />;
};
