import { SelectOptionsType } from 'components/UI/UiSelect';
import { BaseFilterListType, BaseListModelType } from 'models/base';
import { baseFinder } from 'utils/listHelpers';
import { Optional } from 'utils/types';

// eslint-disable-next-line no-shadow
export enum ProductTypesEnum {
  SUBSCRIPTION = 'subscription',
  LUMP_SUM = 'lump_sum',
  TARIFFABLE = 'tariffable',
}

export const productTypesSelectOptions: SelectOptionsType<ProductTypesEnum> = [
  {
    label: 'Абонементная',
    value: ProductTypesEnum.SUBSCRIPTION,
  },
  {
    label: 'Единовременная',
    value: ProductTypesEnum.LUMP_SUM,
  },
  {
    label: 'Тарифицируемая',
    value: ProductTypesEnum.TARIFFABLE,
  },
];
export const getProductType = (type: ProductTypesEnum): string =>
  baseFinder(productTypesSelectOptions, type)?.label || 'Тип не найден';

export type ProductModel = {
  costPrice: number;
  id: number;
  name: string;
  type: ProductTypesEnum;
  unitOfMeasure: string;
};

/** расширенная модель с доп. данными */
export type ExtendedProductModel = ProductModel & {
  tariffPositionId: number;
  priceWithNds: number;
  priceWithoutNds: number;
  volume: number | null;
  discount: number | null;
  percentageOfAgencyRemuneration: number | null;
  isInvoiceCreated: boolean | null;
  providingDate: number | null;
};

export type ProductCreateType = Omit<ProductModel, 'id'>;

export type ProductUpdateType = {
  name?: string;
};

export type ProductListModel = BaseListModelType<ProductModel>;

export type ProductListFilterType = Optional<
  BaseFilterListType &
    Omit<ProductModel, 'id' | 'type' | 'costPrice'> & {
      ids: number[];
      types: ProductTypesEnum[];
      minCostPrice: number;
      maxCostPrice: number;
    }
>;
