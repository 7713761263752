import { SystemServiceTypeForm } from 'containers/System/ServiceType/form';
import React from 'react';

import { SystemProductList } from 'containers/System/Product/list';
import { SystemProductForm } from 'containers/System/Product/form';
import { systemProductListRoute, systemProductRoute } from 'containers/System/Product/routes';
import { SystemServiceForm } from 'containers/System/Service/form';
import { SystemServiceList } from 'containers/System/Service/list';
import { systemServiceListRoute, systemServiceRoute } from 'containers/System/Service/routes';
import { SystemAgenctContractForm } from 'containers/System/AgencyContract/form';
import { SystemAgencyContractList } from 'containers/System/AgencyContract/list';
import { systemAgencyContractListRoute, systemAgencyContractRoute } from 'containers/System/AgencyContract/routes';
import { SystemClientContractForm } from 'containers/System/ClientContract/form';
import { systemClientContractListRoute, systemClientContractRoute } from 'containers/System/ClientContract/routes';
import { systemCustomerListRoute, systemCustomerRoute } from 'containers/System/Customer/routes';
import { systemLegalPersonListRoute, systemLegalPersonRoute } from 'containers/System/LegalPerson/routes';
import { SystemLegalPersonForm } from 'containers/System/LegalPerson/form';
import { SystemLegalPersonList } from 'containers/System/LegalPerson/list';
import { SystemCustomersList } from 'containers/System/Customer/list';
import { SystemCustomerForm } from 'containers/System/Customer/form';
import { agencyContractTranslations } from 'translations/agencyContract';
import { clientContractTranslations } from 'translations/clientContract';
import { customerTranslations } from 'translations/customer';
import { invoiceForAgencyTranslations } from 'translations/InvoiceForAgency';
import { invoiceOnOrderTranslations } from 'translations/invoiceOnOrder';
import { legalPersonTranslations } from 'translations/legalPerson';
import { SystemClientContractList } from 'containers/System/ClientContract/list';
import { orderTranslations } from 'translations/order';
import { productTranslations } from 'translations/product';
import { serviceTranslations } from 'translations/service';
import { serviceTypeTranslations } from 'translations/serviceType';
import { tariffTranslations } from 'translations/tariff';
import { systemTariffBasedOnRoute, systemTariffListRoute, systemTariffRoute } from 'containers/System/Tariff/routes';
import { SystemTariffList } from 'containers/System/Tariff/list';
import { SystemTariffForm } from 'containers/System/Tariff/form';
import { SystemOrderForm } from 'containers/System/Order/form';
import { SystemOrderList } from 'containers/System/Order/list';
import { systemOrderListRoute, systemOrderRoute } from 'containers/System/Order/routes';
import { systemInvoiceOnOrderListRoute, systemInvoiceOnOrderRoute } from 'containers/System/InvoiceOnOrder/routes';
import { SystemInvoiceOnOrderList } from 'containers/System/InvoiceOnOrder/list';
import { SystemInvoiceForAgencyList } from 'containers/System/invoiceForAgency/list';
import { systemInvoceAgencyListRoute, systemInvoceAgencyRoute } from 'containers/System/invoiceForAgency/routes';
import { SystemInvoiceOnOrderForm } from 'containers/System/InvoiceOnOrder/form';
import { SystemInvoiceForAgencyForm } from 'containers/System/invoiceForAgency/form';
import { SystemServiceTypeList } from 'containers/System/ServiceType/list';
import { systemServiceTypeListRoute, systemServiceTypeRoute } from 'containers/System/ServiceType/routes';

export const getSystemRoute = (path = ''): string => `/system${path}`;

type CRoute = {
  path: string;
  component: React.ComponentType;

  exact?: boolean;
  name?: string;
  noLayout?: boolean;
};

type CRoutes = CRoute[];

const generateRoute = (path: string, name: string, component: React.ComponentType, exact = true) => ({
  path,
  name,
  exact,
  component,
});

export const routes: CRoutes = [
  generateRoute(systemCustomerListRoute(), customerTranslations.route.list, SystemCustomersList),
  generateRoute(systemCustomerRoute(), customerTranslations.route.create, () => <SystemCustomerForm modal={false} />),
  generateRoute(systemCustomerRoute(':id'), customerTranslations.route.update, () => (
    <SystemCustomerForm modal={false} />
  )),

  generateRoute(systemLegalPersonListRoute(), legalPersonTranslations.route.list, SystemLegalPersonList),
  generateRoute(systemLegalPersonRoute(), legalPersonTranslations.route.create, () => (
    <SystemLegalPersonForm modal={false} legalTwo={false} />
  )),
  generateRoute(systemLegalPersonRoute(':id'), legalPersonTranslations.route.update, () => (
    <SystemLegalPersonForm modal={false} legalTwo={false} />
  )),

  generateRoute(systemClientContractListRoute(), clientContractTranslations.route.list, SystemClientContractList),
  generateRoute(systemClientContractRoute(), clientContractTranslations.route.create, () => (
    <SystemClientContractForm />
  )),
  generateRoute(systemClientContractRoute(':id'), clientContractTranslations.route.update, () => (
    <SystemClientContractForm />
  )),

  generateRoute(systemAgencyContractListRoute(), agencyContractTranslations.route.list, SystemAgencyContractList),
  generateRoute(systemAgencyContractRoute(), agencyContractTranslations.route.create, () => (
    <SystemAgenctContractForm />
  )),
  generateRoute(systemAgencyContractRoute(':id'), agencyContractTranslations.route.update, () => (
    <SystemAgenctContractForm />
  )),

  generateRoute(systemServiceListRoute(), serviceTranslations.route.list, SystemServiceList),
  generateRoute(systemServiceRoute(), serviceTranslations.route.create, () => <SystemServiceForm modal={false} />),
  generateRoute(systemServiceRoute(':id'), serviceTranslations.route.update, () => <SystemServiceForm modal={false} />),

  generateRoute(systemProductListRoute(), productTranslations.route.list, SystemProductList),
  generateRoute(systemProductRoute(), productTranslations.route.create, () => <SystemProductForm modal={false} />),
  generateRoute(systemProductRoute(':id'), productTranslations.route.update, () => <SystemProductForm modal={false} />),

  generateRoute(systemTariffListRoute(), tariffTranslations.route.list, SystemTariffList),
  generateRoute(systemTariffRoute(), tariffTranslations.route.create, () => <SystemTariffForm modal={false} />),
  generateRoute(systemTariffRoute(':id'), tariffTranslations.route.update, () => <SystemTariffForm modal={false} />),
  generateRoute(systemTariffBasedOnRoute(':id'), tariffTranslations.route.createBasedOn, () => (
    <SystemTariffForm modal={false} />
  )),

  generateRoute(systemOrderListRoute(), orderTranslations.route.list, SystemOrderList),
  generateRoute(systemOrderRoute(), orderTranslations.route.create, SystemOrderForm),
  generateRoute(systemOrderRoute(':id'), orderTranslations.route.update, SystemOrderForm),

  generateRoute(systemInvoiceOnOrderListRoute(), invoiceOnOrderTranslations.route.list, SystemInvoiceOnOrderList),
  generateRoute(systemInvoiceOnOrderRoute(), invoiceOnOrderTranslations.route.view, SystemInvoiceOnOrderForm),

  generateRoute(systemInvoceAgencyListRoute(), invoiceForAgencyTranslations.route.list, SystemInvoiceForAgencyList),
  generateRoute(systemInvoceAgencyRoute(), invoiceForAgencyTranslations.route.view, SystemInvoiceForAgencyForm),

  generateRoute(systemServiceTypeListRoute(), serviceTypeTranslations.route.list, SystemServiceTypeList),
  generateRoute(systemServiceTypeRoute(), serviceTypeTranslations.route.create, SystemServiceTypeForm),
  generateRoute(systemServiceTypeRoute(':id'), serviceTypeTranslations.route.update, SystemServiceTypeForm),
];
