import React, { memo, useCallback } from 'react';
import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CImg } from '@coreui/react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { getProfileSelector } from 'store/selectors';
import { logout } from 'api/auth';

const TheHeaderDropdown = memo((): React.ReactElement | null => {
  const {
    loading,
    data: {
      account: { name },
    },
  } = useSelector(getProfileSelector);

  const logoutFn = useCallback(() => {
    logout().catch((e) => {
      // eslint-disable-next-line no-console
      console.error(e);
      toast.error('Не удалось выйти');
    });
  }, []);

  return loading ? null : (
    <CDropdown inNav className="c-header-nav-items mx-2 mr-5">
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <div className="c-avatar">
          {name}&nbsp;
          <CImg src="/assets/img/profile-user.svg" className="c-avatar-img" alt={name} />
        </div>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownItem header tag="div" color="light" className="text-center">
          <strong>{name}</strong>
        </CDropdownItem>
        <CDropdownItem onClick={logoutFn}>Выйти</CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
});

export default TheHeaderDropdown;
