import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import { Provider } from 'react-redux';
import { ToastContainer, Zoom } from 'react-toastify';
import { Switch, Route, Router, Redirect } from 'react-router-dom';

import { configureStore } from 'store/configureStore';
import { customHistory } from 'utils/customHistory';

import { LoginPage } from 'containers/Login';
import { SystemIndex } from 'containers/System/Index';

export const store = configureStore();

export const App = (): React.ReactElement => (
  <Provider store={store}>
    <ToastContainer position="top-center" autoClose={5000} transition={Zoom} draggable={false} />
    <Router history={customHistory}>
      <Switch>
        <Route exact path="/login" component={LoginPage} />
        <Route path="/system" component={SystemIndex} />
        <Redirect to="/system" />
      </Switch>
    </Router>
  </Provider>
);
