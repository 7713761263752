import { createCustomer, getAllCustomersList } from 'api/customer';
import { SelectOptionsType } from 'components/UI/UiSelect';
import isFunction from 'lodash/isFunction';
import isNaN from 'lodash/isNaN';
import toNumber from 'lodash/toNumber';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { UiLoader } from 'components/UI/UiLoader';
import { checkInnKppLegalPerson, createLegalPerson, getLegalPerson, updateLegalPerson } from 'api/legalPerson';
import { SystemLegalPersonFormComponent } from 'components/System/LegalPerson/form';
import { addLegalPersons2Action, addLegalPersonsAction } from 'store/actions';
import { legalPersonTranslations } from 'translations/legalPerson';
import { doRequest } from 'utils/doRequest';
import { LegalPersonFormModel, LegalPersonModel } from 'models/LegalPerson';
import { getClientContractListByLegalPersonId } from 'api/clientContract';
import { useAllManagers } from 'utils/hooks/useAllManagers';
import { ModalEntityProps } from 'utils/types';

let mounted = false;

export const SystemLegalPersonForm = ({
  modal = false,
  closeModal,
  onSaved,
  legalTwo,
}: ModalEntityProps & { legalTwo: boolean }): React.ReactElement => {
  useEffect(() => {
    mounted = true;
    return () => {
      mounted = false;
    };
  }, []);

  const { id } = useParams<{ id?: string }>();
  const numberId = toNumber(id);

  const { managersListSelectOptions, loading: loadManagers } = useAllManagers();

  const [model, setModel] = useState<LegalPersonModel | null>(null);
  const [clientContractsAmount, setClientContractsAmount] = useState<{ lhs: number; rhs: number }>({ lhs: 0, rhs: 0 });
  const [modelLoading, setModelLoading] = useState(false);
  /**
   * загрузить модель, если указан id
   *
   * + проверить наличие клиентских договоров, т.к. от них зависит возможность редактирования кпп и инн
   */
  useEffect(() => {
    if (!(isNaN(numberId) || modal)) {
      setModelLoading(true);

      doRequest(
        () => Promise.all([getLegalPerson(numberId), getClientContractListByLegalPersonId(numberId)]),
        () => mounted
      )(
        ([response, contracts]) => {
          setModel(response);
          setClientContractsAmount({ lhs: contracts[0].data.length, rhs: contracts[1].data.length });
        },
        () => {
          setModelLoading(false);
        }
      );
    }
  }, [numberId, modal]);

  const [customerList, setCustomerList] = useState<SelectOptionsType<number>>([]);
  const [customerListLoading, setCustomerListLoading] = useState(true);

  useEffect(() => {
    doRequest(getAllCustomersList, () => true)(
      ({ data }) => {
        setCustomerList(data.map((m) => ({ value: m.id, label: m.name })));
      },
      () => {
        setCustomerListLoading(false);
      }
    );
  }, []);

  const [saveLoading, setSaveLoading] = useState(false);

  const dispatch = useDispatch();

  const [key, setKey] = useState(1);

  const onSave = useCallback(
    async (data: LegalPersonFormModel, createCustomerBeforeSave: boolean) => {
      setSaveLoading(true);
      const updateMode = data.id;

      let isUnique = true;

      /** проверка кпп и инн на уникальность (найти все и отсеять текущий ид) */
      if (data.inn && data.kpp) {
        isUnique = await checkInnKppLegalPerson(data.inn, data.kpp)
          .then((checkedData) => {
            const isUniqueRes = checkedData.data.filter((m) => m.inn !== data.inn && m.kpp !== data.kpp).length === 0;
            if (!isUniqueRes) {
              toast.warn(legalPersonTranslations.notUniqueKppInn);
            }
            return isUniqueRes;
          })
          .catch(() => true);
      }

      if (isUnique) {
        if (createCustomerBeforeSave) {
          const responseCreateCustomer = await createCustomer({
            name: data.name,
            isPartner: false,
          });

          // eslint-disable-next-line no-param-reassign
          data.customerId = responseCreateCustomer.id;
        }

        const apiFn = updateMode ? () => updateLegalPerson(data.id as number, data) : () => createLegalPerson(data);

        doRequest(apiFn, () => mounted)(
          (savedItem) => {
            if (modal) {
              if (isFunction(closeModal)) {
                closeModal();
              }

              if (isFunction(onSaved)) {
                onSaved(savedItem);
              }
            }

            dispatch(legalTwo ? addLegalPersons2Action(savedItem) : addLegalPersonsAction(savedItem));

            setModel(savedItem);
            setKey((k) => k + 1);

            toast.success(updateMode ? legalPersonTranslations.updated : legalPersonTranslations.created);
          },
          () => {
            setSaveLoading(false);
          }
        );
      }
      setSaveLoading(false);
    },
    [mounted, model]
  );

  return customerListLoading || modelLoading || loadManagers ? (
    <UiLoader />
  ) : (
    <SystemLegalPersonFormComponent
      key={key}
      saveLoading={saveLoading}
      onSave={onSave}
      model={model}
      clientContractsAmount={clientContractsAmount}
      customerList={customerList} // список заказчиков
      managerList={managersListSelectOptions}
      modal={modal}
    />
  );
};
