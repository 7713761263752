import React from 'react';

import { CDataTable } from '@coreui/react';
import { UiPagination } from 'components/UI/UiPagination';
import { BaseListModelType } from 'models/base';
import { defaultSizeByPageTable } from 'config';

type Props = {
  items: BaseListModelType<any>;
  fields: { key: string; label: string }[];
  loading: boolean;
  pageLimit: number;
  onPaginationChange: (pageLimit: number) => void;
  onChangePage: (pageNumber: number) => void;

  scopedSlots?: Record<string, (model: any) => React.ReactNode>;
};

export const UiTable = ({
  items,
  fields,
  loading,
  pageLimit,
  onPaginationChange,
  scopedSlots,
  onChangePage,
}: Props): React.ReactElement => (
  <>
    <CDataTable
      items={items.data}
      fields={fields}
      loading={loading}
      responsive
      striped
      border
      itemsPerPage={pageLimit}
      itemsPerPageSelect={{
        label: 'Показывать по',
        values: defaultSizeByPageTable,
      }}
      scopedSlots={scopedSlots}
      onPaginationChange={onPaginationChange}
      noItemsViewSlot={<h3 className="text-center mt-2">Нет данных для отображения</h3>}
    />

    {items.amountOfPages > 1 && (
      <UiPagination totalPages={items.amountOfPages} activePage={items.pageNumber} setPage={onChangePage} />
    )}
  </>
);
