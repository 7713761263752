import { getCurrentUserRole } from 'models/Auth';

/** Возвращает объект с полями которые нужно задизеблить в зависимости от прав пользователя */
export const getProductRights = (updateMode = false): { [key: string]: boolean } => {
  const { isAdmin } = getCurrentUserRole();

  return {
    name: updateMode && !isAdmin,
    type: updateMode,
    costPrice: updateMode,
    unitOfMeasure: updateMode,
  };
};
