import React, { memo } from 'react';
import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarNavDropdown,
  CSidebarNavItem,
  CSidebarMinimizer,
} from '@coreui/react';
import { useSelector } from 'react-redux';

import navigation from 'components/Layout/_nav';
import { isSidebarShow } from 'store/selectors';

export const TheSidebar = memo(
  (): React.ReactElement => {
    const sidebarShow = useSelector(isSidebarShow);

    return (
      <CSidebar show={sidebarShow}>
        <CSidebarBrand className="d-md-down-none " to="/">
          <img src="/assets/img/logo.png" alt="logo" className="h-65p" />
        </CSidebarBrand>
        <CSidebarNav>
          <CCreateElement
            items={navigation}
            components={{
              CSidebarNavDivider,
              CSidebarNavDropdown,
              CSidebarNavItem,
              CSidebarNavTitle,
            }}
          />
        </CSidebarNav>
        <CSidebarMinimizer className="c-d-md-down-none" />
      </CSidebar>
    );
  }
);
