import { useEffect } from 'react';
import { API_URL, HOST_URL } from 'config';

/**
 * страница редиректа на aad
 * редирект постоянный, возможно стоит вызывать его по условию (например если не авторизован)
 */
export const LoginPage = (): null => {
  useEffect(() => {
    // TODO убрать позже, сделано для удобства дев
    if (process.env.NODE_ENV === 'production') {
      document.location.replace(`${API_URL}/auth/uri?redirect=${HOST_URL}/system`);
    } else {
      document.location.replace(`${API_URL}/auth/uri?redirect=http://localhost:3000/system`);
    }
  }, []);

  return null;
};
