export const customerTranslations = {
  partner: 'Партнер',
  name: 'Имя',
  manager: 'Аккаунт-менеджер',
  new: 'Новый заказчик',
  route: {
    list: 'Заказчики',
    create: 'Заказчик (создание)',
    update: 'Заказчик (редактирование)',
  },
  created: 'Заказчик успешно создан',
  updated: 'Заказчик успешно обновлен',
  tab: {
    index: 'Основное',
    legalPersons: 'Юридические лица',
  },
};
