import isEqual from 'lodash/isEqual';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { CCard, CCardBody, CCol, CForm, CRow } from '@coreui/react';
import {
  clientContractMethodOfInvoiceSelectOptions,
  ClientContractMethodOfInvoiceType,
} from 'components/System/ClientContract/common';

import { getClientContractRights } from 'components/System/ClientContract/formHelpers';
import { UiSelectLegalPersonModal } from 'components/UI/UiSelectLegalPersonModal';
import { SelectOptionsType, SelectOptionType, UiSelect } from 'components/UI/UiSelect';
import { UiSelectCustomerModal } from 'components/UI/UiSelectCustomerModal';
import { useSelector } from 'react-redux';
import { getManagerId } from 'store/selectors';
import { clientContractTranslations } from 'translations/clientContract';
import { UiInput } from 'components/UI/UiInput';
import { UiDatePicker } from 'components/UI/UiDatePicker';
import { UiSubmitButton } from 'components/UI/UiSubmitButton';
import { getMaxDate, getMinDate, getUnixTimeGMT } from 'utils/date';
import { ClientContractCreateType, ClientContractUpdateType, ClientContractModel } from 'models/ClientContract';
import { formatModelNameId } from 'utils/formatters';
import { prepareFormData } from 'utils/formFilter';
import { baseFinder } from 'utils/listHelpers';

type Props = {
  model: ClientContractModel | null;
  saveLoading: boolean;
  customerList: SelectOptionsType<number>;
  findLegalPersonsByCustomerId: (id: number) => void;
  legalPersonsFoundByCustomerIdLoading: boolean;
  legalPersonsFoundByCustomerId: SelectOptionsType<number>;
  legalPersonsListSelectOptions: SelectOptionsType<number>;
  legalPersonsAllListSelectOptions: SelectOptionsType<number>;
  managerList: SelectOptionsType<string>;
  onSave: (id: number | undefined, data: ClientContractCreateType | ClientContractUpdateType) => void;
  modal: boolean;
};

type FormValues = {
  startingDate: Date | null;
  completionDate: Date | null;
  numberOfContract: string;
  lhsLegalPersonId: SelectOptionType<number>;
  rhsLegalPersonId: SelectOptionType<number>;
  methodOfInvoice: SelectOptionType<ClientContractMethodOfInvoiceType>;
  customerToRhs: SelectOptionType<number> | null; // от него зависит rhsLegalPersonId
  manager: SelectOptionType<string> | null;
};

export const SystemClientContractFormComponent = ({
  model,
  saveLoading,
  customerList,
  findLegalPersonsByCustomerId,
  legalPersonsFoundByCustomerIdLoading,
  legalPersonsFoundByCustomerId,
  legalPersonsListSelectOptions,
  managerList,
  onSave,
  legalPersonsAllListSelectOptions,
  modal,
}: Props): React.ReactElement => {
  const managerId = useSelector(getManagerId);
  const updateMode = !!model?.id;

  const initValues = useMemo(
    () => ({
      numberOfContract: model?.numberOfContract,
      startingDate: model?.startingDate ? getUnixTimeGMT(model.startingDate) : null,
      completionDate: model?.completionDate ? getUnixTimeGMT(model.completionDate) : null,
      lhsLegalPersonId: baseFinder(legalPersonsListSelectOptions, model?.lhsLegalPersonId) || undefined,
      rhsLegalPersonId: baseFinder(legalPersonsListSelectOptions, model?.rhsLegalPersonId) || undefined,
      methodOfInvoice: baseFinder(clientContractMethodOfInvoiceSelectOptions, model?.methodOfInvoice) || undefined,
      manager: model?.manager ? baseFinder(managerList, model.manager) : baseFinder(managerList, managerId),
      customerToRhs: baseFinder(customerList, model?.rhsLegalPerson.customerId),
    }),
    []
  );

  const { register, handleSubmit, errors, watch, setValue, control } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: initValues,
  });

  /** редактирование. вставить получателя после загрузки */
  useEffect(() => {
    const found = baseFinder(legalPersonsFoundByCustomerId, model?.rhsLegalPersonId);

    if (found) {
      setValue('rhsLegalPersonId', found, { shouldValidate: true });

      initValues.rhsLegalPersonId = found;
    }
  }, [legalPersonsFoundByCustomerId, model?.rhsLegalPersonId]);

  const disabledToSave = updateMode
    ? isEqual(initValues, {
        numberOfContract: watch('numberOfContract'),
        startingDate: watch('startingDate'),
        completionDate: watch('completionDate'),
        lhsLegalPersonId: watch('lhsLegalPersonId'),
        rhsLegalPersonId: watch('rhsLegalPersonId'),
        methodOfInvoice: watch('methodOfInvoice'),
        manager: watch('manager'),
        customerToRhs: watch('customerToRhs'),
      })
    : false;

  const rights = getClientContractRights(updateMode, model?.manager);

  const onSubmit = (data: FormValues) => {
    if (!disabledToSave) {
      const saveModel: ClientContractCreateType | ClientContractUpdateType = updateMode
        ? {
            startingDate: getMinDate(data.startingDate),
            completionDate: getMaxDate(data.completionDate),
            manager: updateMode ? data.manager?.value : undefined,
          }
        : {
            numberOfContract: data.numberOfContract,
            lhsLegalPersonId: data.lhsLegalPersonId.value,
            rhsLegalPersonId: data.rhsLegalPersonId.value,
            methodOfInvoice: data.methodOfInvoice.value,
            startingDate: getMinDate(data.startingDate) as number,
            completionDate: getMaxDate(data.completionDate) || null,
          };

      onSave(model?.id, prepareFormData(saveModel));
    }
  };

  const customer = watch('customerToRhs');
  useEffect(() => {
    if (customer?.value) {
      setValue('rhsLegalPersonId', null);
      findLegalPersonsByCustomerId(customer.value);
    }
  }, [customer]);

  return (
    <CForm onSubmit={handleSubmit(onSubmit)}>
      <CCard>
        <CCardBody>
          <CRow>
            <CCol xs={9}>
              {!modal && (
                <h3>{model?.id ? formatModelNameId(null, model.id) : clientContractTranslations.route.create}</h3>
              )}

              <CRow>
                <CCol xs="12" sm="6" md="4">
                  <UiInput
                    register={register}
                    label={clientContractTranslations.numberOfContract}
                    name="numberOfContract"
                    errors={errors}
                    required
                    disabled={rights.numberOfContract}
                  />
                </CCol>
                <CCol xs="12" sm="6" md="4">
                  <UiDatePicker
                    control={control}
                    name="startingDate"
                    setValue={setValue}
                    value={watch('startingDate')}
                    label={clientContractTranslations.startingDate}
                    errors={errors}
                    required
                    disabled={rights.startingDate}
                  />
                </CCol>
                <CCol xs="12" sm="6" md="4">
                  <UiDatePicker
                    control={control}
                    name="completionDate"
                    setValue={setValue}
                    value={watch('completionDate')}
                    label={clientContractTranslations.completionDate}
                    disabled={rights.completionDate}
                  />
                </CCol>
                <CCol xs="12" sm="6" md="4">
                  <UiSelectCustomerModal
                    register={register}
                    name="customerToRhs"
                    options={customerList}
                    setValue={setValue}
                    value={watch('customerToRhs')}
                    errors={errors}
                    modal={modal}
                    disabled={rights.customerToRhs}
                  />
                </CCol>
                <CCol xs="12" sm="6" md="4">
                  <UiSelectLegalPersonModal
                    label={clientContractTranslations.rhsLegalPersonId}
                    register={register}
                    name="rhsLegalPersonId"
                    options={customer ? legalPersonsFoundByCustomerId : legalPersonsAllListSelectOptions}
                    setValue={setValue}
                    value={watch('rhsLegalPersonId')}
                    errors={errors}
                    isLoading={legalPersonsFoundByCustomerIdLoading}
                    setOnSave
                    disabled={rights.rhsLegalPersonId}
                  />
                </CCol>
                <CCol xs="12" sm="6" md="4">
                  <UiSelectLegalPersonModal
                    label={clientContractTranslations.lhsLegalPersonId}
                    register={register}
                    name="lhsLegalPersonId"
                    options={legalPersonsListSelectOptions}
                    setValue={setValue}
                    value={watch('lhsLegalPersonId')}
                    errors={errors}
                    setOnSave
                    disabled={rights.lhsLegalPersonId}
                  />
                </CCol>
                <CCol xs="12" sm="6" md="4">
                  <UiSelect
                    register={register}
                    name="methodOfInvoice"
                    options={clientContractMethodOfInvoiceSelectOptions}
                    setValue={setValue}
                    value={watch('methodOfInvoice')}
                    errors={errors}
                    label={clientContractTranslations.methodOfInvoice.title}
                    disabled={rights.methodOfInvoice}
                  />
                </CCol>
                <CCol xs="12" sm="6" md="4">
                  <UiSelect
                    label={clientContractTranslations.manager}
                    register={register}
                    name="manager"
                    options={managerList}
                    setValue={setValue}
                    value={watch('manager')}
                    errors={errors}
                    required
                    disabled={rights.manager}
                  />
                </CCol>
              </CRow>

              <UiSubmitButton saving={saveLoading} disabled={disabledToSave} />
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CForm>
  );
};
