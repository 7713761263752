import { useCallback, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';

import { findTariffsHelper } from 'components/System/Tariff/formHelpers';
import { SelectOptionsType, SelectOptionType } from 'components/UI/UiSelect';
import { ProductModel, ProductTypesEnum } from 'models/Product';
import { TariffPositions, TariffProductInfoType } from 'models/Tariff';

export const useProductSelectTable = (
  subscrProducts: ProductModel[],
  razProducts: ProductModel[],
  tariffPositions?: TariffPositions | null
): any => {
  const [subscrSelected, setSubscrSelected] = useState<TariffProductInfoType[]>(() =>
    findTariffsHelper(ProductTypesEnum.SUBSCRIPTION, tariffPositions)
  );
  const [razSelected, setRazSelected] = useState<TariffProductInfoType[]>(() =>
    findTariffsHelper(ProductTypesEnum.LUMP_SUM, tariffPositions)
  );

  /** подготовить выбранные услуги для таблицы и оставшиеся для селекта */
  const getOptionsAndTableData = useCallback(
    (type: ProductTypesEnum): [SelectOptionsType<number>, ProductModel[]] => {
      const data = type === ProductTypesEnum.SUBSCRIPTION ? subscrSelected : razSelected;
      const products = type === ProductTypesEnum.SUBSCRIPTION ? subscrProducts : razProducts;

      const options: SelectOptionsType<number> = [];
      const table: ProductModel[] = [];

      const ids = data.map(({ productId }) => productId);
      products.forEach((item) => {
        if (ids.includes(item.id)) {
          table.push(item);
        } else {
          options.push({ value: item.id, label: item.name });
        }
      });

      return [options, table];
    },
    [razProducts, razSelected, subscrSelected, subscrProducts]
  );
  const [subscrProductsOptions, subscrProductsSelected] = getOptionsAndTableData(ProductTypesEnum.SUBSCRIPTION);
  const [razProductsOptions, razProductsSelected] = getOptionsAndTableData(ProductTypesEnum.LUMP_SUM);

  /** логика переноса из селекта в таблицу */
  const onAddFromSelect = useCallback(
    (type: ProductTypesEnum) => (item: SelectOptionType | SelectOptionType[]) => {
      const setter = type === ProductTypesEnum.SUBSCRIPTION ? setSubscrSelected : setRazSelected;
      const data = type === ProductTypesEnum.SUBSCRIPTION ? subscrSelected : razSelected;

      const emptyValue: number = ('' as unknown) as number;

      /** один элемент или массив для добавления всех */
      if (Array.isArray(item)) {
        const newItems = item.map((itemToAdd) => ({
          productId: itemToAdd.value,
          priceWithoutNds: emptyValue,
          priceWithNds: emptyValue,
        }));
        setter([...data, ...newItems]);
      } else {
        setter([...data, { productId: item.value, priceWithoutNds: emptyValue, priceWithNds: emptyValue }]);
      }
    },
    [setSubscrSelected, razSelected, subscrSelected, setRazSelected]
  );

  /** при удалении из таблицы вернуть в селект */
  const onRemove = useCallback(
    (type: ProductTypesEnum) => (idToRemove: number) => {
      const setter = type === ProductTypesEnum.SUBSCRIPTION ? setSubscrSelected : setRazSelected;
      const data = type === ProductTypesEnum.SUBSCRIPTION ? subscrSelected : razSelected;

      setter(data.filter(({ productId }) => idToRemove !== productId));
    },
    [setSubscrSelected, razSelected, subscrSelected, setRazSelected]
  );

  /** изменение инфы ндс */
  const onNDSChange = useCallback(
    (type: ProductTypesEnum) => (val: TariffProductInfoType) => {
      const setter = type === ProductTypesEnum.SUBSCRIPTION ? setSubscrSelected : setRazSelected;
      const data = type === ProductTypesEnum.SUBSCRIPTION ? subscrSelected : razSelected;

      const foundIndex = data.findIndex(({ productId }) => productId === val.productId);

      if (foundIndex !== -1) {
        const newArr = cloneDeep(data);
        newArr[foundIndex] = val;
        setter(newArr);
      }
    },
    [setSubscrSelected, razSelected, subscrSelected, setRazSelected]
  );

  return {
    onAddFromSelect,
    onRemove,
    onNDSChange,
    subscrProductsOptions,
    subscrProductsSelected,
    razProductsOptions,
    razProductsSelected,
    subscrSelected,
    setSubscrSelected,
    razSelected,
    setRazSelected,
  };
};
