import { AnyAction, combineReducers } from 'redux';
import { globalReducer, globalReducerType } from 'store/globalReducer';

export type RootReducer = {
  global: globalReducerType;
};

const appReducer: { [K in keyof RootReducer]: any } = {
  ...globalReducer,
};

export const reducers = (state: RootReducer, action: AnyAction): any =>
  combineReducers({ ...appReducer })(state, action);
