import { useEffect, useState } from 'react';
import { doRequest } from 'utils/doRequest';

let mounted = false;

/**
 * хук выполнения функции с отслеживанием выполнения и передачей ответа выполненной функции
 */
export const useRequest = <T>(
  fnToCall: () => null | Promise<T>,
  defaultValue: any = null,
  parentMounted?: () => boolean // для тонких случаев, когда текущий mounted уже меняется
): {
  loading: boolean;
  model: T | null;
} => {
  useEffect(() => {
    mounted = true;
    return () => {
      mounted = false;
    };
  }, []);

  const [loading, setLoading] = useState(false);
  const [model, setModel] = useState<any>(defaultValue);

  useEffect(() => {
    const promiseOrNull = fnToCall();

    if (promiseOrNull?.then) {
      setLoading(true);

      doRequest(
        () => promiseOrNull,
        () => (parentMounted ? parentMounted() : mounted)
      )(setModel, () => setLoading(false));
    }
  }, [fnToCall]);

  return {
    loading,
    model,
  };
};
