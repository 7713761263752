export const invoiceOnOrderTranslations = {
  route: {
    list: 'Начисления',
    create: 'Начисления (создание)',
    update: 'Начисления (редактирование)',
    view: 'Начисления (просмотр)',
  },
  creationDate: 'Дата формирования',
  invoicePeriod: 'Период начисления',
  customer: 'Заказчик',
  legalPerson: 'Юридическое лицо',
  service: 'Сервис',
  costWithNds: 'Сумма с НДС',
  costWithoutNds: 'Сумма без НДС',
  clientContract: 'Клиентский договор',
  order: 'Заказ',
  isReversal: 'Сторно',
  numberOfContract: 'Клиентский договор, номер',
  methodOfInvoice: 'Клиентский договор, тип начисления',
  clientContractStartingDate: 'Клиентский договор, дата начала',
  clientContractCompletionDate: 'Клиентский договор, дата завершения',
  lhsLegalPersonId: 'Клиентский договор, исполнитель',
  serviceName: 'Сервис, название',
  serviceAgencyContractId: 'Агентский договор',
  agent: 'Агент',
  orderId: 'Заказ, номер',
  orderStartingDate: 'Заказ, дата начала',
  orderCompletionDate: 'Заказ, дата завершения',
};
