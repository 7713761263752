import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import isFunction from 'lodash/isFunction';
import isNaN from 'lodash/isNaN';
import toNumber from 'lodash/toNumber';
import { createProduct, getProduct, updateProduct } from 'api/product';
import { SystemProductFormComponent } from 'components/System/Product/form';
import { ProductCreateType, ProductUpdateType } from 'models/Product';
import { productTranslations } from 'translations/product';
import { doRequest } from 'utils/doRequest';
import { UiLoader } from 'components/UI/UiLoader';
import { useRequest } from 'utils/hooks/useRequest';
import { ModalEntityProps } from 'utils/types';

let mounted = false;

export const SystemProductForm = ({ modal = false, closeModal, onSaved }: ModalEntityProps): React.ReactElement => {
  useEffect(() => {
    mounted = true;
    return () => {
      mounted = false;
    };
  }, []);

  const query = useParams<{ id?: string }>();
  const numberId = toNumber(query.id);

  const getData = useCallback(() => (isNaN(numberId) || modal ? null : getProduct(numberId)), [numberId]);
  const { model: initModel, loading } = useRequest(getData, null, () => mounted);

  const [key, setKey] = useState(1);
  const [model, setModel] = useState(initModel);
  useEffect(() => {
    setModel(initModel);
  }, [initModel]);

  const [saveLoading, setSaveLoading] = useState(false);

  const onSave = useCallback((data: ProductCreateType | ProductUpdateType, id: number | undefined) => {
    const updateMode = !!id;

    const apiFn = updateMode
      ? () => updateProduct(id as number, data as ProductUpdateType)
      : () => createProduct(data as ProductCreateType);

    setSaveLoading(true);

    doRequest(apiFn, () => mounted)(
      (savedItem) => {
        if (modal) {
          if (isFunction(onSaved)) {
            onSaved(savedItem);
          }

          if (isFunction(closeModal)) {
            closeModal();
          }
        }

        getProduct(savedItem.id).then((response) => {
          setModel(response);
          setKey((k) => k + 1);
        });

        toast.success(productTranslations.created);
      },
      () => {
        setSaveLoading(false);
      }
    );
  }, []);

  return loading ? (
    <UiLoader />
  ) : (
    <SystemProductFormComponent
      key={key}
      model={model}
      onSave={onSave}
      saveLoading={saveLoading}
      modal={modal || false}
    />
  );
};
