import React, { useEffect, useState } from 'react';

import { UiLoader } from 'components/UI/UiLoader';
import { SelectOptionsType } from 'components/UI/UiSelect';
import { getAllLegalPersonsList } from 'api/legalPerson';
import { getAllTariffs, getTariffList } from 'api/tariff';
import { SystemTariffListComponent } from 'components/System/Tariff/list';
import { doRequest } from 'utils/doRequest';

let mounted = false;

export const SystemTariffList = (): React.ReactElement => {
  useEffect(() => {
    mounted = true;
    return () => {
      mounted = false;
    };
  }, []);

  const [model, setModel] = useState<SelectOptionsType<number>>([]);
  const [loading, setLoading] = useState(true);

  const [allTariffsOptions, setAllTariffsOptions] = useState<SelectOptionsType<number>>([]);
  const [allTariffsOptionsLoading, setAllTariffsOptionsLoading] = useState(true);

  useEffect(() => {
    doRequest(
      () => getAllLegalPersonsList({ isExecutor: true }),
      () => mounted
    )(
      (executors) => {
        setModel(executors.data.map(({ name, id }) => ({ value: id, label: name })));
      },
      () => {
        setLoading(false);
      }
    );
  }, []);

  useEffect(() => {
    doRequest(
      () => getAllTariffs(),
      () => mounted
    )(
      (allTariffs) => {
        setAllTariffsOptions(allTariffs.data.map(({ name, id }) => ({ value: id, label: name })));
      },
      () => {
        setAllTariffsOptionsLoading(false);
      }
    );
  }, []);

  return loading ? (
    <UiLoader />
  ) : (
    <SystemTariffListComponent
      getData={getTariffList}
      executorSelectOptions={model}
      allTariffsOptions={allTariffsOptions}
      allTariffsOptionsLoading={allTariffsOptionsLoading}
    />
  );
};
