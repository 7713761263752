import isFunction from 'lodash/isFunction';
import isNaN from 'lodash/isNaN';
import toNumber from 'lodash/toNumber';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { createServiceType, getServiceType, updateServiceType } from 'api/serviceType';
import { SystemServiceTypeFormComponent } from 'components/System/ServiceType/form';
import { serviceTypeTranslations } from 'translations/serviceType';
import { doRequest } from 'utils/doRequest';
import { useRequest } from 'utils/hooks/useRequest';
import { UiLoader } from 'components/UI/UiLoader';
import { ServiceTypeFormType } from 'models/ServiceType';
import { ModalEntityProps } from 'utils/types';

let mounted = false;

export const SystemServiceTypeForm: React.FC<ModalEntityProps> = ({
  modal = false,
  closeModal,
  onSaved,
}: ModalEntityProps) => {
  useEffect(() => {
    mounted = true;
    return () => {
      mounted = false;
    };
  }, []);

  const query = useParams<{ id?: string }>();
  const numberId = toNumber(query.id);

  const getData = useCallback(() => (isNaN(numberId) ? null : getServiceType(numberId)), [numberId]);
  const { model: initModel, loading } = useRequest(getData, null, () => mounted);

  const [key, setKey] = useState(1);
  const [model, setModel] = useState(initModel);
  useEffect(() => {
    setModel(initModel);
  }, [initModel]);

  const [saveLoading, setSaveLoading] = useState(false);

  const onSave = useCallback(
    (data: ServiceTypeFormType) => {
      const updateMode = !isNaN(numberId);

      const apiFn = updateMode ? () => updateServiceType(numberId as number, data) : () => createServiceType(data);
      setSaveLoading(true);

      doRequest(apiFn, () => mounted)(
        (savedItem) => {
          if (modal) {
            if (isFunction(closeModal)) {
              closeModal();
            }

            if (isFunction(onSaved)) {
              onSaved(savedItem);
            }
          }

          getServiceType(savedItem.id).then((response) => {
            setModel(response);
            setKey((k) => k + 1);
          });

          toast.success(updateMode ? serviceTypeTranslations.updated : serviceTypeTranslations.created);
        },
        () => {
          setSaveLoading(false);
        }
      );
    },
    [numberId]
  );

  return loading ? (
    <UiLoader />
  ) : (
    <SystemServiceTypeFormComponent key={key} model={model} onSave={onSave} saveLoading={saveLoading} modal={modal} />
  );
};
