export const invoiceForAgencyTranslations = {
  route: {
    list: 'Агентские вознаграждения',
    view: 'Агентское вознаграждение',
  },
  creationDate: 'Дата формирования',
  invoicePeriod: 'Период начисления',
  agent: 'Агент',
  agencyContract: 'Агентский договор',
  invitedLegalPersonIds: 'Приглашенное ЮЛ',
  remunerationWithNds: 'Сумма вознаграждения с НДС',
  remunerationWithoutNds: 'Сумма вознаграждения без НДС',
  orderId: 'Заказ, номер',
  order: {
    creationDate: 'Заказ, дата создания',
    completionDate: 'Заказ, дата завершения',
    serviceName: 'Заказ, сервис',
  },
};
