import { UiRowCheckbox } from 'components/UI/UiCheckbox';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { CCol, CForm, CRow } from '@coreui/react';

import { SelectOptionsType, UiSelect } from 'components/UI/UiSelect';
import { UiInput } from 'components/UI/UiInput';
import { UiToolbar } from 'components/UI/UiToolbar';
import { tariffTranslations } from 'translations/tariff';
import { prepareFormData, prepareIds, setValueFilter } from 'utils/formFilter';
import { Optional } from 'utils/types';
import { TariffFilterType } from 'models/Tariff';

type Props = {
  onSearch: (f: TariffFilterType) => void;
  onReset: () => void;
  filter: any;
  executorSelectOptions: SelectOptionsType<number>;
};

type FormValues = Optional<{
  id: number;
  name: string;
  legalPersonIds: SelectOptionsType<number>;
  isActive: boolean;
}>;

export const SystemTariffFilter = ({ onSearch, onReset, filter, executorSelectOptions }: Props): React.ReactElement => {
  const { register, handleSubmit, reset, setValue, watch } = useForm<FormValues>({
    defaultValues: {
      isActive: true,
    },
  });

  const onLocalReset = () => {
    reset();
    onReset();
  };

  const onSearchLocal = (data: FormValues) =>
    onSearch(
      prepareFormData({
        ids: prepareIds(data.id),
        name: data.name?.length ? data.name : undefined,
        legalPersonIds: data.legalPersonIds ? data.legalPersonIds.map(({ value }) => value) : undefined,
        isArchive: data.isActive ? false : undefined,
      })
    );

  useEffect(() => {
    setValueFilter(filter, setValue, 'name');
    setValueFilter(filter, setValue, 'isActive');
    if (filter.ids) {
      setValue('id', filter.ids[0]);
    }
  }, [filter, setValue]);

  return (
    <CForm onSubmit={handleSubmit(onSearchLocal)} onReset={onLocalReset}>
      <UiToolbar open={Object.values(watch()).some((f) => f)}>
        <CRow>
          <CCol xs="12" sm="6" md="4">
            <UiInput register={register} name="id" label="ID" />
          </CCol>
          <CCol xs="12" sm="6" md="4">
            <UiInput register={register} name="name" label={tariffTranslations.name} />
          </CCol>
          <CCol xs="12" sm="6" md="4">
            <UiSelect
              register={register}
              name="legalPersonIds"
              options={executorSelectOptions}
              setValue={setValue}
              value={watch('legalPersonIds')}
              isClearable
              allowEmptyValue
              multiple
              label={tariffTranslations.legalPersons}
            />
          </CCol>
          <CCol xs="12" sm="6" md="4">
            <UiRowCheckbox register={register} name="isActive" label={tariffTranslations.isActive} />
          </CCol>
        </CRow>
      </UiToolbar>
    </CForm>
  );
};
