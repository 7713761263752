import { CNav, CNavItem, CNavLink, CTabContent, CTabPane, CTabs } from '@coreui/react';
import React, { memo, useState } from 'react';

type Props = {
  items: Record<string, { title: string; component: () => React.ReactNode; show?: boolean }>;

  stayAlive?: boolean; // рендерить все табы, даже не открытые (чтобы стейт например не сбрасывался)
};

export const UiTabs = memo(
  ({ items, stayAlive }: Props): React.ReactElement => {
    const [tab, setTab] = useState(() => Object.keys(items)[0]);

    const itemArr = Object.entries(items);

    const headers = stayAlive ? itemArr : itemArr.filter(([, { show }]) => (show !== undefined ? show : true));
    const contents = stayAlive ? itemArr : itemArr.filter(([key]) => tab === key);

    return (
      <CTabs activeTab={tab} onActiveTabChange={setTab}>
        <CNav variant="tabs">
          {headers.map(([key, { title }]) => (
            <CNavItem key={key}>
              <CNavLink data-tab={key}>{title}</CNavLink>
            </CNavItem>
          ))}
        </CNav>
        <CTabContent className="mt-3">
          {/** показывать только 1 активную табу */}
          {contents.map(([key, { component }]) => (
            <CTabPane key={key} data-tab={key}>
              {component()}
            </CTabPane>
          ))}
        </CTabContent>
      </CTabs>
    );
  }
);
