import React, { memo } from 'react';
import { CFormGroup, CInputCheckbox, CLabel, innerRef } from '@coreui/react';
import { RegisterOptions } from 'react-hook-form';

type Props = {
  register: (v?: RegisterOptions) => innerRef | undefined;
  name: string;
  label: string;

  horizontalCentered?: boolean;
  disabled?: boolean;
  className?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const UiRowCheckbox = memo(
  ({ register, name, label, horizontalCentered, disabled, className, onChange }: Props): React.ReactElement => {
    const id = Math.random().toString();

    return (
      <CFormGroup row className={`ml-0${horizontalCentered ? ' mt-2rem' : ''}${className ? ` ${className}` : ''}`}>
        <CFormGroup variant="custom-checkbox">
          <CInputCheckbox custom id={id} name={name} innerRef={register()} disabled={disabled} onChange={onChange} />
          <CLabel variant="custom-checkbox" htmlFor={id} />
        </CFormGroup>
        <CLabel htmlFor={id}>{label}</CLabel>
      </CFormGroup>
    );
  }
);
