import { getAllCustomersList } from 'api/customer';
import { SelectOptionsType } from 'components/UI/UiSelect';
import isFunction from 'lodash/isFunction';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import isNaN from 'lodash/isNaN';
import toNumber from 'lodash/toNumber';

import { ServiceFormType } from 'models/Service';
import { createService, getService, updateService } from 'api/service';
import { SystemServiceFormComponent } from 'components/System/Service/form';
import { UiLoader } from 'components/UI/UiLoader';
import { addServiceAction } from 'store/actions';
import { serviceTranslations } from 'translations/service';
import { doRequest } from 'utils/doRequest';
import { useAgencyContractsOptions } from 'utils/hooks/SelectOptionsHooks/useAgencyContractsOptions';
import { useAgentsOptions } from 'utils/hooks/SelectOptionsHooks/useAgentsOptions';
import { useClientsContractsOptions } from 'utils/hooks/SelectOptionsHooks/useClientsContractsOptions';
import { useTariffsOptions } from 'utils/hooks/SelectOptionsHooks/useTariffsOptions';
import { useAllManagers } from 'utils/hooks/useAllManagers';
import { useLegalPersonsOptions } from 'utils/hooks/SelectOptionsHooks/useLegalPersonsOptions';
import { useRequest } from 'utils/hooks/useRequest';
import { useServiceTypes } from 'utils/hooks/useServiceTypes';
import { ModalEntityProps } from 'utils/types';

let mounted = false;

export const SystemServiceForm = ({ modal = false, closeModal, onSaved }: ModalEntityProps): React.ReactElement => {
  useEffect(() => {
    mounted = true;
    return () => {
      mounted = false;
    };
  }, []);

  const dispatch = useDispatch();

  const { managersListSelectOptions, loading: managersLoading } = useAllManagers();

  const query = useParams<{ id?: string }>();
  const numberId = toNumber(query.id);

  const [customerList, setCustomerList] = useState<SelectOptionsType<number>>([]);
  const [customerListLoading, setCustomerListLoading] = useState(true);

  useEffect(() => {
    doRequest(getAllCustomersList, () => true)(
      ({ data }) => {
        setCustomerList(data.map((m) => ({ value: m.id, label: m.name })));
      },
      () => {
        setCustomerListLoading(false);
      }
    );
  }, []);

  const getData = useCallback(() => (isNaN(numberId) ? null : getService(numberId)), [numberId]);
  const { model: initModel, loading } = useRequest(getData, null, () => mounted);

  const [key, setKey] = useState(1);
  const [model, setModel] = useState(initModel);
  useEffect(() => {
    setModel(initModel);
  }, [initModel]);

  const [saveLoading, setSaveLoading] = useState(false);

  const onSave = useCallback((data: ServiceFormType) => {
    const updateMode = data.id;
    const apiFn = updateMode
      ? () => updateService(data.id as number, { manager: data.manager || '' })
      : () => createService(data);
    setSaveLoading(true);

    doRequest(apiFn, () => mounted)(
      (savedItem) => {
        if (modal) {
          if (isFunction(closeModal)) {
            closeModal();
          }

          if (isFunction(onSaved)) {
            onSaved(savedItem);
          }
        }

        dispatch(addServiceAction(savedItem));

        getService(savedItem.id).then((response) => {
          setModel(response);
          setKey((k) => k + 1);
        });

        toast.success(updateMode ? serviceTranslations.updated : serviceTranslations.created);
      },
      () => {
        setSaveLoading(false);
      }
    );
  }, []);

  /** найти получателей, зависящих от заказчика */
  const [legalPersons, getLegalPersons, legalPersonsLoading] = useLegalPersonsOptions();

  /** найти клиентские договоры зависящие от юл. */
  const [clientContracts, getClientContracts, clientContractsLoading] = useClientsContractsOptions();

  /** Загрузить все не архивные тарифы */
  const [tariffs, getTariffs, tariffsLoading] = useTariffsOptions();

  /** Загрузить юл - агентов */
  const [agents, , agentsLoading] = useAgentsOptions();

  const [serviceTypes, , serviceTypesLoading] = useServiceTypes();

  /** Найти агентские договоры по юл агента */
  const [agencyContracts, findAgencyContracts, agencyContractsLoading] = useAgencyContractsOptions();

  return loading || agentsLoading || customerListLoading || managersLoading || serviceTypesLoading ? (
    <UiLoader />
  ) : (
    <SystemServiceFormComponent
      key={key}
      model={model}
      onSave={onSave}
      saveLoading={saveLoading}
      customerList={customerList}
      findLegalPersonsByCustomerId={getLegalPersons}
      legalPersonsFoundByCustomerIdLoading={legalPersonsLoading}
      legalPersonsFoundByCustomerId={legalPersons}
      findClientContracts={getClientContracts}
      clientContracts={clientContracts}
      clientContractsLoading={clientContractsLoading}
      findAgencyContracts={findAgencyContracts}
      agencyContracts={agencyContracts}
      agencyContractsLoading={agencyContractsLoading}
      tariffs={tariffs}
      tariffsLoading={tariffsLoading}
      getTariffs={getTariffs}
      agents={agents}
      managerList={managersListSelectOptions}
      modal={modal}
      serviceTypes={serviceTypes}
    />
  );
};
