import { getCurrentUserRole, getIsOwner } from 'models/Auth';

/** Возвращает объект с полями которые нужно задизеблить в зависимости от прав пользователя */
export const getOrderRights = (updateMode = false, managerId = ''): { [key: string]: boolean } => {
  const { isAdmin, isChiefManager } = getCurrentUserRole();
  const isOwner = getIsOwner(managerId);

  return {
    startingDate: updateMode && !(isOwner || isAdmin),
    completionDate: updateMode && !(isOwner || isAdmin),
    customerToLegalPerson: updateMode,
    legalPersonToClientContract: updateMode,
    clientContractToService: updateMode,
    serviceId: updateMode,
    numberOfOrder: updateMode && !isAdmin,
    isTesting: updateMode,
    manager: !(updateMode && isAdmin),

    abonementProducts: updateMode && !(isAdmin || isChiefManager),
    abonementProductsRemove: updateMode,
    razProducts: updateMode && !(isAdmin || isChiefManager),
    razProductsRemove: updateMode,
  };
};
