import React from 'react';
import { CButton, CCard, CCardBody, CCol, CRow } from '@coreui/react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { UiTable } from 'components/UI/UiTable';
import { tariffTranslations } from 'translations/tariff';
import { systemTariffBasedOnRoute, systemTariffRoute } from 'containers/System/Tariff/routes';
import { customHistory } from 'utils/customHistory';
import { useFilter } from 'utils/hooks/useFilter';
import { getTariffList } from 'api/tariff';
import { TariffListModel, TariffModel } from 'models/Tariff';
import { SystemTariffFilter } from 'components/System/Tariff/filter';
import { SelectOptionsType, UiSelect } from 'components/UI/UiSelect';

const fields = [
  { key: 'id', label: 'ID' },
  { key: 'name', label: tariffTranslations.name },
  { key: 'legalPersons', label: tariffTranslations.legalPersons },
  { key: 'isActive', label: tariffTranslations.isActive },
];

type Props = {
  getData: typeof getTariffList;
  executorSelectOptions: SelectOptionsType<number>;
  allTariffsOptions: SelectOptionsType<number>;
  allTariffsOptionsLoading: boolean;
};

export const SystemTariffListComponent = ({
  getData,
  executorSelectOptions,
  allTariffsOptions,
  allTariffsOptionsLoading,
}: Props): React.ReactElement => {
  const { onPaginationChange, loading, model, filter, onReset, onSearch, onChangePage } = useFilter<TariffListModel>(
    getData,
    { isArchive: false }
  );

  const { register, setValue, watch } = useForm<any>({});

  return (
    <CCard>
      <CCardBody>
        <h3 className="text-center">{tariffTranslations.route.list}</h3>

        <CRow className="justify-content-center">
          <CCol xs={6} md={3}>
            {/* eslint-disable-next-line jsx-a11y/label-has-for  */}
            <label>&nbsp;</label>
            <CButton tag={Link} block color="primary" to={systemTariffRoute()}>
              {tariffTranslations.new}
            </CButton>
          </CCol>
          <CCol xs={6} md={3}>
            <UiSelect
              register={register}
              name="createBasedOn"
              label="Создать на основании"
              options={allTariffsOptions}
              setValue={setValue}
              isLoading={allTariffsOptionsLoading}
              value={watch('createBasedOn')}
              onChange={({ value }) => {
                customHistory.push(systemTariffBasedOnRoute(value));
              }}
              allowEmptyValue
            />
          </CCol>
        </CRow>

        <SystemTariffFilter
          filter={filter}
          onSearch={onSearch}
          onReset={onReset}
          executorSelectOptions={executorSelectOptions}
        />

        <UiTable
          items={model}
          fields={fields}
          loading={loading}
          pageLimit={filter.pageLimit}
          onChangePage={onChangePage}
          scopedSlots={{
            id: ({ id }: TariffModel) => (
              <td>
                <Link to={systemTariffRoute(id)}>{id}</Link>
              </td>
            ),
            legalPersons: ({ legalPersonAndTariffs }: TariffModel) => (
              <td>{legalPersonAndTariffs.map(({ legalPerson }) => legalPerson.name).join(', ')}</td>
            ),
            isActive: ({ archivingDate }: TariffModel) => <td>{archivingDate ? 'Нет' : 'Да'}</td>,
          }}
          onPaginationChange={onPaginationChange}
        />
      </CCardBody>
    </CCard>
  );
};
