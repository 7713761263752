import { maxValueToGetAllDataWithPagination } from 'config';
import {
  ClientContractCreateType,
  ClientContractFilterType,
  ClientContractListModel,
  ClientContractModel,
  ClientContractUpdateType,
} from 'models/ClientContract';
import { httpGet, httpPost, httpPut } from 'utils/http';

const path = '/admin/client-contracts';

/** создать клиентский договор */
export const createClientContract = (model: ClientContractCreateType): Promise<ClientContractModel> =>
  httpPost(`${path}/contract`, model);

/** обновить клиентский договор */
export const updateClientContract = (id: number, model: ClientContractUpdateType): Promise<ClientContractModel> =>
  httpPut(`${path}/contract/${id}`, model);

/** получить клиентский договор */
export const getClientContract = (id: number): Promise<ClientContractModel> => httpGet(`${path}/contract/${id}`);

/** получить список клиентских договоров */
export const getClientContractList = (filter: ClientContractFilterType): Promise<ClientContractListModel> =>
  httpGet(`${path}/filter`, filter);

/** получить все клиентские договоры */
export const getAllClientContracts = (filter?: ClientContractFilterType): Promise<ClientContractListModel> =>
  getClientContractList({ ...filter, pageLimit: maxValueToGetAllDataWithPagination });

/** получить список клиентских договоров по конкретному юр.лицу */
export const getClientContractListByLegalPersonId = (
  personId: number
): Promise<[ClientContractListModel, ClientContractListModel]> =>
  Promise.all([
    getClientContractList({ lhsLegalPersonId: personId }),
    getClientContractList({ rhsLegalPersonId: personId }),
  ]);
