import { getAllClientContracts } from 'api/clientContract';
import { AnyAction } from 'redux';
import { all, put, takeLatest } from 'redux-saga/effects';

import { getAllServiceList } from 'api/service';
import { getAllTariffs } from 'api/tariff';
import {
  getAllClientContractsOkAction,
  getAllCustomersOkAction,
  getAllLegalPersons2OkAction,
  getAllLegalPersonsOkAction,
  getAllServicesOkAction,
  getAllTariffsOkAction,
} from 'store/actions';
import {
  GET_CLIENT_CONTRACTS_START,
  GET_CUSTOMERS_START,
  GET_LEGAL_PERSONS2_START,
  GET_LEGAL_PERSONS_START,
  GET_SERVICES_START,
  GET_TARIFFS_START,
} from 'store/constants';
import { getAllCustomersList } from 'api/customer';
import { getAllLegalPersonsList } from 'api/legalPerson';

function* getCustomersSaga(): Generator<any, any, any> {
  try {
    const response = yield getAllCustomersList();
    yield put(getAllCustomersOkAction(response));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

function* getLegalPersonsSaga({ payload }: AnyAction): Generator<any, any, any> {
  try {
    const response = yield getAllLegalPersonsList(payload.filter);
    yield put(getAllLegalPersonsOkAction(response));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

function* getLegalPersons2Saga({ payload }: AnyAction): Generator<any, any, any> {
  try {
    const response = yield getAllLegalPersonsList(payload.filter);
    yield put(getAllLegalPersons2OkAction(response));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

function* getTariffsSaga({ payload }: AnyAction): Generator<any, any, any> {
  try {
    const response = yield getAllTariffs(payload.filter);
    yield put(getAllTariffsOkAction(response));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

function* getServicesSaga({ payload }: AnyAction): Generator<any, any, any> {
  try {
    const response = yield getAllServiceList(payload.filter);
    yield put(getAllServicesOkAction(response));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

function* getClientContractsSaga({ payload }: AnyAction): Generator<any, any, any> {
  try {
    const response = yield getAllClientContracts(payload.filter);
    yield put(getAllClientContractsOkAction(response));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

function* baseSaga() {
  yield takeLatest(GET_CUSTOMERS_START, getCustomersSaga);
  yield takeLatest(GET_LEGAL_PERSONS_START, getLegalPersonsSaga);
  yield takeLatest(GET_LEGAL_PERSONS2_START, getLegalPersons2Saga);
  yield takeLatest(GET_TARIFFS_START, getTariffsSaga);
  yield takeLatest(GET_SERVICES_START, getServicesSaga);
  yield takeLatest(GET_CLIENT_CONTRACTS_START, getClientContractsSaga);
}

export function* rootSaga(): Generator<any, any, any> {
  yield all([baseSaga()]);
}
