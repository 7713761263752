import { CCard, CCardBody, CCol, CRow } from '@coreui/react';
import isNil from 'lodash/isNil';
import React from 'react';

import { InvoiceForAgencyType } from 'models/InvoiceForAgency';
import { invoiceForAgencyTranslations } from 'translations/InvoiceForAgency';
import { formatDate } from 'utils/date';
import { formatCurrency, formatModelNameId } from 'utils/formatters';

const noData = 'Не указано';

const InfoBlock = ({ label, value }: { label: string; value?: string | number | null }) => (
  <CCol xs="12" sm="6" md="4" className="mb-2">
    {/* eslint-disable-next-line jsx-a11y/label-has-for */}
    <label className="form-label">{label}</label>
    <input type="text" className="form-control" defaultValue={isNil(value) ? noData : value} disabled />
  </CCol>
);

type Props = {
  model: InvoiceForAgencyType | null;
};

export const SystemInvoiceForAgencyFormComponent: React.FC<Props> = ({ model }: Props) =>
  model ? (
    <CCard>
      <CCardBody>
        <h3>{formatModelNameId('Агентское вознаграждение', model.id)}</h3>

        <CRow>
          <InfoBlock label={invoiceForAgencyTranslations.creationDate} value={formatDate(model.creationDate)} />
          <InfoBlock
            label={invoiceForAgencyTranslations.invoicePeriod}
            value={`${formatDate(model.beginOfInvoicePeriod)} - ${formatDate(model.finishOfInvoicePeriod)}`}
          />
          <InfoBlock label={invoiceForAgencyTranslations.agent} value={model.agencyContract.agencyLegalPerson.name} />
          <InfoBlock
            label={invoiceForAgencyTranslations.agencyContract}
            value={`№${model.agencyContract.id} от ${formatDate(model.agencyContract.creationDate)}`}
          />
          <InfoBlock
            label={invoiceForAgencyTranslations.invitedLegalPersonIds}
            value={model.agencyContract.invitedLegalPerson.name}
          />
          <InfoBlock
            label={invoiceForAgencyTranslations.remunerationWithNds}
            value={formatCurrency(model.remunerationWithNds)}
          />
          <InfoBlock
            label={invoiceForAgencyTranslations.remunerationWithoutNds}
            value={formatCurrency(model.remunerationWithoutNds)}
          />
          <InfoBlock label={invoiceForAgencyTranslations.orderId} value={model.orderId} />
          <InfoBlock
            label={invoiceForAgencyTranslations.order.creationDate}
            value={formatDate(model.order.creationDate)}
          />
          <InfoBlock
            label={invoiceForAgencyTranslations.order.completionDate}
            value={model.order.completionDate ? formatDate(model.order.completionDate) : noData}
          />
          <InfoBlock label={invoiceForAgencyTranslations.order.serviceName} value={model.order.service.name} />
        </CRow>
      </CCardBody>
    </CCard>
  ) : (
    <h1>Сущность не найдена</h1>
  );
