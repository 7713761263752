export const clientContractTranslations = {
  route: {
    list: 'Клиентские договоры',
    create: 'Клиентский договор (создание)',
    update: 'Клиентский договор (редактирование)',
  },
  new: 'Новый клиентский договор',
  startingDate: 'Дата начала действия договора',
  completionDate: 'Дата окончания действия договора',
  numberOfContract: 'Номер',
  customer: 'Заказчик',
  rhsLegalPersonId: 'Юр. лицо (получатель)',
  lhsLegalPersonId: 'Юр. лицо (исполнитель)',
  manager: 'Аккаунт-менеджер',
  agents: 'Агенты',
  agent: 'Агент',
  methodOfInvoice: {
    title: 'Тип начисления',
    prepaid: 'Предоплата',
    postpaid: 'Постоплата',
  },
  created: 'Клиентский договор успешно создан',
  updated: 'Клиентский договор успешно обновлен',
};
