export const serviceTranslations = {
  route: {
    list: 'Сервисы',
    create: 'Сервис (создание)',
    update: 'Сервис (редактирование)',
  },
  new: 'Новый сервис',
  name: 'Название',
  customer: 'Заказчик',
  legalPerson: 'Юр. лицо',
  clientContract: 'Договор клиентский',
  agencyContract: 'Договор агентский',
  isAgencyContract: 'Агентский договор',
  agent: 'Агент',
  tariff: 'Тариф',
  accountManager: 'Аккаунт менеджер',
  created: 'Сервис успешно создан',
  updated: 'Сервис успешно обновлен',
  serviceTypeId: 'Тип сервиса',
  serviceTypeValue: 'Значение типа сервиса',
};
