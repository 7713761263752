import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SelectOptionsType } from 'components/UI/UiSelect';
import { CustomerManagersListModel } from 'models/Customer';
import { getAllCustomersManagersList } from 'api/customer';
import { getAllManagersOkAction, getAllManagersStartAction } from 'store/actions';
import { getManagersSelector } from 'store/selectors';
import { doRequest } from 'utils/doRequest';

export const useAllManagers = (): {
  managersListSelectOptions: SelectOptionsType<string>;
  managers: CustomerManagersListModel;
  loading: boolean;
} => {
  const dispatch = useDispatch();
  const { data: managers, loading } = useSelector(getManagersSelector);

  if (!loading && managers.length === 0) {
    dispatch(getAllManagersStartAction());

    doRequest(
      getAllCustomersManagersList,
      () => true
    )((data) => {
      dispatch(getAllManagersOkAction(data));
    });
  }

  const managersListSelectOptions: SelectOptionsType<string> = useMemo(
    () => managers.map(({ displayName, id }) => ({ value: id, label: displayName })),
    [managers]
  );

  return { managersListSelectOptions, managers, loading };
};
