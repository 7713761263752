import { useCallback, useEffect, useState } from 'react';
import { getAllClientContracts } from 'api/clientContract';
import { ClientContractModel } from 'models/ClientContract';
import { SelectOptionType } from 'components/UI/UiSelect';
import { formatDate } from 'utils/date';
import { doRequest } from 'utils/doRequest';

export type TSelectOptionClientContract = SelectOptionType<number> & ClientContractModel;

/** найти клиентские договоры зависящие от юл. */
export const useClientsContractsOptions = (
  mounted = true
): [
  TSelectOptionClientContract[], // Список
  (personId?: number) => void, // Функция для загрузки списка
  boolean // Индикатор загрузки
] => {
  const [list, setList] = useState<TSelectOptionClientContract[]>([]);
  const [loading, setLoading] = useState(false);
  const getList = useCallback((personId?: number) => {
    setLoading(true);
    doRequest(
      () => getAllClientContracts({ rhsLegalPersonId: personId }),
      () => mounted
    )(
      ({ data }) => {
        setList(
          data.map((item) => ({
            ...item,
            value: item.id,
            label: `${item.numberOfContract} от ${formatDate(item.startingDate)}`,
          }))
        );
      },
      () => {
        setLoading(false);
      },
      () => {
        setList([]);
      }
    );
  }, []);
  useEffect(() => {
    getList();
  }, []);

  return [list, getList, loading];
};
