import { CFormGroup, CInvalidFeedback, CLabel } from '@coreui/react';
import get from 'lodash/get';
import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Controller } from 'react-hook-form';
import MaskedTextInput from 'react-text-mask';
import localeRu from 'date-fns/locale/ru';

const getMaskInputDate = (withTime: boolean) => {
  const mask = withTime
    ? [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ':', /\d/, /\d/]
    : [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/];
  return <MaskedTextInput type="text" mask={mask} />;
};

type Props = {
  name: string;
  setValue: any;
  control: any;
  value: Date | null;

  label?: string;
  showTimeSelect?: boolean;
  maxDate?: Date;
  minDate?: Date | null;
  startDate?: Date | null;
  endDate?: Date | null;
  id?: string;
  selectsStart?: boolean;
  selectsEnd?: boolean;
  required?: boolean;
  errors?: { [s: string]: any };
  disabled?: boolean;
};

export const UiDatePicker = ({
  control,
  name,
  setValue,
  value,
  maxDate,
  showTimeSelect = false,
  id,
  startDate,
  selectsStart,
  selectsEnd,
  minDate,
  endDate,
  required,
  errors,
  label,
  disabled = false,
}: Props): React.ReactElement => {
  const error = get(errors, name, false);

  const component = (
    <Controller
      id={id}
      as={ReactDatePicker}
      control={control}
      selected={value}
      customInput={getMaskInputDate(showTimeSelect)}
      dateFormat={showTimeSelect ? 'dd.MM.yyyy HH:mm' : 'dd.MM.yyyy'}
      onChange={(d: Date) => setValue(name, d)}
      locale={localeRu}
      name={name}
      className={`form-control ${errors?.[name] ? 'border-danger' : ''}`}
      wrapperClassName="w-100"
      placeholderText="Введите дату"
      showTimeSelect={showTimeSelect}
      maxDate={maxDate}
      minDate={minDate}
      startDate={startDate}
      endDate={endDate}
      autoComplete="off"
      selectsStart={selectsStart}
      selectsEnd={selectsEnd}
      rules={{ required }}
      disabled={disabled}
    />
  );

  return label ? (
    <CFormGroup>
      <CLabel>{label}</CLabel>
      {component}
      {error && <CInvalidFeedback className="help-block">{error.message}</CInvalidFeedback>}
    </CFormGroup>
  ) : (
    component
  );
};

type ClearProps = { onChange: (d: Date) => void; value?: Date | null };

export const ClearDatePicker: React.FC<ClearProps> = ({ onChange, value: initValue }: ClearProps) => {
  const [value, setValue] = useState<Date | null>(initValue || null);

  return (
    <ReactDatePicker
      onChange={(d: Date) => {
        setValue(d);
        onChange(d);
      }}
      customInput={getMaskInputDate(false)}
      className="form-control"
      dateFormat="dd.MM.yyyy"
      locale={localeRu}
      wrapperClassName="w-100"
      placeholderText="Введите дату"
      selected={value}
    />
  );
};
