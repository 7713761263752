import { getCurrentUserRole, getIsOwner } from 'models/Auth';

/** Возвращает объект с полями которые нужно задизеблить в зависимости от прав пользователя */
export const getLegalPersonRights = (updateMode = false, managerId = ''): { [key: string]: boolean } => {
  const { isAdmin, isChiefManager } = getCurrentUserRole();
  const isOwner = getIsOwner(managerId);

  return {
    name: updateMode && !(isAdmin || isChiefManager),
    inn: updateMode && !(isAdmin || (isOwner && isChiefManager)),
    kpp: updateMode && !(isAdmin || (isOwner && isChiefManager)),
    customerId: updateMode && !(isAdmin || isChiefManager),
    manager: !(updateMode && isAdmin),
    role: updateMode && !(isAdmin || (isOwner && isChiefManager)),
  };
};
