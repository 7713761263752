/** id модели переносится в название. хелпер для удобства генерирования заголовка формы */
export const formatModelNameId = (name: string | null, id: string | number): string =>
  name ? `${name} (ID: ${id})` : `ID: ${id}`;

/**
 * форматирование чисел
 * @param {number | null} valueToFormat - искомое значение
 * @returns string
 */
export const formatCurrency = (valueToFormat: number | null): string => {
  if (typeof valueToFormat === 'number') {
    const v: string[] = valueToFormat.toFixed(2).split('.');
    return `${v[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ')},${v[1]}`;
  }
  return valueToFormat || '';
};
