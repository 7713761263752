import React from 'react';
import { CCard, CCardBody } from '@coreui/react';
import { Link } from 'react-router-dom';

import { UiListBtnNew } from 'components/UI/UiListBtnNew';
import { systemCustomerRoute } from 'containers/System/Customer/routes';
import { CustomerModel, CustomerListModel } from 'models/Customer';
import { customerTranslations } from 'translations/customer';
import { useFilter } from 'utils/hooks/useFilter';
import { getCustomersList } from 'api/customer';
import { SystemCustomerFilter } from 'components/System/Customer/filter';
import { UiTable } from 'components/UI/UiTable';
import { SelectOptionsType } from 'components/UI/UiSelect';
import { baseFinderLabel } from 'utils/listHelpers';

const fields = [
  { key: 'id', label: 'ID' },
  { key: 'name', label: customerTranslations.name },
  { key: 'isPartner', label: customerTranslations.partner },
  { key: 'manager', label: customerTranslations.manager },
];

type Props = {
  getData: typeof getCustomersList;
  loadingFilterData: boolean;
  managersListSelectOptions: SelectOptionsType<string>;
};

export const SystemCustomersListComponent = ({
  getData,
  loadingFilterData,
  managersListSelectOptions,
}: Props): React.ReactElement => {
  const { onPaginationChange, loading, model, filter, onReset, onSearch, onChangePage } = useFilter<CustomerListModel>(
    getData
  );

  return (
    <CCard>
      <CCardBody>
        <h3 className="text-center">{customerTranslations.route.list}</h3>

        <UiListBtnNew title={customerTranslations.new} to={systemCustomerRoute()} />

        {!loadingFilterData && (
          <SystemCustomerFilter
            filter={filter}
            onSearch={onSearch}
            onReset={onReset}
            managerList={managersListSelectOptions}
          />
        )}

        <UiTable
          items={model}
          fields={fields}
          loading={loading}
          pageLimit={filter.pageLimit}
          onPaginationChange={onPaginationChange}
          onChangePage={onChangePage}
          scopedSlots={{
            id: ({ id }: CustomerModel) => (
              <td>
                <Link to={systemCustomerRoute(id)}>{id}</Link>
              </td>
            ),
            isPartner: ({ isPartner }: CustomerModel) => <td>{isPartner ? 'Да' : 'Нет'}</td>,
            manager: ({ manager }: CustomerModel) => (
              <td>{baseFinderLabel(manager)(managersListSelectOptions, manager)}</td>
            ),
          }}
        />
      </CCardBody>
    </CCard>
  );
};
