import { CFormGroup, CInput, CLabel, CTextarea, innerRef } from '@coreui/react';
import React from 'react';
import get from 'lodash/get';
import { RegisterOptions } from 'react-hook-form';

export type InputTypes = 'text' | 'number' | 'textarea' | 'password';

/** html валидация для текстовых инпутов */
const getDefaultHtmlMaxLength = (type: InputTypes): number => {
  switch (type) {
    case 'number':
    case 'password':
      return 300;
    case 'text':
    case 'textarea':
      return 1000;
    default:
      return 0;
  }
};

/** значения по-умолчанию для инпута */
const getDefaultValidationByType = (type: InputTypes) => ({
  maxLength: {
    value: getDefaultHtmlMaxLength(type),
    message: 'Слишком большая длина',
  },
});

type Props = {
  register: (v?: RegisterOptions) => innerRef | undefined;
  name: string;

  value?: string | number;
  type?: InputTypes;
  required?: boolean; // сокращение от validation={{ required: true }}
  validation?: RegisterOptions; // объект валидации
  maxLength?: number;
  minLength?: number;
  errors?: unknown; // объект с ошибками
  min?: number; // мин. значение для type="number"
  max?: number; // макс. значение для type="number"
  rows?: number;

  // https://stackoverflow.com/questions/1355728/values-of-disabled-inputs-will-not-be-submitted
  disabled?: boolean;
  readOnly?: boolean;
};

export const UiStandardInput = ({
  register,
  name,
  required = false,
  type = 'text',
  validation,
  maxLength,
  minLength,
  errors,
  min,
  max,
  value,
  rows = 5,
  disabled = false,
  readOnly = false,
}: Props): React.ReactElement => {
  const error = get(errors, name, false);

  const formgroupClassName = [];

  const localValidation: RegisterOptions = {
    ...getDefaultValidationByType(type),
    ...validation,
  };
  if (required) {
    localValidation.required = 'Обязательное поле';

    /** доп.проверка для текстовых инпутов на пустоту */
    if (['text', 'textarea', 'password'].includes(type)) {
      /** добавить в объект новый валидатор. не использовать функцию, т.к. перетирает предыдущее значение */
      localValidation.validate = {
        ...localValidation.validate,
        emptyStrNotAllowed: (text: string) => (text.trim().length === 0 ? 'Обязательное поле' : true),
      };
    }

    formgroupClassName.push('required');
  }
  if (maxLength) {
    localValidation.maxLength = {
      value: maxLength,
      message: 'Слишком большая длина',
    };
  }
  if (minLength) {
    localValidation.minLength = {
      value: minLength,
      message: 'Слишком маленькая длина',
    };
  }

  return (
    <>
      {type === 'textarea' ? (
        <CTextarea
          value={value}
          invalid={!!error}
          innerRef={register(localValidation)}
          name={name}
          rows={rows}
          maxLength={get(localValidation, 'maxLength.value', getDefaultHtmlMaxLength(type))}
          minLength={get(localValidation, 'minLength.value', 0)}
          disabled={disabled}
          readOnly={readOnly}
        />
      ) : (
        <CInput
          value={value}
          invalid={!!error}
          innerRef={register(localValidation)}
          name={name}
          type={type}
          min={min}
          max={max}
          rows={rows}
          step={0.1}
          maxLength={get(localValidation, 'maxLength.value', getDefaultHtmlMaxLength(type))}
          minLength={get(localValidation, 'minLength.value', 0)}
          disabled={disabled}
          readOnly={readOnly}
        />
      )}
      {/** подтекст немного ломает отображение инпутов */}
      {/* {error && <CInvalidFeedback className="help-block">{error.message}</CInvalidFeedback>}*/}
    </>
  );
};

type UiInputProps = {
  label: string;
  append?: string;
  wrapperClassName?: string;
  helpText?: string;
} & Props;

export const UiInput = ({ label, helpText, ...props }: UiInputProps): React.ReactElement => (
  <CFormGroup>
    <CLabel>{label}</CLabel>
    <UiStandardInput {...props} />
    {helpText && <small className="form-text text-muted">{helpText}</small>}
  </CFormGroup>
);
