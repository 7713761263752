import { CButton, CCard, CCardBody, CCol, CForm, CRow } from '@coreui/react';
import { getDataByInn } from 'api/legalPerson';
import { getLegalPersonRights } from 'components/System/LegalPerson/formHelpers';
import { UiRowCheckbox } from 'components/UI/UiCheckbox';
import { UiInput } from 'components/UI/UiInput';
import { UiListBtnNew } from 'components/UI/UiListBtnNew';
import { SelectOptionsType, SelectOptionType, UiSelect } from 'components/UI/UiSelect';
import { UiSelectCustomerModal } from 'components/UI/UiSelectCustomerModal';
import { UiSubmitButton } from 'components/UI/UiSubmitButton';
import { UiTabs } from 'components/UI/UiTabs';
import { SystemClientContractList } from 'containers/System/ClientContract/list';
import { systemClientContractRoute } from 'containers/System/ClientContract/routes';
import isEqual from 'lodash/isEqual';
import { LegalPersonFormModel, LegalPersonModel, OrganizationOptions, OrganizationsType } from 'models/LegalPerson';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getManagerId } from 'store/selectors';
import { clientContractTranslations } from 'translations/clientContract';
import { legalPersonTranslations } from 'translations/legalPerson';
import { formatModelNameId } from 'utils/formatters';
import { prepareFormData } from 'utils/formFilter';
import { baseFinder } from 'utils/listHelpers';

type Props = {
  customerList: SelectOptionsType<number>;
  saveLoading: boolean;
  model: LegalPersonModel | null;
  onSave: (data: LegalPersonFormModel, createCustomer: boolean) => void;
  modal: boolean;
  clientContractsAmount: { lhs: number; rhs: number };
  managerList: SelectOptionsType<string>;
};

type FormValues = {
  name: string;
  inn: number;
  kpp: number | undefined;
  customerId: SelectOptionType<number> | null;
  isRecipient: boolean;
  isExecutor: boolean;
  isAgent: boolean;
  isNDSPayer: boolean;
  manager: SelectOptionType<string> | null;
  organizationType: SelectOptionType<OrganizationsType> | null;
  createCustomer: boolean;
};

export const SystemLegalPersonFormComponent = ({
  model,
  customerList,
  managerList,
  saveLoading,
  onSave,
  modal,
  clientContractsAmount,
}: Props): React.ReactElement => {
  const managerId = useSelector(getManagerId);
  const updateMode = !!model?.id;

  const initValues = useMemo(
    () => ({
      name: model?.name || '',
      inn: model?.inn || undefined,
      kpp: model?.kpp || undefined,
      customerId: baseFinder(customerList, model?.customerId) || undefined,
      isRecipient: model?.isRecipient ?? true,
      isExecutor: model?.isExecutor ?? false,
      isAgent: model?.isAgent ?? false,
      isNDSPayer: model?.isNDSPayer ?? false,
      manager: model?.manager ? baseFinder(managerList, model.manager) : baseFinder(managerList, managerId),
      createCustomer: false,
      organizationType: (() => {
        const found = OrganizationOptions.find(({ value }) => value === model?.organizationType);

        return found || undefined;
      })(),
    }),
    []
  );

  const { register, handleSubmit, errors, watch, setValue, setError, getValues } = useForm<FormValues>({
    defaultValues: initValues,
  });

  const disabledToSave = (() => {
    if (updateMode) {
      // удалить лишние (или сложные) для проверки данные
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { organizationType, createCustomer, ...initVals } = initValues;

      return isEqual(
        { ...initVals, organizationType: model?.organizationType },
        {
          name: watch('name'),
          inn: watch('inn'),
          kpp: watch('kpp'),
          customerId: watch('customerId'),
          isRecipient: watch('isRecipient'),
          isExecutor: watch('isExecutor'),
          isAgent: watch('isAgent'),
          isNDSPayer: watch('isNDSPayer'),
          manager: watch('manager'),
          organizationType: watch('organizationType')?.value,
        }
      );
    }
    return false;
  })();

  const rights = getLegalPersonRights(updateMode, model?.manager);
  const isExecutorChecked = watch('isExecutor');

  useEffect(() => {
    if (!isExecutorChecked) {
      setValue('isNDSPayer', false);
    }
  }, [isExecutorChecked]);

  const onSubmit = (data: FormValues) => {
    if (!disabledToSave) {
      if (!data.createCustomer && !data.customerId) {
        setError('customerId', {});
        return;
      }

      const modelToSave: LegalPersonFormModel = {
        id: model?.id,
        name: data.name,
        inn: data.inn,
        kpp: data.kpp || undefined,
        customerId: data.customerId?.value || 0,
        isRecipient: data.isRecipient,
        isExecutor: data.isExecutor,
        isAgent: data.isAgent,
        isNDSPayer: data.isExecutor ? data.isNDSPayer : undefined,
        manager: updateMode ? data.manager?.value : undefined,
        organizationType: (data.organizationType?.value || undefined) as OrganizationsType,
      };

      /** проверка наличия хотя бы одной роли */
      if (!modelToSave.isRecipient && !modelToSave.isAgent && !modelToSave.isExecutor && !modelToSave.isNDSPayer) {
        toast.warn(legalPersonTranslations.rolesRequired);
      } else {
        onSave(prepareFormData(modelToSave), data.createCustomer);
      }
    }
  };

  const hasClientContracts = !(clientContractsAmount.lhs === 0 && clientContractsAmount.rhs === 0);

  const [gettingData, setGettingData] = useState(false);
  const onGetDataByInn = useCallback(() => {
    const inn = getValues('inn');

    if (inn) {
      setGettingData(true);

      getDataByInn(inn)
        .then(({ kpp, organizationType, shortOrganizationName }) => {
          if (kpp) {
            setValue('kpp', kpp);
          }

          if (shortOrganizationName) {
            setValue('name', shortOrganizationName);
          }

          const found = OrganizationOptions.find(({ value }) => value === organizationType);
          if (found) {
            setValue('organizationType', found);
          }
        })
        .finally(() => {
          setGettingData(false);
        });
    }
  }, []);

  const component = (
    <>
      <CRow>
        <CCol xs="12" sm="6" md="4">
          <UiInput
            type="number"
            minLength={10}
            maxLength={12}
            register={register}
            name="inn"
            label={legalPersonTranslations.inn}
            errors={errors}
            required
            disabled={hasClientContracts || rights.inn}
          />
        </CCol>
        <CCol xs="12" sm="6" md="4" style={{ marginTop: 29 }}>
          {!updateMode && (
            <CButton
              block
              color="primary"
              type="button"
              onClick={onGetDataByInn}
              disabled={gettingData || !watch('inn')}
            >
              {gettingData ? 'Идет загрузка' : 'Получить данные'}
            </CButton>
          )}
        </CCol>
      </CRow>
      <CRow>
        <CCol xs="12" sm="6" md="4">
          <UiInput
            register={register}
            name="name"
            label={legalPersonTranslations.name}
            errors={errors}
            required
            disabled={rights.name}
          />
        </CCol>

        <CCol xs="12" sm="6" md="4">
          <UiInput
            type="number"
            minLength={9}
            maxLength={9}
            register={register}
            name="kpp"
            label={legalPersonTranslations.kpp}
            errors={errors}
            disabled={hasClientContracts || rights.kpp}
          />
        </CCol>
        <CCol xs="12" sm="6" md="4">
          <UiSelect
            label={legalPersonTranslations.organizationType}
            register={register}
            name="organizationType"
            options={OrganizationOptions}
            setValue={setValue}
            value={watch('organizationType')}
            errors={errors}
            required
          />
        </CCol>
        <CCol xs="12" sm="6" md="4">
          <UiSelectCustomerModal
            register={register}
            name="customerId"
            options={customerList}
            setValue={setValue}
            value={watch('customerId')}
            errors={errors}
            modal={modal}
            required={false}
            allowEmptyValue
            disabled={rights.customerId || watch('createCustomer')}
          />
        </CCol>
        {!updateMode && (
          <CCol xs="12" sm="6" md="4">
            <UiRowCheckbox
              register={register}
              name="createCustomer"
              label={legalPersonTranslations.createCustomer}
              className="mt-2rem"
            />
          </CCol>
        )}
      </CRow>
      <CRow>
        <CCol xs="12" sm="6" md="4">
          <UiSelect
            label={legalPersonTranslations.manager}
            register={register}
            name="manager"
            options={managerList}
            setValue={setValue}
            value={watch('manager')}
            errors={errors}
            required
            disabled={rights.manager}
          />
        </CCol>
      </CRow>

      <h3>Роли</h3>
      <UiRowCheckbox
        register={register}
        name="isRecipient"
        label={legalPersonTranslations.role.isRecipient}
        disabled={rights.role}
      />
      <CRow>
        <CCol xs={3}>
          <UiRowCheckbox
            register={register}
            name="isExecutor"
            label={legalPersonTranslations.role.isExecutor}
            disabled={hasClientContracts || rights.role}
          />
        </CCol>
        <CCol>
          <UiRowCheckbox
            register={register}
            name="isNDSPayer"
            label={legalPersonTranslations.role.isNDSPayer}
            disabled={rights.role}
          />
        </CCol>
      </CRow>
      <UiRowCheckbox
        register={register}
        name="isAgent"
        label={legalPersonTranslations.role.isAgent}
        disabled={rights.role}
      />
      <UiSubmitButton saving={saveLoading} disabled={disabledToSave} />
    </>
  );

  return (
    <CForm onSubmit={handleSubmit(onSubmit)}>
      {modal ? (
        component
      ) : (
        <CCard>
          <CCardBody>
            <h3>{model?.id ? formatModelNameId(model.name, model.id) : legalPersonTranslations.route.create}</h3>

            {model?.id ? (
              <UiTabs
                items={{
                  index: {
                    title: legalPersonTranslations.tab.index,
                    component: () => (
                      <CRow>
                        <CCol xs={9}>{component}</CCol>
                      </CRow>
                    ),
                  },
                  clientContractsWherePersonRhs: {
                    title: legalPersonTranslations.tab.clientContractsWherePersonRhs,
                    component: () => (
                      <SystemClientContractList lhsLegalPersonId={model?.id}>
                        <UiListBtnNew title={clientContractTranslations.new} to={systemClientContractRoute()} />
                      </SystemClientContractList>
                    ),
                    show: clientContractsAmount.lhs !== 0,
                  },
                  clientContractsWherePersonLhs: {
                    title: legalPersonTranslations.tab.clientContractsWherePersonLhs,
                    component: () => (
                      <SystemClientContractList rhsLegalPersonId={model?.id}>
                        <UiListBtnNew title={clientContractTranslations.new} to={systemClientContractRoute()} />
                      </SystemClientContractList>
                    ),
                    show: clientContractsAmount.rhs !== 0,
                  },
                }}
              />
            ) : (
              <CRow>
                <CCol xs={9}>{component}</CCol>
              </CRow>
            )}
          </CCardBody>
        </CCard>
      )}
    </CForm>
  );
};
