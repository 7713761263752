import { getAgencyContract } from 'api/agencyContract';
import isNaN from 'lodash/isNaN';
import toNumber from 'lodash/toNumber';
import { AgencyContractModel } from 'models/AgencyContract';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getInvoiceOnOrder } from 'api/invoiceOnOrder';
import { UiLoader } from 'components/UI/UiLoader';
import { SystemInvoiceOnOrderFormComponent } from 'components/System/InvoiceOnOrder/form';
import { InvoiceOnOrderType } from 'models/InvoiceOnOrder';
import { ClientContractModel } from 'models/ClientContract';
import { getClientContract } from 'api/clientContract';
import { getAllLegalPersonsList } from 'api/legalPerson';
import { SelectOptionsType } from 'components/UI/UiSelect';
import { doRequest } from 'utils/doRequest';

let mounted = false;

export const SystemInvoiceOnOrderForm: React.FC = () => {
  useEffect(() => {
    mounted = true;
    return () => {
      mounted = false;
    };
  }, []);

  const { id } = useParams<{ id?: string }>();
  const numberId = toNumber(id);

  const [model, setModel] = useState<InvoiceOnOrderType | null>(null);
  const [loading, setLoading] = useState(true);

  const [clientContract, setClientContract] = useState<ClientContractModel | null>(null);
  const [loadingContract, setLoadingContract] = useState(false);

  const [agencyContract, setAgencyContract] = useState<AgencyContractModel | null>(null);
  const [loadingAgencyContract, setLoadingAgencyContract] = useState(false);

  const [legalPersonsExecutorSelectOptions, setLegalPersonsExecutor] = useState<SelectOptionsType<number>>([]);
  const [legalPersonsLoading, setLegalPersonsLoading] = useState(false);

  useEffect(() => {
    if (!isNaN(numberId)) {
      setLegalPersonsLoading(true);

      doRequest(
        () => getAllLegalPersonsList({ isExecutor: true }),
        () => mounted
      )(
        (executors) => {
          setLegalPersonsExecutor(executors.data.map((executor) => ({ value: executor.id, label: executor.name })));
        },
        () => {
          setLegalPersonsLoading(false);
        }
      );

      doRequest(
        () => getInvoiceOnOrder(numberId),
        () => mounted
      )(
        (response) => {
          setModel(response);

          setLoadingContract(true);

          doRequest(
            () => getClientContract(response.order.service.clientContractId),
            () => mounted
          )(setClientContract, () => {
            setLoadingContract(false);
          });

          if (response.order.service.agencyContractId) {
            setLoadingAgencyContract(true);

            doRequest(
              () => getAgencyContract(response.order.service.agencyContractId),
              () => mounted
            )(setAgencyContract, () => {
              setLoadingAgencyContract(false);
            });
          } else {
            setLoadingAgencyContract(false);
          }
        },
        () => {
          setLoading(false);
        }
      );
    } else {
      setLoading(false);
    }
  }, [numberId]);

  return loading || loadingContract || legalPersonsLoading || loadingAgencyContract ? (
    <UiLoader />
  ) : (
    <SystemInvoiceOnOrderFormComponent
      model={model}
      clientContract={clientContract}
      agencyContract={agencyContract}
      legalPersonsExecutorSelectOptions={legalPersonsExecutorSelectOptions}
    />
  );
};
