import { CButton, CDataTable } from '@coreui/react';
import { RawUiFormatNumber } from 'components/UI/UiFormatNumber';
import React, { memo } from 'react';

import { ProductModel } from 'models/Product';
import { productTranslations } from 'translations/product';
import { tariffTranslations } from 'translations/tariff';
import { TariffProductInfoType } from 'models/Tariff';

const fields = [
  { key: 'id', label: 'ID' },
  { key: 'name', label: productTranslations.name },
  { key: 'costPrice', label: productTranslations.costPrice },
  { key: 'unitOfMeasure', label: productTranslations.unitOfMeasure },
  { key: 'priceWithoutNds', label: tariffTranslations.priceWithoutNds },
  { key: 'priceWithNds', label: tariffTranslations.priceWithNds },
  { key: 'actions', label: '' },
];

type Props = {
  items: ProductModel[];
  onRemove: (id: number) => void;
  onNDSChange: (data: TariffProductInfoType) => void;
  ndsInfo: TariffProductInfoType[];
  disabledRemove?: boolean;
  nds: number;
};

export const SystemTariffProductTableComponent = memo(
  ({ items, onRemove, onNDSChange, ndsInfo, disabledRemove = false, nds }: Props): React.ReactElement | null => {
    const onBlur = (id: number, ndsWith: boolean) => (floatValue: number | undefined) => {
      let priceWithoutNds: number | string;
      let priceWithNds: number | string;

      if (ndsWith) {
        priceWithNds = floatValue || 0;
        priceWithoutNds = priceWithNds - (priceWithNds / (nds / 100 + 1) - priceWithNds) * -1;
        if (priceWithoutNds) {
          priceWithoutNds = priceWithoutNds.toFixed(2);
        }
      } else {
        priceWithoutNds = floatValue || 0;
        priceWithNds = priceWithoutNds + (priceWithoutNds * nds) / 100;
        if (priceWithNds) {
          priceWithNds = priceWithNds.toFixed(2);
        }
      }

      onNDSChange({
        productId: id,
        priceWithoutNds: (priceWithoutNds || '') as number, // string
        priceWithNds: (priceWithNds || '') as number, // string
      });
    };

    return items.length ? (
      <CDataTable
        items={items}
        fields={fields}
        responsive
        striped
        border
        scopedSlots={{
          priceWithoutNds: ({ id }: ProductModel) => (
            <td>
              <RawUiFormatNumber
                onValueChange={({ floatValue }) => {
                  if (ndsInfo.find(({ productId }) => productId === id)?.priceWithoutNds !== floatValue) {
                    onBlur(id, false)(floatValue);
                  }
                }}
                value={ndsInfo.find(({ productId }) => productId === id)?.priceWithoutNds}
              />
            </td>
          ),
          priceWithNds: ({ id }: ProductModel) => (
            <td>
              <RawUiFormatNumber
                onValueChange={({ floatValue }) => {
                  if (ndsInfo.find(({ productId }) => productId === id)?.priceWithNds !== floatValue) {
                    onBlur(id, true)(floatValue);
                  }
                }}
                value={ndsInfo.find(({ productId }) => productId === id)?.priceWithNds}
              />
            </td>
          ),
          actions: ({ id }: ProductModel) => (
            <td>
              <CButton
                block
                color="danger"
                onClick={() => {
                  onRemove(id);
                }}
                disabled={disabledRemove}
              >
                X
              </CButton>
            </td>
          ),
        }}
      />
    ) : null;
  }
);
