import { maxValueToGetAllDataWithPagination } from 'config';
import {
  AgencyContractFilterType,
  AgencyContractFormType,
  AgencyContractListModel,
  AgencyContractModel,
} from 'models/AgencyContract';
import { httpGet, httpPost, httpPut } from 'utils/http';

const path = '/admin/agency-contracts';

/** создать клиентский договор */
export const createAgencyContract = (model: AgencyContractFormType): Promise<AgencyContractModel> =>
  httpPost(`${path}/agency-contract`, model);

/** обновить клиентский договор */
export const updateAgencyContract = (id: number, model: AgencyContractFormType): Promise<AgencyContractModel> =>
  httpPut(`${path}/agency-contract/${id}`, model);

/** получить клиентский договор */
export const getAgencyContract = (id: number): Promise<AgencyContractModel> => httpGet(`${path}/agency-contract/${id}`);

/** получить список клиентских договоров */
export const getAgencyContractList = (filter: AgencyContractFilterType): Promise<AgencyContractListModel> =>
  httpGet(`${path}/filter`, filter);

/** получить все агентские договоры */
export const getAllAgencyContract = (filter: AgencyContractFilterType = {}): Promise<AgencyContractListModel> =>
  getAgencyContractList({ ...filter, pageLimit: maxValueToGetAllDataWithPagination });
