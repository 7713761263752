import { maxValueToGetAllDataWithPagination } from 'config';
import {
  ProductCreateType,
  ProductListFilterType,
  ProductListModel,
  ProductModel,
  ProductUpdateType,
} from 'models/Product';
import { httpGet, httpPost, httpPut } from 'utils/http';

const path = '/admin/products';

/** создать услугу */
export const createProduct = (model: ProductCreateType): Promise<ProductModel> => httpPost(`${path}/product`, model);

/** обновить услугу */
export const updateProduct = (id: number, model: ProductUpdateType): Promise<ProductModel> =>
  httpPut(`${path}/product/${id}`, model);

/** получить услугу */
export const getProduct = (id: number): Promise<ProductModel> => httpGet(`${path}/product/${id}`);

/** получить список услуг */
export const getProductList = (filter?: ProductListFilterType): Promise<ProductListModel> =>
  httpGet(`${path}/filter`, filter);

/** получить список всех услуг */
export const getAllProductList = (filter?: ProductListFilterType): Promise<ProductListModel> =>
  httpGet(`${path}/filter`, { ...filter, pageLimit: maxValueToGetAllDataWithPagination });
