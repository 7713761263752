import isNaN from 'lodash/isNaN';
import toNumber from 'lodash/toNumber';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { createOrder, getOrder, updateOrder } from 'api/order';
import { getService } from 'api/service';
import { getTariff } from 'api/tariff';
import { SystemOrderFormComponent } from 'components/System/Order/form';
import { UiLoader } from 'components/UI/UiLoader';
import { SelectOptionsType } from 'components/UI/UiSelect';
import { ExtendedProductModel, ProductTypesEnum } from 'models/Product';
import { ServiceModel } from 'models/Service';
import { TariffModel } from 'models/Tariff';
import {
  getAllClientContractsStartAction,
  getAllCustomersStartAction,
  getAllLegalPersonsStartAction,
  getAllServicesStartAction,
} from 'store/actions';
import {
  getClientContractsSelector,
  getCustomersSelector,
  getLegalPersonsSelector,
  getServicesSelector,
} from 'store/selectors';
import { orderTranslations } from 'translations/order';
import { doRequest } from 'utils/doRequest';
import { OrderCreateType, OrderUpdateType } from 'models/Order';
import { useAllManagers } from 'utils/hooks/useAllManagers';
import { useRequest } from 'utils/hooks/useRequest';

const emptyProductsValue = {
  subscrProducts: [],
  razProducts: [],
};

let mounted = false;

export const SystemOrderForm: React.FC = () => {
  useEffect(() => {
    mounted = true;
    return () => {
      mounted = false;
    };
  }, []);

  const { managersListSelectOptions, loading: loadManagers } = useAllManagers();
  const [legalPersonsRecipientsSelectOptions] = useState<SelectOptionsType<number>>([]);

  const query = useParams<{ id?: string }>();
  const numberId = toNumber(query.id);

  const getData = useCallback(() => (isNaN(numberId) ? null : getOrder(numberId)), [numberId]);
  const { model: initModel, loading } = useRequest(getData, null, () => mounted);

  const [key, setKey] = useState(1);
  const [model, setModel] = useState(initModel);
  useEffect(() => {
    setModel(initModel);
  }, [initModel]);

  /** найти заказчиков */
  const dispatch = useDispatch();
  const customers = useSelector(getCustomersSelector);
  useEffect(() => {
    dispatch(getAllCustomersStartAction());
  }, []);

  /** найти юр.лиц, зависящих от заказчика */
  const legalPersonsByCustomerId = useSelector(getLegalPersonsSelector);
  const findLegalPersonsByCustomer = useCallback(
    (customerId: number) => {
      dispatch(getAllLegalPersonsStartAction({ customerId, isRecipient: true }));
    },
    [dispatch]
  );

  /** найти клиентские договоры, зависящие от юр.лица, где юр.лицо - получатель */
  const clientContractsSelectOptionsByLegalPerson = useSelector(getClientContractsSelector);
  const findClientContractsByLegalPerson = useCallback(
    (rhsLegalPersonId: number) => {
      dispatch(getAllClientContractsStartAction({ rhsLegalPersonId }));
    },
    [dispatch]
  );

  /** найти сервисы, зависящие от клиентского договора */
  const { options: servicesSelectOptions, loading: servicesSelectOptionsLoading } = useSelector(getServicesSelector);
  const findServiceByClientContracts = useCallback(
    (clientContractId: number) => {
      dispatch(getAllServicesStartAction({ clientContractIds: [clientContractId] }));
    },
    [dispatch]
  );

  /** найти тариф->продукты по ид сервиса */
  const [products, setProducts] = useState<{
    subscrProducts: ExtendedProductModel[];
    razProducts: ExtendedProductModel[];
  }>(emptyProductsValue);
  const [productsLoading, setProductsLoading] = useState(false);

  const findProductsByServiceId = useCallback((serviceId?: number | null) => {
    if (serviceId) {
      setProductsLoading(true);

      doRequest(
        () => getService(serviceId).then(({ tariffId }: ServiceModel) => getTariff(tariffId)),
        () => mounted
      )(
        ({ tariffPositions }: TariffModel) => {
          setProducts({
            subscrProducts: tariffPositions
              .filter((m) => m.product.type === ProductTypesEnum.SUBSCRIPTION)
              .map((m) => ({
                ...m.product,
                tariffPositionId: m.id,
                priceWithNds: m.priceWithNds,
                priceWithoutNds: m.priceWithoutNds,
                volume: null,
                discount: null,
                percentageOfAgencyRemuneration: null,
                isInvoiceCreated: null,
                providingDate: null,
              })),
            razProducts: tariffPositions
              .filter((m) => m.product.type === ProductTypesEnum.LUMP_SUM)
              .map((m) => ({
                ...m.product,
                tariffPositionId: m.id,
                priceWithNds: m.priceWithNds,
                priceWithoutNds: m.priceWithoutNds,
                volume: null,
                discount: null,
                percentageOfAgencyRemuneration: null,
                isInvoiceCreated: null,
                providingDate: null,
              })),
          });
        },
        () => {
          setProductsLoading(false);
        },
        () => {
          setProducts(emptyProductsValue);
        }
      );
    } else {
      setProducts(emptyProductsValue);
    }
  }, []);

  const [saveLoading, setSaveLoading] = useState(false);
  const onSave = useCallback((modelToSave: OrderCreateType | OrderUpdateType) => {
    setSaveLoading(true);
    const updateMode = !!modelToSave?.id;

    const apiFn = updateMode
      ? () => updateOrder(modelToSave.id as number, modelToSave as OrderUpdateType)
      : () => createOrder(modelToSave as OrderCreateType);

    doRequest(apiFn, () => mounted)(
      (savedItem) => {
        getOrder(savedItem.id).then((response) => {
          setModel(response);
          setKey((k) => k + 1);
        });

        toast.success(updateMode ? orderTranslations.updated : orderTranslations.created);
      },
      () => {
        setSaveLoading(false);
      }
    );
  }, []);

  return customers.loading || loading || loadManagers ? (
    <UiLoader />
  ) : (
    <SystemOrderFormComponent
      key={key}
      model={model}
      onSave={onSave}
      saveLoading={saveLoading}
      customerList={customers.options}
      subscrProducts={products.subscrProducts}
      razProducts={products.razProducts}
      legalPersonsFoundByCustomerId={legalPersonsByCustomerId.options}
      legalPersonsFoundByCustomerIdLoading={legalPersonsByCustomerId.loading}
      findLegalPersonsByCustomer={findLegalPersonsByCustomer}
      legalPersonsAllListSelectOptions={legalPersonsRecipientsSelectOptions}
      clientContractsSelectOptionsByLegalPerson={clientContractsSelectOptionsByLegalPerson.options}
      clientContractsSelectOptionsByLegalPersonLoading={clientContractsSelectOptionsByLegalPerson.loading}
      findClientContractsByLegalPerson={findClientContractsByLegalPerson}
      servicesSelectOptions={servicesSelectOptions}
      servicesSelectOptionsLoading={servicesSelectOptionsLoading}
      findServiceByClientContracts={findServiceByClientContracts}
      findProductsByServiceId={findProductsByServiceId}
      productsLoading={productsLoading}
      managerList={managersListSelectOptions}
    />
  );
};
