import { CCard, CCardBody } from '@coreui/react';
import React from 'react';
import { Link } from 'react-router-dom';

import { UiTable } from 'components/UI/UiTable';
import { systemInvoiceOnOrderRoute } from 'containers/System/InvoiceOnOrder/routes';
import { getInvoiceOnOrderList } from 'api/invoiceOnOrder';
import { InvoiceOnOrderListType, InvoiceOnOrderType } from 'models/InvoiceOnOrder';
import { invoiceOnOrderTranslations } from 'translations/invoiceOnOrder';
import { formatDate } from 'utils/date';
import { formatCurrency } from 'utils/formatters';
import { useFilter } from 'utils/hooks/useFilter';
import { SystemInvoiceOnOrderFilter } from 'components/System/InvoiceOnOrder/filter';
import { SelectOptionsType } from 'components/UI/UiSelect';

const fields = [
  { key: 'id', label: 'ID' },
  { key: 'creationDate', label: invoiceOnOrderTranslations.creationDate },
  { key: 'invoicePeriod', label: invoiceOnOrderTranslations.invoicePeriod },
  { key: 'customer', label: invoiceOnOrderTranslations.customer },
  { key: 'legalPerson', label: invoiceOnOrderTranslations.legalPerson },
  { key: 'service', label: invoiceOnOrderTranslations.service },
  { key: 'costWithNds', label: invoiceOnOrderTranslations.costWithNds },
  { key: 'costWithoutNds', label: invoiceOnOrderTranslations.costWithoutNds },
  { key: 'isReversal', label: invoiceOnOrderTranslations.isReversal },
];

type Props = {
  getData: typeof getInvoiceOnOrderList;
  customerOptionsList: SelectOptionsType<number>;
  legalPersonsOptionsList: SelectOptionsType<number>;
  clientContractsOptionsList: SelectOptionsType<number>;
  servicesOptionsList: SelectOptionsType<number>;
  ordersOptionsList: SelectOptionsType<number>;
};

export const SystemInvoiceOnOrderListComponent: React.FC<Props> = ({
  getData,
  customerOptionsList,
  legalPersonsOptionsList,
  clientContractsOptionsList,
  servicesOptionsList,
  ordersOptionsList,
}: Props) => {
  const {
    onPaginationChange,
    loading,
    model,
    filter,
    onReset,
    onSearch,
    onChangePage,
  } = useFilter<InvoiceOnOrderListType>(getData);

  return (
    <CCard>
      <CCardBody>
        <h3 className="text-center">{invoiceOnOrderTranslations.route.list}</h3>

        <SystemInvoiceOnOrderFilter
          onSearch={onSearch}
          onReset={onReset}
          customerList={customerOptionsList}
          legalPersonsOptionsList={legalPersonsOptionsList}
          clientContractsOptionsList={clientContractsOptionsList}
          servicesOptionsList={servicesOptionsList}
          ordersOptionsList={ordersOptionsList}
        />

        <UiTable
          items={model}
          fields={fields}
          loading={loading}
          pageLimit={filter.pageLimit}
          onChangePage={onChangePage}
          scopedSlots={{
            id: ({ id }: InvoiceOnOrderType) => (
              <td>
                <Link to={systemInvoiceOnOrderRoute(id)}>{id}</Link>
              </td>
            ),
            creationDate: ({ creationDate }: InvoiceOnOrderType) => <td>{formatDate(creationDate)}</td>,
            invoicePeriod: ({ beginOfInvoicePeriod, finishOfInvoicePeriod }: InvoiceOnOrderType) => (
              <td>
                {formatDate(beginOfInvoicePeriod)} - {formatDate(finishOfInvoicePeriod)}
              </td>
            ),
            customer: ({ legalPerson }: InvoiceOnOrderType) => <td>{legalPerson.customer.name}</td>,
            legalPerson: ({ legalPerson }: InvoiceOnOrderType) => <td>{legalPerson.name}</td>,
            service: ({ order }: InvoiceOnOrderType) => <td>{order.service.name}</td>,
            costWithNds: ({ costWithNds }: InvoiceOnOrderType) => <td>{formatCurrency(costWithNds)}</td>,
            costWithoutNds: ({ costWithoutNds }: InvoiceOnOrderType) => <td>{formatCurrency(costWithoutNds)}</td>,
            isReversal: ({ isReversal }: InvoiceOnOrderType) => <td>{isReversal ? 'Да' : 'Нет'}</td>,
          }}
          onPaginationChange={onPaginationChange}
        />
      </CCardBody>
    </CCard>
  );
};
