/**
 * простое выполнение промиса с отслеживанием жизни вызвавшего компонента
 * mounted передавать как функцию, т.к. в таком случае он всегда будет актуален
 *
 * catch использовать для отслеживания факта ошибки, т.к. текст ошибки стандартно выводится в interceptors
 */
export const doRequest = <T>(fnToCall: () => Promise<T>, mounted: () => boolean) => (
  thenFn?: (response: T) => void,
  finallyFn?: () => void,
  catchFn?: (e: Error) => void
): void => {
  fnToCall()
    .then((response) => {
      if (mounted() && thenFn) {
        thenFn(response);
      }
    })
    .catch((e) => {
      if (mounted() && catchFn) {
        catchFn(e);
      }
    })
    .finally(() => {
      if (mounted() && finallyFn) {
        finallyFn();
      }
    });
};
