import { CCard, CCardBody, CCol, CForm, CRow } from '@coreui/react';
import isEqual from 'lodash/isEqual';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { UiRowCheckbox } from 'components/UI/UiCheckbox';
import { UiInput } from 'components/UI/UiInput';
import { UiSubmitButton } from 'components/UI/UiSubmitButton';
import { ServiceTypeFormType, ServiceTypeModel } from 'models/ServiceType';
import { serviceTypeTranslations } from 'translations/serviceType';
import { formatModelNameId } from 'utils/formatters';

type Props = {
  model: ServiceTypeModel | null;
  onSave: (d: ServiceTypeFormType) => void;
  saveLoading: boolean;
  modal: boolean;
};

type FormValues = {
  isUrlActive: boolean;
  prefix: string;
  name: string;
};

export const SystemServiceTypeFormComponent: React.FC<Props> = ({ model, onSave, saveLoading, modal }: Props) => {
  const updateMode = !!model?.id;

  const initValues = useMemo(
    () => ({
      isUrlActive: model?.isUrlActive || false,
      prefix: model?.prefix || '',
      name: model?.name || '',
    }),
    []
  );

  const { register, handleSubmit, errors, watch } = useForm<FormValues>({
    defaultValues: initValues,
  });

  const disabledToSave = updateMode
    ? isEqual(initValues, {
        isUrlActive: watch('isUrlActive'),
        prefix: watch('prefix'),
        name: watch('name'),
      })
    : false;

  const onSubmit = (data: FormValues) => {
    if (!disabledToSave) {
      onSave(data);
    }
  };

  const component = (
    <>
      <CRow className="mt-3">
        <CCol md={modal ? 3 : 2} xl={modal ? 2 : 1}>
          <UiRowCheckbox
            className="mt-4"
            register={register}
            label={serviceTypeTranslations.isUrlActive}
            name="isUrlActive"
          />
        </CCol>

        <CCol xs="12" sm="6" md="4">
          <UiInput register={register} label={serviceTypeTranslations.name} name="name" errors={errors} required />
        </CCol>

        <CCol xs="12" sm="6" md="4">
          <UiInput register={register} label={serviceTypeTranslations.prefix} name="prefix" errors={errors} required />
        </CCol>
      </CRow>

      <CRow>
        <CCol xs="12" sm="6" md="4">
          <UiSubmitButton saving={saveLoading} disabled={disabledToSave} />
        </CCol>
      </CRow>
    </>
  );

  return (
    <CForm onSubmit={handleSubmit(onSubmit)}>
      {modal ? (
        component
      ) : (
        <CCard>
          <CCardBody>
            <h3>{model?.id ? formatModelNameId(null, model.id) : serviceTypeTranslations.route.create}</h3>

            {component}
          </CCardBody>
        </CCard>
      )}
    </CForm>
  );
};
