import React from 'react';
import { CCard, CCardBody } from '@coreui/react';
import { Link } from 'react-router-dom';

import { getServiceList } from 'api/service';
import { UiTable } from 'components/UI/UiTable';
import { ServiceListModel, ServiceModel } from 'models/Service';
import { UiListBtnNew } from 'components/UI/UiListBtnNew';
import { serviceTranslations } from 'translations/service';
import { systemServiceRoute } from 'containers/System/Service/routes';
import { formatDate } from 'utils/date';
import { useFilter } from 'utils/hooks/useFilter';
import { getCustomerById } from 'utils/listHelpers';
import { SystemServiceFilter } from 'components/System/Service/filter';
import { SelectOptionsType } from 'components/UI/UiSelect';

type Props = {
  getData: typeof getServiceList;
  customerOptionsList: SelectOptionsType<number>;
};

const fields = [
  { key: 'id', label: 'ID' },
  { key: 'name', label: serviceTranslations.name },
  { key: 'customer', label: serviceTranslations.customer },
  { key: 'legalPerson', label: serviceTranslations.legalPerson },
  { key: 'clientContract', label: serviceTranslations.clientContract },
  { key: 'isAgencyContract', label: serviceTranslations.isAgencyContract },
  { key: 'agent', label: serviceTranslations.agent },
  { key: 'serviceTypeId', label: serviceTranslations.serviceTypeId },
  { key: 'serviceTypeValue', label: serviceTranslations.serviceTypeValue },
];

export const SystemServiceListComponent = ({ getData, customerOptionsList }: Props): React.ReactElement => {
  const { onPaginationChange, loading, model, filter, onSearch, onReset, onChangePage } = useFilter<ServiceListModel>(
    getData
  );

  return (
    <CCard>
      <CCardBody>
        <h3 className="text-center">{serviceTranslations.route.list}</h3>

        <UiListBtnNew title={serviceTranslations.new} to={systemServiceRoute()} />

        <SystemServiceFilter onSearch={onSearch} onReset={onReset} customerList={customerOptionsList} />

        <UiTable
          items={model}
          fields={fields}
          loading={loading}
          pageLimit={filter.pageLimit}
          onChangePage={onChangePage}
          scopedSlots={{
            id: ({ id }: ServiceModel) => (
              <td>
                <Link to={systemServiceRoute(id)}>{id}</Link>
              </td>
            ),
            customer: ({ clientContract }: ServiceModel) => (
              <td>{getCustomerById(customerOptionsList, clientContract.rhsLegalPerson.customerId)}</td>
            ),
            legalPerson: ({ clientContract }: ServiceModel) => <td>{clientContract.rhsLegalPerson.name}</td>,
            clientContract: ({ clientContract }: ServiceModel) => (
              <td>
                {clientContract.numberOfContract} от {formatDate(clientContract.creationDate)}
              </td>
            ),
            isAgencyContract: ({ agencyContract }: ServiceModel) => <td>{agencyContract ? 'Да' : 'Нет'}</td>,
            agent: ({ agencyContract }: ServiceModel) => <td>{agencyContract?.agencyLegalPerson?.name || '-'}</td>,
            serviceTypeId: ({ serviceType }: ServiceModel) => <td>{serviceType.name || '-'}</td>,
            serviceTypeValue: ({ serviceTypeValue }: ServiceModel) => <td>{serviceTypeValue}</td>,
          }}
          onPaginationChange={onPaginationChange}
        />
      </CCardBody>
    </CCard>
  );
};
