import { MyProfileType } from 'models/Auth';
import { httpGet, httpPost } from 'utils/http';

/** инфа о текущем пользователе */
export const getMe = (): Promise<MyProfileType> => httpGet('/auth/me');

/** выход из бэка + редирект на выход из AAD */
export const logout = (redirectTo?: string): Promise<void> =>
  httpPost<string>(`/auth/logout${redirectTo ? `?redirect=${redirectTo}` : ''}`).then(
    (pathToRedirectLogout: string) => {
      window.location.href = pathToRedirectLogout;
    }
  );
