import React from 'react';

import { TheContent } from 'components/Layout/TheContent';
import { TheFooter } from 'components/Layout/TheFooter';
import { TheHeader } from 'components/Layout/TheHeader';
import { TheSidebar } from 'components/Layout/TheSidebar';

export const TheLayout = (): React.ReactElement => (
  <div className="c-app c-default-layout">
    <TheSidebar />
    <div className="c-wrapper">
      <TheHeader />
      <div className="c-body">
        <TheContent />
      </div>
      <TheFooter />
    </div>
  </div>
);
