import { systemAgencyContractListRoute } from 'containers/System/AgencyContract/routes';
import { systemClientContractListRoute } from 'containers/System/ClientContract/routes';
import { systemCustomerListRoute } from 'containers/System/Customer/routes';
import { systemInvoceAgencyListRoute } from 'containers/System/invoiceForAgency/routes';
import { systemInvoiceOnOrderListRoute } from 'containers/System/InvoiceOnOrder/routes';
import { systemLegalPersonListRoute } from 'containers/System/LegalPerson/routes';
import { systemOrderListRoute } from 'containers/System/Order/routes';
import { systemProductListRoute } from 'containers/System/Product/routes';
import { systemServiceListRoute } from 'containers/System/Service/routes';
import { systemServiceTypeListRoute } from 'containers/System/ServiceType/routes';
import { systemTariffListRoute } from 'containers/System/Tariff/routes';
import { agencyContractTranslations } from 'translations/agencyContract';
import { clientContractTranslations } from 'translations/clientContract';
import { customerTranslations } from 'translations/customer';
import { invoiceForAgencyTranslations } from 'translations/InvoiceForAgency';
import { invoiceOnOrderTranslations } from 'translations/invoiceOnOrder';
import { legalPersonTranslations } from 'translations/legalPerson';
import { orderTranslations } from 'translations/order';
import { productTranslations } from 'translations/product';
import { serviceTranslations } from 'translations/service';
import { serviceTypeTranslations } from 'translations/serviceType';
import { tariffTranslations } from 'translations/tariff';

export default [
  {
    _tag: 'CSidebarNavItem',
    name: customerTranslations.route.list,
    to: systemCustomerListRoute(),
  },
  {
    _tag: 'CSidebarNavItem',
    name: legalPersonTranslations.route.list,
    to: systemLegalPersonListRoute(),
  },
  {
    _tag: 'CSidebarNavItem',
    name: clientContractTranslations.route.list,
    to: systemClientContractListRoute(),
  },
  {
    _tag: 'CSidebarNavItem',
    name: agencyContractTranslations.route.list,
    to: systemAgencyContractListRoute(),
  },
  {
    _tag: 'CSidebarNavItem',
    name: serviceTranslations.route.list,
    to: systemServiceListRoute(),
  },
  {
    _tag: 'CSidebarNavItem',
    name: serviceTypeTranslations.route.list,
    to: systemServiceTypeListRoute(),
  },
  {
    _tag: 'CSidebarNavItem',
    name: tariffTranslations.route.list,
    to: systemTariffListRoute(),
  },
  {
    _tag: 'CSidebarNavItem',
    name: productTranslations.route.list,
    to: systemProductListRoute(),
  },
  {
    _tag: 'CSidebarNavItem',
    name: orderTranslations.route.list,
    to: systemOrderListRoute(),
  },
  {
    _tag: 'CSidebarNavItem',
    name: invoiceOnOrderTranslations.route.list,
    to: systemInvoiceOnOrderListRoute(),
  },
  {
    _tag: 'CSidebarNavItem',
    name: invoiceForAgencyTranslations.route.list,
    to: systemInvoceAgencyListRoute(),
  },
];
