import { maxValueToGetAllDataWithPagination } from 'config';
import { OrderCreateType, OrderModel, OrderListModel, OrderUpdateType, TOrdersFilter } from 'models/Order';
import { httpGet, httpPost, httpPut } from 'utils/http';

const path = '/admin/orders';

/** создать заказ */
export const createOrder = (model: OrderCreateType): Promise<OrderModel> => httpPost(`${path}/order`, model);

/** обновить заказ */
export const updateOrder = (id: number, model: OrderUpdateType): Promise<OrderModel> =>
  httpPut(`${path}/order/${id}`, model);

/** получить заказ */
export const getOrder = (id: number): Promise<OrderModel> => httpGet(`${path}/order/${id}`);

/** получить список заказов */
export const getOrderList = (filter?: TOrdersFilter): Promise<OrderListModel> => httpGet(`${path}/filter`, filter);

/** получить список всех заказов */
export const getAllOrderList = (filter?: TOrdersFilter): Promise<OrderListModel> =>
  httpGet(`${path}/filter`, { ...filter, pageLimit: maxValueToGetAllDataWithPagination });
